import React, {Component} from "react";
import {
  HTMLElementEventHandler,
  HTMLevent,
  INewUser,
  IOption,
  IOrganization,
  IUserOrganization
} from "../../Interfaces/Common";
import Translation, { i18n } from "../../i18n";
import cloneDeep from "clone-deep";
import Popup from "../../Components/Popup";
import Form from "../../Components/Form";
import {Select, Switch, Text} from "./Element";
import {connect} from "react-redux";
import {alertConfirm, alertError, getOrganizationUsers} from "../../Store";
import {options as RoleOptions, Roles} from "../../Helpers/Roles";
import {organizationOptions} from "../../Helpers/Common";
import Icon from "../Icon";
import {IAuthObject} from "../../Interfaces/Redux";
import Password from "./Element/Password";

interface IProps {
  auth: IAuthObject;
  user: INewUser;
  onCloseForm: HTMLevent;
  onSave: Function;
  onAddNewUserPermission: Function;
  onEditNewUserPermission: Function;
  onRemoveUserPermission: Function;
  organizations: Array<IOrganization>;
}

interface IState {
  user: IProps["user"];
  organization_users: Array<IUserOrganization>;
  organization_user: {
    organization_id: number;
    insights_license: IUserOrganization["insights_license"];
    skyview_license: IUserOrganization["skyview_license"];
  };
  organization_options: Array<IOption>;
  reset_2fa: boolean;
  organization_mfa_auth_roles: Array<Roles>
}

class User extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      organization_options: [],
      organization_user: {
        organization_id: null,
        insights_license: true,
        skyview_license: true
      },
      organization_users: [],
      user: cloneDeep(this.props.user),
      reset_2fa: false,
      organization_mfa_auth_roles: []
    };
    if (this.props.user.id) {
      this.loadOrganizationUsers();
    }
  }

  public render(): React.ReactNode {
    const _roleOptions = RoleOptions.filter(ro => ro.key >= this.props.auth.user.role_id);
    return <Translation>{
      t => <>
        <Popup onCloseForm={this.props.onCloseForm}>
          <Form onSubmit={this.onSave} onCancel={this.props.onCloseForm}>
            <h2>{t(this.props.user.id ? "EDIT" : "ADD")} {t("USER")}</h2>

            <table style={{width: "100%"}}>
              <tbody>
              {!this.props.user.motion_user_id &&
              <tr>
                <th style={{width: "30%"}}>
                  {t("NAME")}
                </th>
                <td className="nopadding">
                  <Text value={this.state.user.name} name="name" onChange={this.onChange}/>
                </td>
              </tr>
              }
              {
                <tr>
                  <th style={{width: "30%"}}>
                    {t("EMAIL")}
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.user.email} name="email" onChange={this.onChange}/>
                  </td>
                </tr>
              }
              {
                <tr>
                  <th style={{width: "30%"}}>
                    {t("NUMBER")}
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.user.phone_number} name="phone_number" onChange={this.onChange}/>
                  </td>
                </tr>
              }

              {!this.props.user.motion_user_id &&
              <tr>
                <th style={{width: "30%"}}>
                  {t("USERNAME")}
                </th>
                <td className="nopadding">
                  <Text value={this.state.user.username} name="username" onChange={this.onChange}/>
                </td>
              </tr>
              }

              <tr>
                <th>
                  {t("ACTIVE")}
                </th>
                <td className="nopadding">
                  <Switch value={this.state.user.active} name="active" onChange={this.onChange}/>
                </td>
              </tr>

                <tr>
                  <th>
                    {t("WALLBOARD")}
                  </th>
                  <td className="nopadding">
                    <Switch value={this.state.user.wallboard} name="wallboard" onChange={this.onChange} />
                  </td>
                </tr>
              {(!this.props.user.motion_user_id || this.props.user.motion_user_id < 1) && <tr>
                <th style={{width: "30%"}}>
                  {t("PASSWORD")}
                </th>
                <td className="nopadding">
                  <Password value={this.state.user.password} name="password" onChange={this.onChange}/>
                </td>
              </tr>
              }

              <tr>
                <th>
                  {t("ROLE")}
                </th>
                <td className="nopadding">
                  <Select selected={this.state.user.role_id} options={_roleOptions} name="role_id"
                          onChange={this.onChange}/>
                </td>
              </tr>
                  <tr>
                    <th>
                      {t("MFA_AUTH")}
                    </th>
                    <td className="nopadding">
                      <Switch
                        value={this.state.user.mfa_auth}
                        name="userauth"
                        onChange={(_, val) => this.mfaAuth(val)}
                      />
                      {/* Show this message if mfa is either true or false for both user and org */}
                      {((this.state.user.mfa_auth && this.mfaEnabledForOrgRole()) ||
                          (!this.state.user.mfa_auth && !this.mfaEnabledForOrgRole())) && (
                          <p className="nopadding">
                            ** Inherited from the Organization.
                          </p>
                        )}
                  </td>
                  </tr>
              <tr>
                <th>
                  {t("RESET_2FA")}
                </th>
                <td className="nopadding">
                  <Switch
                    value={this.state.reset_2fa}
                    name="reset_2fa"
                    onChange={(_, val) => this.reset2fa(val)}
                  />
                </td>
              </tr>

            {(this.props.auth.user.role_id !== Roles.god) && (
                <tr>
                  <th>2FA Email Authentication</th>
                  <td className="nopadding">
                  <Switch
                      value={this.state.user.mfa_notify_email}
                      name="mfa_notify_email"
                      onChange={(_, val) => this.emailAuth(val)}
                  />
                  </td>
                </tr>
            )}
              {!this.props.user.id && <>
                <tr>
                  <th>
                    {t("ORGANIZATION")}
                  </th>
                  <td className="nopadding">
                    <Select selected={this.state.user.organization_id}
                            options={organizationOptions(this.props.organizations)} name="organization_id"
                            onChange={this.onChange}/>
                  </td>
                </tr>

                <tr>
                  <th>
                    {t("INSIGHTS_LICENSE")}
                  </th>
                  <td className="nopadding">
                    <Switch value={this.state.user.insights_license} name="insights_license" onChange={this.onChange}/>
                  </td>
                </tr>

                <tr>
                  <th>
                    {t("SKYVIEW_LICENSE")}
                  </th>
                  <td className="nopadding">
                    <Switch value={this.state.user.skyview_license} name="skyview_license" onChange={this.onChange}/>
                  </td>
                </tr>

              </>
              }

              </tbody>
            </table>

            {this.props.user.id > 0 && <>
              <h3>{t("PERMISSIONS")}</h3>
              <table className="list">
                <thead>
                <tr>
                  <th>{t("ORGANIZATION")}</th>
                  <th>{t("INSIGHTS")}</th>
                  <th>{t("SKYVIEW")}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                  (this.state.organization_users || []).map(ou => {
                    const organization: IOption = this.state.organization_options.find(oo => oo.key === ou.organization_id);
                    if (!organization)
                      return <></>;
                    return <tr key={ou.id}>
                      <td>{organization.title}</td>
                      <td>
                        <Switch value={ou.insights_license} name="insights_license" onChange={(key, val) => {
                          this.onPermissionUpdate(ou.id, key, val);
                        }
                        }/>
                      </td>
                      <td>
                        <Switch value={ou.skyview_license} name="skyview_license" onChange={(key, val) => {
                          this.onPermissionUpdate(ou.id, key, val);
                        }
                        }/>
                      </td>
                      <td>
                        <Icon icon="delete" onClick={() => {
                          this.removeUserOrganization(ou.id);
                        }
                        }/>
                      </td>
                    </tr>;
                  })
                }
                <tr key={-1}>
                  <td>
                    <Select selected={this.state.organization_user.organization_id}
                            options={organizationOptions(this.props.organizations)} name="organization_id"
                            onChange={this.onPermissionChange}/>
                  </td>
                  <td>
                    <Switch value={this.state.organization_user.insights_license} name="insights_license"
                            onChange={this.onPermissionChange}/>
                  </td>
                  <td>
                    <Switch value={this.state.organization_user.skyview_license} name="skyview_license"
                            onChange={this.onPermissionChange}/>
                  </td>
                  <td>
                    <Icon icon="add" onClick={this.addNewPermission}/>
                  </td>
                </tr>
                </tbody>
              </table>
            </>
            }
          </Form>
        </Popup>
      </>
    }</Translation>;
  }

  private loadOrganizationUsers = () => {
    getOrganizationUsers(this.props.user.id).then((resp) => {
      this.setState(
        {
          organization_options: organizationOptions(this.props.organizations),
          organization_users: resp.data.organization_users,
          organization_mfa_auth_roles: resp.data.two_factor_auth
        }
      );
    });
  }

  private onSave: HTMLevent = (e) => {
    if (!this.state.user.name)
      return alertError(`Name is required!`);
    if (!this.props.user.id && !this.state.user.organization_id)
      return alertError(`Organization is required!`);

    if (this.state.organization_user.organization_id) {
      this.addNewPermission(() => {
        this.props.onSave(this.state.user);
      });
    } else {
      const user = {...this.state.user};

      if (this.state.reset_2fa || !this.state.user.mfa_notify_email) {
        user.two_factor_secret = null;
      }

      user.mfa_notify_email = this.state.user.mfa_notify_email;
      user.mfa_auth = this.state.user.mfa_auth;
      this.props.onSave(user);
    }
  }

  private onChange: HTMLElementEventHandler = (key: string, value: any) => {
    let user: IState["user"] = this.state.user;
    user[key] = value;
    // condition to check MFA for org when creating a new user
    if (
      (key === "organization_id" || key === "role_id")
    ) {
      user.mfa_auth = this.mfaEnabledForOrgRole(user);
    }
    this.setState({user});
  }

  private mfaEnabledForOrgRole(updatedUser?: IState["user"]) {
    const user = updatedUser || this.state.user;
    // for create get org id from user, for edit operation get org id form organization_users obj
    const organizationId = user.organization_id || (this.state.organization_users?.[0]?.organization_id);
    const roleId = parseInt(user.role_id + "");
    const { organizations } = this.props;
    let isVisibleMfa = false;
    if (organizationId) {
      // check for org id match on first and seceond level
        if (organizations[0].id == organizationId) {
            isVisibleMfa = organizations[0].two_factor_auth?.includes(roleId) ?? false;
        } else {
            const subOrganization = organizations[0].organizations?.find(({ id }) => id == organizationId);
            isVisibleMfa = subOrganization?.two_factor_auth?.includes(roleId) ?? false;
        }
    }

    return isVisibleMfa;
}

  private onPermissionUpdate = (id: number, key: string, val: any) => {
    this.props.onEditNewUserPermission(this.state.user.id, id, key, val, () => {
      this.loadOrganizationUsers();
    });
  }

  private onPermissionChange: HTMLElementEventHandler = (key: string, value: any) => {
    let organization_user: IState["organization_user"] = this.state.organization_user;
    organization_user[key] = value;
    this.setState({organization_user});
  }

  private addNewPermission = (callback: Function = null): void => {
    this.props.onAddNewUserPermission({
      organization_id: this.state.organization_user.organization_id,
      user_id: this.props.user.id,
      insights_license: this.state.organization_user.insights_license,
      skyview_license: this.state.organization_user.skyview_license
    },                                () => {
      this.loadOrganizationUsers();
      if (typeof callback === "function")
        callback();
    });
  }

  private removeUserOrganization = (id: number): void => {
    this.props.onRemoveUserPermission(this.props.user.id, id, () => {
      this.loadOrganizationUsers();
    });
  }

  private reset2fa = (val: boolean) => {
    if (val) {
      alertConfirm(i18n.t("CONFIRM_RESET_2FA"), async () => {
        this.setState({reset_2fa: val});
      });
    } else {
      this.setState({reset_2fa: val});
    }
  }

  private emailAuth = (val: boolean) => {
    if (val) {
      alertConfirm(i18n.t("CONFIRM_MFA_NOTIFY_EMAIL"), async () => {
        this.setState({user: {...this.state.user, mfa_notify_email: val}});
      });
    } else {
      this.setState({user: {...this.state.user, mfa_notify_email: val}});
    }
  };

  private mfaAuth = (val: boolean) => {
    if (val) {
      this.setState({ user: { ...this.state.user, mfa_auth: val } });
    } else {
      alertConfirm(i18n.t("CONFIRM_MFA_AUTH"), async () => {
        this.setState({ user: { ...this.state.user, mfa_auth: val } });
      });
    }
  };

  /*
  private organizationOptions(): Array<IOption> {
    let flatten: Array<IOption> = [{key: "", title: i18n.t("SELECT_ORGANIZATION")}];

    function ls(organizations: Array<IOrganization>, parent: Array<string> = []): any {
      organizations.forEach(org => {
        flatten.push({
          key: org.id,
          title: `${[...parent, org.name].join(" - ")}`
        });
        if ((org.organizations || []).length)
          ls(org.organizations, [...parent, org.name]);
      });
    }

    ls(this.props.organizations);
    return flatten;
  }
  */

}

function mapStateToProps(state: { organizations: Array<IOrganization>, auth: IAuthObject }): { organizations: Array<IOrganization>, auth: IAuthObject } {
  return {
    auth: state.auth,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(User);
