import React from "react";
import {connect} from "react-redux";
import {INewNode, INewOrganization, IOrganization} from "../Interfaces/Common";
import Translation, {i18n} from "../i18n";
import OrganizationForm, { license_plans } from "../Components/Form/Organization";
import NodeForm from "../Components/Form/Node";
import {
  addNode,
  addOrganization,
  alertConfirm,
  checkVbsVoiceBot,
  deleteNode,
  deleteOrganization,
  updateNode,
  updateOrganization
} from "../Store";
import Icon from "../Components/Icon";
import {Roles} from "../Helpers/Roles";
import {Link} from "react-router-dom";
import {IAuthObject} from "../Interfaces/Redux";
import { LicenseType } from "connectel-shared/interfaces/Site";
import { DeepgramTranscriptionLanguage, DeepgramTranscriptionModal } from "connectel-shared/interfaces/Deepgram";

interface IProps {
  auth: IAuthObject;
  organizations: Array<IOrganization>;
}

interface IState {
  organization: INewOrganization;
  node: INewNode;
  organizationsNode: Array<IOrganization>;
}

class Nodes extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      organization: null,
      node: null,
      organizationsNode: null
    };
  }

  public render(): React.ReactNode {
    const list: any = (organizations: Array<IOrganization>, lvl: number = 0): React.ReactNode => {
      let ls: Array<React.ReactNode> = [];
      organizations.map(organization => {
        ls.push(this.getOrganizationRow(organization, lvl));

        organization.nodes.forEach(node => {
          ls.push(<tr key={node.id} className={`node lvl${lvl}`}>
            <td></td>
            <td>{node.id} - {node.name}</td>
            <td>{node.hostname}{node.motion_host && <><br/>({node.motion_host})</>}</td>
            <td className="align-center">
              <Icon 
                icon={node.active? "checbox-checked": "checbox-unchecked"}
                style={{color: node.active? "green": "red"}} />
            </td>
            <td></td>
            <td></td>
            <td className="align-center">{
              organization.survey_license && node.eviexa && 
              <Icon icon="checkmark" style={{color: 'green', fontSize: "16px"}}/> }
            </td>
            <td className="align-center">{
              node.sms_pack &&
              <Icon icon="checkmark" style={{color: 'green', fontSize: "16px"}}/> }
            </td>
            <td></td>
            <td></td>
            <td>
              {[Roles.god, Roles.admin].includes(this.props.auth.user.role_id) && <>
                <Icon icon="mode_edit" onClick={() => {
                  this.Node({
                    active: node.active,
                    eviexa: node.eviexa,
                    hostname: node.hostname,
                    motion_host: node.motion_host,
                    sms_pack: node.sms_pack,
                    id: node.id,
                    name: node.name,
                    insights_pseudonymize_agents: node.insights_pseudonymize_agents,
                    logo_url: node.logo_url
                  });
                }} tooltip={{id: `node${organization.id}update`, message: `${i18n.t("EDIT")} ${i18n.t("NODE")}`}}/>
                <Icon icon="delete" onClick={() => {
                  alertConfirm(i18n.t("ORGANIZATION_DELETE_CONFIRM"), (e) => {
                    return deleteNode(node.id);
                  });
                }} tooltip={{
                  id: `node${organization.id}del`,
                  type: "error",
                  message: `${i18n.t("DELETE")} ${i18n.t("NODE")}`
                }}/>
              </>
              }
            </td>
          </tr>);
        });
        if ((organization.organizations).length > 0)
          ls = [...ls, ...list(organization.organizations, lvl + 1)];
      });
      return ls;
    };

    return <Translation>
      {t => <>
        {this.state.organization !== null &&
        <OrganizationForm organizationsNode={this.state.organizationsNode} organization={this.state.organization} onCloseForm={() => {
          this.Organization(null);
        }} onSave={this.onSaveOrganization}/>}
        {this.state.node !== null && <NodeForm node={this.state.node} onCloseForm={() => {
          this.Node(null);
        }} onSave={this.onSaveNode}/>}
        <h1>
          {t("ORGANIZATIONS")}

          {this.props.auth.user.role_id === Roles.god && <>
            <a className="button" onClick={() => this.Organization({
              active: true,
              license: false,
              license_type: LicenseType.STANDARD,
              name: "",
              parent_id: 0,
              insights_licenses: -1,
              skyview_licenses: -1,
              survey_license: false,
              voice_bot: false,
              voice_bot_maps: false,
              nav_ids: "",
              widgets_host: "",
              two_factor_auth: null,
              benchmark: true,
              benchmark_visibility: null,
              vbs_host: "",
              ai_coach: false,
              has_external_domain: false,
              speech_analytics: false,
              speech_analytics_language: DeepgramTranscriptionLanguage.SWEDISH,
              speech_analytics_modal: DeepgramTranscriptionModal.ENHANCED,
              speech_analytics_sentiment: false,
            },[])}>{t("ADD")} {t("ORGANIZATION")}</a>

            <a className="button" onClick={() => this.Node({
              active: true,
              hostname: "",
              motion_host: null,
              name: "",
              sms_pack: false,
              eviexa: "",
              organization_id: 1,
              insights_pseudonymize_agents: false,
              logo_url: null
            })}>{t("ADD")} {t("NODE")}</a>
          </>
          }
        </h1>
        <article>
          <table className="list organizations stickyheader">
            <thead>
            <tr>
              <th>{t("ORGANIZATION")}</th>
              <th>{t("NODE")}</th>
              <th>{t("HOSTNAME")}</th>
              <th>{t("ACTIVE")}</th>
              <th>{t("INSIGHTS_LICENSES")} ({t("USED")})</th>
              <th>{t("SKYVIEW_LICENSES")} ({t("USED")})</th>
              <th>{t("SURVEY")}</th>
              <th>{t("VOICE_BOT")}</th>
              <th>{t("SMS")}</th>
              <th>{this.props.auth.user.role_id === Roles.god ? t("NAVISION") : ""}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {list(this.props.organizations)}
            </tbody>
          </table>
        </article>


      </>
      }
    </Translation>;
  }

  private Organization = async (organization: INewOrganization, organizationsNode?: Array<IOrganization>) => {
    let voice_bot_vbs_settings = {
      enabled: false,
      towards_enabled: false,
      future_bookings_enabled: false
    }

    if (organization) {
      if (organization?.voice_bot && organization?.vbs_host) {
        const voiceBotResult = await checkVbsVoiceBot(organization.id);
        const { enabled, towards_enabled, future_bookings_enabled } = voiceBotResult?.data;

        voice_bot_vbs_settings = {
          enabled,
          towards_enabled,
          future_bookings_enabled
        }
      }
      organization = { ...organization, ...{ voice_bot_vbs_settings } }
    }
    this.setState({
      organization,
      organizationsNode
    });
  }

  private getOrganizationRow(organization: IOrganization, lvl: number) {
    let nav_ids: Array<string> = this.props.auth.user.role_id === Roles.god && organization.nav_ids ? organization.nav_ids.split(",").map(n => n.trim()) : [];
    if (nav_ids.length > 3)
      nav_ids = [nav_ids[0], nav_ids[1], `+${nav_ids.length - 2}`];
    const hasSurveyLicense = organization.survey_license;  
    const surveyLicenseStatus = hasSurveyLicense ? "Active": "Inactive";
    const surveyLicenseIcon = hasSurveyLicense ? "checkmark": "cross";
    const surveyLicenseIconColor = hasSurveyLicense ? "grey": "red";
    const hasSMSPack = organization.nodes.find(node => node.sms_pack) ?? false;
    const smsPackIcon = hasSMSPack ? "checkmark" : "cross";
    const smsPackIconColor = hasSMSPack ? "green" : "red";
    const hasVoiceBot = organization.voice_bot ?? false;
    const voiceBotIcon = hasVoiceBot ? "checkmark" : "cross";
    const voiceBotColor = hasVoiceBot ? "green" : "red";
    const skyviewLicenseType = organization.license ? organization.license_type : LicenseType.STANDARD;
    const enterpriseIcon = this.getIconForLicense(organization.license_type);
    const isOrganizationActive = organization.active;
    const organizationStatusIcon = isOrganizationActive ? "checkmark": "cross";
    const organizationStatusIconColor = isOrganizationActive ? "green": "red";
    return (
      <tr key={`org${organization.id}`} className={`organization lvl${lvl}`}>
          <td colSpan={2}>{organization.id} - {organization.name}</td>
          <td></td>
          <td className="align-center">
              <Icon
                icon={organizationStatusIcon}
                style={{color: organizationStatusIconColor}}>
              </Icon>
          </td>
          <td className="align-center">
            { <Icon icon={`${enterpriseIcon}`} style={{color: "grey"}} tooltip={{id: `insights-plan-${organization.id.toString()}`, message: skyviewLicenseType}}/>} &nbsp;
            { <Icon icon="checkmark" style={{color: 'green'}} tooltip={{id: `insights-plan-status-${organization.id.toString()}`, message: "Active"}}/>} &nbsp;
            { <span className="icon" style={{color: 'grey', fontSize: '30px'}}>{organization.insights_licenses === -1 ? '∞' : ''}</span>} &ensp;
            {<Link to={`/organization/${organization.id}/users/insights`}>{organization.insights_licenses_used}</Link>}
            {/* {organization.insights_licenses === -1 ? i18n.t("UNLIMITED") : `${organization.insights_licenses} st`} (<Link
            to={`/organization/${organization.id}/users/insights`}>{organization.insights_licenses_used} st</Link>) */}
          </td>
          <td className="align-center">
            { <Icon icon={`${enterpriseIcon}`} style={{color: "grey"}}  tooltip={{id: `skyview-plan${organization.id.toString()}`, message: skyviewLicenseType}}/>} &nbsp;
            { <Icon icon="checkmark" style={{color: 'green'}}  tooltip={{id: `skyview-plan-status-${organization.id.toString()}`, message: "Active"}}/>} &nbsp;
            { <span className="icon" style={{color: 'grey', fontSize: '30px'}}>{organization.insights_licenses === -1 ? '∞' : ''}</span>} &ensp;
            { <Link to={`/organization/${organization.id}/users/skyview`}>{organization.skyview_licenses_used}</Link> }
            {/* {organization.skyview_licenses === -1 ? i18n.t("UNLIMITED") : `${organization.skyview_licenses} st`} (<Link
            to={`/organization/${organization.id}/users/skyview`}>{organization.skyview_licenses_used} st</Link>) */}
          </td>
          <td className="align-center">
            {!hasSurveyLicense && <Icon icon={surveyLicenseIcon} style={{color: surveyLicenseIconColor}} tooltip={{id: `survey-plan-status-${organization.id.toString()}`, message: surveyLicenseStatus}}/>} &nbsp;
            {hasSurveyLicense && <Icon icon={"home2"} style={{color: "green"}} tooltip={{id: `survey-license-type-${organization.id.toString()}`, message: "Standard"}}/>}
          </td>
          <td className="align-center">
            {hasVoiceBot && <Icon icon={voiceBotIcon} style={{color: voiceBotColor}} />}
          </td>
          <td className="align-center">
            <Icon icon={smsPackIcon} style={{color: smsPackIconColor}} />
          </td>
          <td>{nav_ids.join(", ")}</td>
          <td>
            {(
              [Roles.god, Roles.admin].includes(this.props.auth.user.role_id)
            ) && <>
              <Icon icon="mode_edit" onClick={() => {
                this.Organization({
                  active: organization.active,
                  license: organization.license,
                  id: organization.id,
                  name: organization.name,
                  parent_id: organization.parent_id,
                  insights_licenses: organization.insights_licenses,
                  skyview_licenses: organization.skyview_licenses,
                  survey_license: organization.survey_license,
                  survey_license_type: organization.survey_license_type,
                  license_type: organization.license_type,
                  benchmark: organization.benchmark,
                  benchmark_visibility: organization.benchmark_visibility,
                  voice_bot: organization.voice_bot,
                  voice_bot_maps: organization.voice_bot_maps,
                  nav_ids: organization.nav_ids,
                  two_factor_auth: organization.two_factor_auth,
                  widgets_host: organization.widgets_host,
                  vbs_host: organization.vbs_host,
                  ai_coach: organization.ai_coach,
                  has_external_domain: organization.has_external_domain,
                  speech_analytics: organization.speech_analytics,
                  speech_analytics_language: organization.speech_analytics_language,
                  speech_analytics_modal: organization.speech_analytics_modal,
                  speech_analytics_sentiment: organization.speech_analytics_sentiment,
                },[organization]);
              }} tooltip={{id: `org${organization.id}update`, message: `${i18n.t("EDIT")} ${i18n.t("ORGANIZATION")}`}}/>
              <Icon icon="delete" onClick={() => {
                alertConfirm(i18n.t("ORGANIZATION_DELETE_CONFIRM"), (e) => {
                  return deleteOrganization(organization.id);
                });
              }} tooltip={{
                id: `org${organization.id}del`,
                type: "error",
                message: `${i18n.t("DELETE")} ${i18n.t("ORGANIZATION")}`
              }}/>
            </>
            }
          </td>
        </tr>
    )
  }
  private onSaveOrganization = (organization: INewOrganization) => {
    let promise: any = null;

    if (!organization.id)
      promise = addOrganization(organization);
    else
      promise = updateOrganization(organization);

    promise.then(() => {
      this.setState({
        organization: null
      });
    });

  }

  private getIconForLicense(license: LicenseType): string {
    return license_plans.find(license_plan => license_plan.name === license)?.icon
  }

  private Node = (node: INewNode) => {
    this.setState({
      node
    });
  }

  private onSaveNode = (node: INewNode) => {
    let promise: any = null;

    if (!node.id)
      promise = addNode(node);
    else
      promise = updateNode(node);

    promise.then(() => {
      this.setState({
        node: null
      });
    });

  }

}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(Nodes);
