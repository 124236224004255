export const PORTING_APPLICABLE_COUNTRY = [
  {displayValue: "None", value: ""},
  {displayValue: "Australia (+61)", value: "Australia (+61)"},
  {displayValue: "Austria (+43)", value: "Austria (+43)"},
  {displayValue: "Belgium (+32)", value: "Belgium (+32)"},
  {displayValue: "Canada (+1)", value: "Canada (+1)"},
  {displayValue: "Chile (+56)", value: "Chile (+56)"},
  {displayValue: "Croatia (+385)", value: "Croatia (+385)"},
  {displayValue: "Cyprus (+357)", value: "Cyprus (+357)"},
  {displayValue: "Czech Republic (+420)", value: "Czech Republic (+420)"},
  {displayValue: "Denmark (+45)", value: "Denmark (+45)"},
  {displayValue: "Estonia (+372)", value: "Estonia (+372)"},
  {displayValue: "Finland (+358)", value: "Finland (+358)"},
  {displayValue: "France (+33)", value: "France (+33)"},
  {displayValue: "Germany (+49)", value: "Germany (+49)"},
  {displayValue: "Greece (+30)", value: "Greece (+30)"},
  {displayValue: "Hong Kong (+852)", value: "Hong Kong (+852)"},
  {displayValue: "Hungary (+36)", value: "Hungary (+36)"},
  {displayValue: "Ireland (+353)", value: "Ireland (+353)"},
  {displayValue: "Israel (+972)", value: "Israel (+972)"},
  {displayValue: "Italy (+39)", value: "Italy (+39)"},
  {displayValue: "Latvia (+371)", value: "Latvia (+371)"},
  {displayValue: "Lithuania (+370)", value: "Lithuania (+370)"},
  {displayValue: "Luxembourg (+352)", value: "Luxembourg (+352)"},
  {displayValue: "Mexico (+52)", value: "Mexico (+52)"},
  {displayValue: "Netherlands (+31)", value: "Netherlands (+31)"},
  {displayValue: "New Zealand (+64)", value: "New Zealand (+64)"},
  {displayValue: "Norway (+47)", value: "Norway (+47)"},
  {displayValue: "Panama (+507)", value: "Panama (+507)"},
  {displayValue: "Poland (+48)", value: "Poland (+48)"},
  {displayValue: "Portugal (+351)", value: "Portugal (+351)"},
  {displayValue: "Puerto Rico (+1)", value: "Puerto Rico (+1)"},
  {displayValue: "Romania (+40)", value: "Romania (+40)"},
  {displayValue: "Singapore (+65)", value: "Singapore (+65)"},
  {displayValue: "Slovakia (+421)", value: "Slovakia (+421)"},
  {displayValue: "Slovenia (+386)", value: "Slovenia (+386)"},
  {displayValue: "South Africa (+27)", value: "South Africa (+27)"},
  {displayValue: "Spain (+34)", value: "Spain (+34)"},
  {displayValue: "Sweden (+46)", value: "Sweden (+46)"},
  {displayValue: "Switzerland (+41)", value: "Switzerland (+41)"},
  {displayValue: "United Kingdom (+44)", value: "United Kingdom (+44)"},
  {displayValue: "United States (+1)", value: "United States (+1)"},
  {
    displayValue: "Virgin Islands (U.S) (+1)",
    value: "Virgin Islands (U.S) (+1)"
  }
];

export const PORTING_NUMBER_TYPE = [
  {displayValue: "None", value: ""},
  {displayValue: "Geographic", value: "Geographic"},
  {displayValue: "Toll-free", value: "Toll-free"},
  {displayValue: "National", value: "National"},
  {displayValue: "Mobile", value: "Mobile"}
];

export const PORTING_STATUS = [
  {displayValue: "New", value: "New"},
  {displayValue: "Accepted", value: "Accepted"},
  {displayValue: "Processing", value: "Processing"},
  {displayValue: "Declined", value: "Declined"},
  {displayValue: "Done", value: "Done"}
];
