import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";
import {Translation} from "react-i18next";
import {connect} from "react-redux";
import {flattenOrganizations, getPastDateForWeeks} from "../Helpers/Common";
import { Roles } from "../Helpers/Roles";
import {IOrganization, IUser} from "../Interfaces/Common";
import {IAuthObject} from "../Interfaces/Redux";
import "../Style/Sass/AnalyticsPage.scss";
import { getSyncRequestData } from "../Store/index";

interface IProps {
    auth: IAuthObject;
    users: IUser[];
    organizations: Array<IOrganization>;
}

interface IState {
    allOrganizations: IOrganization[];
    page: number;
    size: number;
    syncRequestCountPerCustomer: Record<string, number>;
}

class AnalyticsPage extends React.Component<IProps, IState> {
    chartsWidth: number;
    constructor(props: IProps) {
        super(props);
        this.chartsWidth = 300;
        this.state = {
            allOrganizations: [],
            page:1,
            size:100,
            syncRequestCountPerCustomer: {},
        };
    }

    async componentDidMount(): Promise<void> {
        const response = await getSyncRequestData(this.state.page, this.state.size)
        this.setState({
            syncRequestCountPerCustomer: this.getCountPerCustomer(response.data.syncRequests),
        })
    }

    getLast30DaysSyncRequests(requests) {
        const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        return requests.filter(({ updatedAt }) => new Date(updatedAt) >= thirtyDaysAgo);
    }

    getCountPerCustomer(syncRequests) {
        const syncRequestsLast30Days = this.getLast30DaysSyncRequests(syncRequests)
        const syncRequestsCountPerCustomer: Record<string, number> = {};
        syncRequestsLast30Days.forEach(item => {
            syncRequestsCountPerCustomer[item.hostname] = syncRequestsCountPerCustomer[item.hostname] ? syncRequestsCountPerCustomer[item.hostname] + 1 : 1;
        });

       return syncRequestsCountPerCustomer;
    }

    static getDerivedStateFromProps(props: IProps) {
        return {
            allOrganizations: flattenOrganizations(props.organizations)
        };
    }

    pieChartOptions = (
        captionText: {heading: string; value: string | number},
        chartTitle: string,
        seriesData: Array<{name: string; y: number | string; color: string}>
    ) => ({
        caption: {
            text: `<b>${captionText.heading}</b>: ${captionText.value}`,
            align: "center",
            style: {
                fontSize: 15
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            type: "pie",
            width: this.chartsWidth
        },
        title: {
            text: `<b>${chartTitle}</b>`
        },
        tooltip: {
            headerFormat: "{point.key}: {point.percentage:.1f} %",
            pointFormat: ""
        },
        accessibility: {
            point: {
                valueSuffix: "%"
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: <b>{point.y}</b>",
                    distance: -50,
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: 4
                }
            }
        },
        series: [
            {
                colorByPoint: true,
                data: seriesData
            }
        ]
    });

    columnChartOptions = (
        chartTitle: string,
        data: any[],
        xAxis: {title: string; categories: any[]},
        yAxis: {title: string}
    ) => ({
        credits: {
            enabled: false
        },
        chart: {
            type: "column",
            width: this.chartsWidth
        },
        title: {
            text: `<b>${chartTitle}</b>`
        },
        tooltip: {
            headerFormat: "{point.key}: <b>{point.y}</b>",
            pointFormat: ""
        },
        xAxis: {
            title: {text: `<b>${xAxis.title}</b>`},
            categories: xAxis.categories
        },
        yAxis: {
            title: {text: `<b>${yAxis.title}</b>`}
        },
        series: [
            {
                data,
                showInLegend: false
            }
        ]
    });

    lineChartOptions = (
        chartTitle: string,
        xAxis: {
            title: string;
            categories: number[] | string[];
        },
        yAxis: {title: string},
        graphValues: number[]
    ) => ({
        credits: {
            enabled: false
        },
        chart: {
            type: "line"
        },
        title: {
            text: `<b>${chartTitle}</b>`
        },
        tooltip: {
            headerFormat: "{point.key}: <b>{point.y}</b>",
            pointFormat: ""
        },
        xAxis: {
            title: {text: `<b>${xAxis.title}</b>`},
            categories: xAxis.categories
        },
        yAxis: {
            title: {text: `<b>${yAxis.title}</b>`}
        },
        series: [
            {
                data: graphValues,
                showInLegend: false
            }
        ]
    });

    getActiveOrganizationsOptions = () => {
        const activeOrganizations = this.state.allOrganizations.reduce(
            (count, n) => {
                if (n.active) {
                    return count + 1;
                }
                return count;
            },
            0
        );
        const inactiveOrganizations =
            this.state.allOrganizations.length - activeOrganizations;
        return this.pieChartOptions(
            {
                heading: "Total organizations",
                value: this.state.allOrganizations.length
            },
            "Active Organizations",
            [
                {
                    name: "Active",
                    y: activeOrganizations,
                    color: "green"
                },
                {
                    name: "In-active",
                    y: inactiveOrganizations,
                    color: "red"
                }
            ]
        );
    };

    getActiveNodeOptions = () => {
        const {nodesCount, activeNodesCount} =
            this.state.allOrganizations.reduce(
                ({nodesCount, activeNodesCount}, org) => {
                    return {
                        nodesCount: nodesCount + org.nodes.length,
                        activeNodesCount: (activeNodesCount += org.nodes.filter(
                            (n) => n.active
                        ).length)
                    };
                },
                {nodesCount: 0, activeNodesCount: 0}
            );
        return this.pieChartOptions(
            {
                heading: "Total Nodes",
                value: nodesCount
            },
            "Active Nodes",
            [
                {
                    name: "Active",
                    y: activeNodesCount,
                    color: "green"
                },
                {
                    name: "In-active",
                    y: nodesCount - activeNodesCount,
                    color: "red"
                }
            ]
        );
    };

    getServicesStatsOptions = () => {
        const {skyview, insights, survey} = this.state.allOrganizations.reduce(
            ({skyview, insights, survey}, org) => {
                if (org.survey_license) survey++;
                if (org.skyview_licenses !== 0) skyview++;
                if (org.insights_licenses !== 0) insights++;
                return {skyview, insights, survey};
            },
            {skyview: 0, insights: 0, survey: 0}
        );
        return this.columnChartOptions(
            "Services Stats",
            [skyview, insights, survey],
            {
                title: "Services",
                categories: ["Skyview", "Insights", "Survey"]
            },
            {
                title: "Number of organizations"
            }
        );
    };

    getSykviewInsightUsersOptions = () => {
        const {insightUsers, skyviewUsers} = this.props.organizations.reduce(
            ({insightUsers, skyviewUsers}, org) => {
                return {
                    insightUsers: insightUsers + org.insights_licenses_used,
                    skyviewUsers: skyviewUsers + org.skyview_licenses_used
                };
            },
            {insightUsers: 0, skyviewUsers: 0}
        );
        return this.columnChartOptions(
            "Skyview-Insights Users",
            [skyviewUsers, insightUsers],
            {
                title: "Licences",
                categories: ["Skyview", "Insights"]
            },
            {
                title: "Number of users"
            }
        );
    };

    getSyncRequestOptions=()=>{

        return {
            title: {
            text: 'Last 30 Days Sync Request',
            },
            tooltip: {
                headerFormat: "{point.key}: <b>{point.y}</b>",
                pointFormat: ""
            },
            xAxis: {
                title: {text: `<b>${"Last Month Data"}</b>`},
                categories: Object.keys(this.state.syncRequestCountPerCustomer).map(customerName => customerName.split(".")[0].split("\/\/")?.[1])
            },
            yAxis: {
                title: {text: `<b>${"Count Per Customer"}</b>`}
            },
            series: [{
                type: 'column',
                data: Object.values(this.state.syncRequestCountPerCustomer)
            }],
        }
    }

    getWeeklyActiveUsersOptions = () => {
        const dates = getPastDateForWeeks(8);
        const xAxisKeys = [];
        const yAxisValues = [];
        for (let i = 0; i < dates.length - 1; i++) {
            xAxisKeys.push(
                `${moment(dates[i]).format("DD MMM")}-${moment(
                    dates[i + 1]
                ).format("DD MMM")}`
            );
            const count = this.props.users.filter((user) => {
                const lastLoginDate = moment(user.last_login).format(
                    "yyyy-MM-DD"
                );
                return (
                    dates[i] <= lastLoginDate && lastLoginDate <= dates[i + 1]
                );
            }).length;
            yAxisValues.push(count);
        }
        return this.lineChartOptions(
            "Active users of past 2 months",
            {
                title: "Week days",
                categories: xAxisKeys
            },
            {
                title: "Number of active users"
            },
            yAxisValues
        );
    };

    render(): React.ReactNode {
        if(this.props.auth.user.role_id !== Roles.god) return <></>
        
        return (
            <Translation>
                {(t) => (
                    <>
                        <h1>{t("ANALYTICS")}</h1>
                        <div className="charts">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.getActiveOrganizationsOptions()}
                            />
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.getActiveNodeOptions()}
                            />

                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.getServicesStatsOptions()}
                            />
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.getSykviewInsightUsersOptions()}
                            />
                        </div>
                        <HighchartsReact
                                highcharts={Highcharts}
                                options={this.getSyncRequestOptions()}
                        />
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.getWeeklyActiveUsersOptions()}
                        />
                    </>
                )}
            </Translation>
        );
    }
}

function mapStateToProps(state: IProps): IProps {
    return {
        auth: state.auth,
        users: state.users,
        organizations: state.organizations
    };
}

export default connect(mapStateToProps, {})(AnalyticsPage);
