import React from "react";
import  {IOrganization, IUser} from "../Interfaces/Common";
import IApp, {AppScopeOptions} from "connectel-shared/interfaces/App";
import {IAuthObject, IConfigObject} from "../Interfaces/Redux";
import {connect} from "react-redux";
import {fetchApps, addApp, alertConfirm, deleteApp} from "../Store";
import Translation from "../i18n";
import {Roles} from "../Helpers/Roles";
import AppForm from "../Components/Form/App";
import i18n from "i18next";
import Icon from "../Components/Icon";

interface IState {
  app: {
    user_id: number;
  };
  apps: Array<IApp>;
}

interface IProps {
  auth: IAuthObject;
  config: IConfigObject;
  organizations: Array<IOrganization>;
  users: Array<IUser>;
}

class Apps extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      app: null,
      apps: []
    };
  }

  public componentDidMount(): void {
    this.fetchApps();
  }

  public render(): React.ReactNode {
    return <Translation>{
      t => <>
        {this.state.app !== null && <AppForm
          app={this.state.app}
          auth={this.props.auth}
          onCloseForm={() => {
            this.setState({app: null});
          }}
          onSave={this.onSaveApp}
          users={this.props.users}
        />}
        <h1>
          {t("APPS")}
          {this.props.auth.user.role_id <= Roles.manager && <>
            <a className="button" onClick={() => this.App()}>{t("ADD")} {t("APP")}</a>
          </>
          }
        </h1>
        <article>
          <table className="list">
            <thead>
            <tr>
              <td>{t("ID")}</td>
              <td>{t("OWNER")}</td>
              <td>{t("CLIENT_ID")}</td>
              <td>{t("SCOPES")}</td>
              <td>{t("CREATED_AT")}</td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            {
              this.state.apps.map(app => {
                const user: IUser = this.props.users.find(u => u.id === app.user_id);
                return <tr>
                  <td>{app.id}</td>
                  <td>{user?.name || t("USER_NOT_FOUND")}</td>
                  <td>{app.client_id}</td>
                  <td>{app.scopes.map(scope => AppScopeOptions.find(ast => (ast.key + "") === (scope + ""))?.title || `Scope "${scope}" not found`).join(", ")}</td>
                  <td>{app.createdAt}</td>
                  <td>
                    <Icon icon="delete" onClick={() => {
                      alertConfirm(i18n.t("APP_DELETE_CONFIRM"), (e) => {
                        return this.deleteApp(app.id);
                      });
                    }}/>
                  </td>
                </tr>;
              })
            }
            </tbody>
          </table>
        </article>
      </>
    }
    </Translation>;
  }

  private App(): void {
    this.setState({
      app: {
        user_id: this.props.auth.user.id
      }
    });
  }

  private onSaveApp = (app: IApp) => {
    addApp(app).then(() => {
      this.setState({
        app: null
      });
      this.fetchApps();
    });

  }

  private deleteApp = (app_id: IApp["id"]): Promise<any> => {
    return deleteApp(app_id).then(() => {
      this.fetchApps();
    });

  }

  private fetchApps(): void {
    fetchApps().then(apps => {
      this.setState({
        apps: apps.data
      });
    });
  }

}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    config: state.config,
    organizations: state.organizations,
    users: state.users
  };
}

export default connect(mapStateToProps, {})(Apps);
