import React, {Component} from "react";
import {HTMLElementEventHandler, HTMLevent, IUser} from "../../Interfaces/Common";
import IApp, {AppScope} from "connectel-shared/interfaces/App";
import Translation from "../../i18n";
import Popup from "../Popup";
import Form from "../Form";
import {random as RandomString} from "../../Helpers/String";
import FormSelect from "./Element/Select";
import {Roles} from "../../Helpers/Roles";
import {IAuthObject} from "../../Interfaces/Redux";

interface IState {
  app: Partial<IApp>;
}

interface IProps {
  app: {
    user_id: number;
  };
  auth: IAuthObject;
  onCloseForm: HTMLevent;
  onSave: Function;
  users: Array<IUser>;
}

export default class App extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      app: {
        client_id: RandomString(20),
        scopes: [AppScope.WidgetApi],
        secret: RandomString(40),
        user_id: this.props.app.user_id
      }
    };
  }

  public render(): React.ReactNode {
    return <Translation>{
      t => <>
        <Popup onCloseForm={this.props.onCloseForm}>
          <Form onSubmit={this.onSave} onCancel={this.props.onCloseForm}>
            <h2>{t("ADD")} {t("APP")}</h2>

            <table style={{width: "100%"}}>
              <tbody>
              {
                this.props.auth.user.role_id === Roles.god && <tr>
                  <td>{t("OWNER")}</td>
                  <td>
                    <FormSelect
                      onChange={this.onChange}
                      options={this.props.users.map(u => ({key: u.id, title: u.name}))}
                      name="user_id"
                      selected={this.state.app.user_id}
                    />
                  </td>
                </tr>
              }
              <tr>
                <td>{t("CLIENT_ID")}</td>
                <td>{this.state.app.client_id}</td>
              </tr>
              <tr>
                <td>{t("SECRET")} <span className="sup">*</span></td>
                <td>{this.state.app.secret}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <ul className="helpers">
                    <li><span className="sup">*</span> {t("APP_SECRET_INFO")}</li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </Form>
        </Popup>
      </>
    }
    </Translation>;
  }

  private onSave = (): void => {
    this.props.onSave(this.state.app);
  }

  private onChange: HTMLElementEventHandler = (key: string, value: any) => {
    this.setState({app: {...this.state.app, [key]: value}});
  }

}

