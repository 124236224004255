import React, {useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import {
  HTMLElementEventHandler,
  INewPortingRequest,
  IOrganization
} from "../../../Interfaces/Common";
import Form from "../../Form";
import Translation from "../../../i18n";
import {Select} from "../Element";
import {organizationOptions} from "../../../Helpers/Common";
import {alertError} from "../../../Store";
import {
  PORTING_APPLICABLE_COUNTRY,
  PORTING_NUMBER_TYPE
} from "./Constants/PortingRequestFormConstants";
import {formKeyToFormLabel} from "./PortingRequestUtilityFunction";

type TProps = {
  closeForm: () => void;
  setUserDetailForm: Function;
  showUserDetailForm: Boolean;
  portingRequest: INewPortingRequest;
  organizations: Array<IOrganization>;
  setCustomerDetailForm: Function;
};
const PortingRequestUserDetailForm: React.FC<TProps> = ({
  closeForm,
  showUserDetailForm,
  portingRequest,
  organizations,
  setCustomerDetailForm
}) => {
  const [organizationId, setOrganizationId] = useState(null);
  const {handleChange, handleSubmit, values} = useFormik({
    initialValues: {
      country: portingRequest.country,
      title: "",
      company: "",
      vat_number: "",
      street: "",
      building_number: "",
      city: "",
      zip_code: "",
      status: "New"
    },
    onSubmit: (values) => {
      if (!checkValidation(values)) {
        return;
      }
      setCustomerDetailForm({
        ...portingRequest,
        ...values,
        organization_id: organizationId,
        country: portingRequest.country.substring(
          0,
          portingRequest.country.indexOf("(")
        )
      });
    }
  });
  const onChange: HTMLElementEventHandler = (key: string, value: any) => {
    setOrganizationId(value);
  };
  const formFields = [
    {
      fieldName: "title",
      fieldTitle: "Title",
      fieldValue: values.title,
      type: "text",
      placeholder: "Title"
    },
    {
      fieldName: "company",
      fieldTitle: "Company",
      fieldValue: values.company,
      type: "text",
      placeholder: "Company"
    },
    {
      fieldName: "vat_number",
      fieldTitle: "VAT Number",
      fieldValue: values.vat_number,
      type: "text",
      placeholder: "VAT number"
    },
    {
      fieldName: "street",
      fieldTitle: "Street",
      fieldValue: values.street,
      type: "text",
      placeholder: "Street"
    },
    {
      fieldName: "building_number",
      fieldTitle: "Building Number",
      fieldValue: values.building_number,
      type: "text",
      placeholder: "Building"
    },
    {
      fieldName: "country",
      fieldTitle: "Country",
      fieldValue: values.country,
      type: "select",
      placeholder: "Select Country"
    },
    {
      fieldName: "city",
      fieldTitle: "City",
      fieldValue: values.city,
      type: "text",
      placeholder: "City"
    },
    {
      fieldName: "zip_code",
      fieldTitle: "ZIP Code",
      fieldValue: values.zip_code,
      type: "text",
      placeholder: "ZIP Code"
    }
  ];
  return (
    <Translation>
      {(t) => (
        <Form
          onCancel={closeForm}
          onSubmit={handleSubmit}
          stepperFooter={!showUserDetailForm}
        >
          <h2>
            {t(portingRequest && portingRequest.id ? "EDIT" : "ADD")}{" "}
            {t("Porting Request")}
          </h2>
          <div className="portingFormLayout">
            {formFields.map((field) =>
              field.type !== "select" ? (
                <div className="fieldStyle">
                  <div className="fieldTitle">{field.fieldTitle}</div>
                  <div className="fieldValue">
                    <input
                      type="text"
                      name={field.fieldName}
                      value={field.fieldValue}
                      onChange={handleChange}
                      placeholder={field.placeholder}
                    />
                  </div>
                </div>
              ) : (
                <div className="fieldStyle">
                  <div className="fieldTitle">{field.fieldTitle}</div>
                  <div className="fieldValue">
                    <select
                      placeholder={field.placeholder}
                      name={field.fieldName}
                      value={field.fieldValue}
                      onChange={handleChange}
                      disabled
                    >
                      {field.fieldName === "country"
                        ? PORTING_APPLICABLE_COUNTRY.map(
                            ({displayValue, value}) => (
                              <option value={value}>{displayValue}</option>
                            )
                          )
                        : PORTING_NUMBER_TYPE.map(({displayValue, value}) => (
                            <option value={value}>{displayValue}</option>
                          ))}
                    </select>
                  </div>
                </div>
              )
            )}
            <div className="fieldStyle">
              <div className="fieldTitle">Organization</div>
              <div className="fieldValue">
                <Select
                  selected={null}
                  options={organizationOptions(organizations)}
                  name="organization_id"
                  onChange={(key, value) => onChange(key, value)}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Translation>
  );

  function checkValidation(values) {
    let isFormValid = true;
    const formKeys = Object.keys(values);
    for (let counter = 0; counter < formKeys.length; counter++) {
      if (values[formKeys[counter]] === "") {
        isFormValid = false;
        alertError(formKeyToFormLabel(formKeys, counter) + " is required");
        break;
      }
    }
    return isFormValid;
  }
};

function mapStateToProps(state: {organizations: Array<IOrganization>}): {
  organizations: Array<IOrganization>;
} {
  return {
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(PortingRequestUserDetailForm);
