import {HOST, NumbersReq} from "../Store";
import {IDidObjectRaw, IRestriction} from "../Interfaces/Common";

const cache: {
  [countryCode: string]: IRespData;
} = {};

interface IRespData {
  didInventory: IDidObjectRaw[];
  serviceRestrictions: IRestriction[];
}

/**
 * Inventory request, used both to fetch inventory and regulations, because these are returned from the same API call.
 * @param orgNr
 * @param countryCode
 * @returns inventory and restrictions.
 */
async function inventoryRequest(
  orgNr: number,
  countryCode: string
): Promise<IRespData> {
  if (cache[countryCode]) {
    return cache[countryCode];
  }

  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/inventory/${countryCode}`,
    null,
    "get"
  );
  cache[countryCode] = resp.data;
  return resp.data;
}

/**
 * Fetches DID inventory.
 * @param orgNr The organization number the will be used for the request.
 * @param countryCode The countryCode to fetch inventory for. Can be an empty string to fetch for all countries.
 * @returns DID inventory for specified country (or all countries).
 */
export default async function fetchInventory(
  orgNr: number,
  countryCode: string
): Promise<IDidObjectRaw[]> {
  const data: any = await inventoryRequest(orgNr, countryCode);
  return data.didInventory;
}

/**
 * Fetches restrictions for specified country.
 * @param orgNr
 * @param countryCode
 * @returns restrictions
 */
export async function fetchRestrictions(
  orgNr: number,
  countryCode: string
): Promise<IRestriction[]> {
  const data: any = await inventoryRequest(orgNr, countryCode);
  return data.serviceRestrictions;
}
