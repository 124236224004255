import React from "react";
import Translation from "../i18n";
import {connect} from "react-redux";
import {IAuthObject} from "../Interfaces/Redux";
import { fetchExternalShares } from "../Fetch/SyncRequests";
import { IUser } from "../Interfaces/Common";

interface IProps {
  auth: IAuthObject;
  users: Array<IUser>;
}

interface IState {
  externalShares: any[];
}

class ExternalShare extends React.Component<IProps, IState> {

  constructor(props) {
    super(props)
    this.state = {
      externalShares: null
    }
  }

  componentDidMount(): void {
    if(!this.state.externalShares) {
      this.getExternalShares().then(response => {
        console.log(response.data)
        this.setState({externalShares: response.data})
      })
    }
  }

  render(): React.ReactNode {
    return <React.Fragment>
      {this.getHeader()}
      {this.getExternalSharesTable()}
    </React.Fragment>;
  }

  getHeader(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <h1>
              {t("EXTERNAL_SHARE")}
            </h1>
          </React.Fragment>
        )}
      </Translation>
    );
  }

  getExternalSharesTable() {
    console.log(this.state.externalShares)
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <table className="list users">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>User</th>
                  <th>Via</th>
                  <th>Recipients</th>
                  <th>Metadata</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {this.state?.externalShares?.map(share => {
                  const license = share.metadata?.license;
                  const report = share.metadata?.reportId;
                  const siteId = share.metadata?.siteId;
                  const user = this.props.users.find(user => user.id === share.metadata?.userId)
                  return <React.Fragment>
                    <tr>
                      <td>{share.shared_item}</td>
                      <td>{user?.username || share.metadata?.userId}</td>
                      <td>{share.shared_via}</td>
                      <td>{share.to.join(", ")}</td>
                      <td>{siteId && license && report ? `${siteId}: ${license}: ${report}`: "-"}</td>
                      <td>{share.createdAt}</td>
                    </tr>
                  </React.Fragment>
                })}
              </tbody>
            </table>
          </React.Fragment>)}
      </Translation>
    )
  }

  getExternalShares() {
    return fetchExternalShares()
  }
}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    users: state.users,
  };
}

export default connect(mapStateToProps, {})(ExternalShare);
