import {Action, IReduxAction} from "../Interfaces/Redux";
import {IUser} from "../Interfaces/Common";

export default function UsersReducer(state: Array<IUser> = [], action: IReduxAction<Array<IUser>, Action.USERS>): Array<IUser> {
  switch (action.type) {
    case Action.USERS:
      return action.payload;
    default:
      return state;
  }
}
