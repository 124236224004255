import i18n, {TFunction} from "i18next";
import {initReactI18next, Translation} from "react-i18next";
import {IObject} from "./Interfaces/Common";

// the translations
// (tip move them in a JSON file and import them)
const resources: IObject = {
  en: {
    translation: {
      ORGANIZATIONS: "Organizations",
      ORGANIZATION: "Organization",
      NODE: "Node",
      NODES: "Nodes",
      USER: "User",
      USERS: "Users",
      NOTIFICATIONS: "Notifications",
      NOTIFICATION: "Notification",
      ANALYTICS: "Analytics",
      CREATE: "Create",
      NEW: "New",
      RECEIVERS: "Receivers",
      ACTIVE: "Active",
      HAS_EXTERNAL_DOMAIN: "Has external domain",
      WALLBOARD: "Wallboard",
      MFA_AUTH: "MFA authentication",
      PURCHASE_FINALIZED: "Finalized",
      PURCHASE_PENDING: "Pending",
      PURCHASE_PENDING_EXPLANATION: `
        You have numbers pending regulation acceptance.
        If your provided information needs to be complemented Connectel may contact you.
        For more information contact Connectel Helpdesk.
      `,
      ADD: "Add",
      EDIT: "Edit",
      NAME: "Name",
      VBS_HOST: "VBS Host",
      ORGANIZATION_DELETE_CONFIRM: "Are you sure you want to delete?",
      HOSTNAME: "Hostname",
      DELETE: "Delete",
      PARENT: "Parent",
      SELECT_ORGANIZATION: "Select organization",
      SELECT_WIDGETS_HOST: "Select widgets host",
      USER_DELETE_CONFIRM:
        'Are you sure you want to delete?' +
        '<div class="option-check"><input class="external-html" type="checkbox" id="delete_survey"><span>Delete user from Survey?</span></div>' +
        '<div class="option-check"><input class="external-html" type="checkbox" id="delete_motion"><span>Delete user from Agent/User?</span></div>',
      APP_DELETE_CONFIRM: "Are you sure you want to delete?",
      PASSWORD: "Password",
      USERNAME: "Username",
      EMAIL: "Email",
      ROLE: "Role",
      ROLES: "Roles",
      PERMISSIONS: "Permissions",
      SIGN_OUT: "Sign out",
      INSIGHTS_LICENSES: "Insights licenses",
      SKYVIEW_LICENSES: "Skyview licenses",
      USED: "Used",
      EVIEXA: "Eviexa",
      UNLIMITED_INSIGHTS_LICENSES: "Unlimited insights licences",
      UNLIMITED_SKYVIEW_LICENSES: "Unlimited skyview licenses",
      UNLIMITED: "Unlimited",
      INSIGHTS_LICENSE: "Insights license",
      SKYVIEW_LICENSE: "Skyview license",
      INSIGHTS: "Insights",
      SKYVIEW: "Skyview",
      ORGANIZATION_USERS: "Organization users",
      LAUNCH: "Launch",
      SURVEY: "Survey",
      SURVEY_LICENSE_PLAN: "Survey license plan",
      PRODUCTION_MODE: "Production mode",
      ENABLE_VOICEBOT: "Enables Voicebot",
      ENABLE_TOWARDS_ADDRESS: "Enables towards addresses",
      ENABLE_FUTURE_BOOKING: "Enables future bookings",
      LICENSE: "License",
      INSIGHTS_SKYVIEW_LICENSE_PLAN: "Insights/Skyview license plan",
      VOICE_BOT: "Voicebot",
      VOICE_BOT_MAPS: "Voicebot maps",
      BENCHMARK: "Benchmark",
      AI_COACH: "AI Coach",
      SPEECH_ANALYTICS: "Speech Analytics",
      SPEECH_ANALYTICS_LANGUAGE: "Analytics Language",
      SPEECH_ANALYTICS_MODAL: "Analytics Modal",
      SPEECH_ANALYTICS_SENTIMENT: "Analytics Sentiment",
      BENCHMARK_VISIBILITY: "Performance benchmark visibility",
      SMS: "SMS",
      LAST_LOGIN: "Last login",
      NAVISION_IDS: "Navision IDs",
      ADD_NAVISION_ID: "Add navision ID",
      NAVISION: "Navision",
      SOMETHING_WENT_WRONG: "Something went wrong",

      ENABLE_2FA: "Enable two-factor authentication",
      ENABLE_EMAIL_AUTH: "Enable two-factor authentication via email",
      RESET_2FA: "Reset two-factor authentication",
      CONFIRM_RESET_2FA: `
        Are you sure you want to reset two-factor-authentication for this user?
        This will force the user to set it up again.
      `,
      CONFIRM_MFA_NOTIFY_EMAIL: `Are you sure you want receive MFA code via email? Make sure to update a valid email address.`,
      CONFIRM_MFA_AUTH: `Are you sure you want to turn off the user's MFA authentication`,

      SEARCH: "Search",
      UNLOCK: "Unlock",
      PURCHASE_NUMBERS: "Purchase numbers",
      CHECKOUT: "Check out",
      SELECT_VOICE_URI: "Voice URI",
      ADD_TO_CART: "Add to cart",
      REMOVE_FROM_CART: "Remove from cart",
      CHECK_OUT: "Go to checkout",
      COUNTRY_NAME: "Country",
      CITY_NAME: "City",
      AREA_CODE: "Area code",
      SETUP_PRICE: "Setup price",
      MONTHLY_PRICE: "Monthly price",
      DID_TYPE: "Type",
      PURCHASE: "Purchase",

      CUSTOMER_NUMBERS: "Your numbers",
      VOICE_URI_ID: "Voice URI",
      VOICE_URI_ADDRESS: "Voice URI",
      NUMBER: "Phone number",
      CREATED: "Created",
      BILLABLE: "Billable",
      VALID_TO: "Valid to",
      ID: "ID",
      UNASSIGN_NUMBER: "Terminate number",
      DESCRIPTION: "Description",
      STOCK: "Stock",
      QUANTITY: "Quantity",

      YES: "Yes",
      NO: "No",

      INVENTORY: "Inventory of numbers",
      LOADING_ITEMS: "Loading items...",
      CART: "Items in cart",
      NUMBERS_IN_CART: "Numbers in cart",
      TOTAL_SETUP_PRICE: "Total setup price",
      TOTAL_MONTHLY_PRICE: "Total monthly price",
      CONFIRM_PURCHASE: "Confirm purchase",
      PURCHASE_SUCCESSFUL: "The purchase was successful",
      ASSIGNED_NUMBERS: "Assigned numbers",
      PURCHASE_FAILURE:
        "One or more of the purchases failed and is left in the cart",
      SELECT_COUNTRY: "Select country",
      ALL_COUNTRIES: "All countries",
      SELECT_ORG_NR: "Org. nr",
      NO_ORG_NR: "You must select organization to do this",
      NO_VOICE_URIS: "Error when fetching Voice URIs for this organization.",
      NO_NUMBER_ORDERS: "Error when fetching order data for this organization.",
      ONE_OR_MORE_MISSING_VOICE_URI_ID:
        "One or more items are missing Voice URI ID.",
      ONE_OR_MORE_INVALID_QUANTITY:
        "One or more items have an invalid quantity.",
      AGREEMENTS: "Agreements",
      UNASSIGN_SUCCESSFUL: "The numbers will be terminated ",
      UNASSIGN_FAILURE: "The termination failed.",
      CONFIRM_UNASSIGN: "Confirm termination of numbers",
      TERMINATION_PENDING: "Termination pending",
      TERMINATES: "Terminates",
      FROM: "from",
      AND: "and",
      ACCEPT_AGREEMENTS:
        "I confirm that I have read and understood all the agreements above, and that I approve all of them.",
      APPS: "Apps",
      APP: "App",
      CREATED_AT: "Created at",
      SCOPES: "Scopes",
      APP_SECRET_INFO:
        "Make sure to copy your new secret token now. You won’t be able to see it again!",
      OWNER: "Owner",
      CLIENT_ID: "Client id",
      SECRET: "Secret",
      USER_NOT_FOUND: "User not found",
      LEAVE_EMPTY_MOTION_HOST:
        "Leave empty if motion hostname is the same as hostname!",
      MOTION_HOSTNAME: "Motion host",
      PORTING_REQUESTS: "Porting request",
      NUMBER_ORDERS: "Order history",
      ORDER_DATE: "Ordered",
      ENTERPRISE: "Enterprise",
      INSIGHTS_PSEUDONYMIZE_AGENTS: "Pseudonymize agents in insights",
      NO_PORTING_REQUEST_FOUND: "No Porting Requests found",
      STATUS: "Status",
      COMMENTS: "Comments",
      DELETE_PORTING_REQUEST_CONFIRM: "Are you sure want to delete?",
      INVALID_RANGE: "From Number & To Number range is not valid",
      NUMBER_TYPE: "Number type",
      FROM_NUMBER: "From number",
      TO_NUMBER: "To number",
      PORTING_REQUEST_TITLE: "Title",
      PORTING_REQUEST_COMPANY: "Company",
      SYNC_REQUESTS: "Sync requests",
      STARTED_AT: "Started At",
      COMPLETED_AT: "Completed At",
      HOST_NAME: "Host Name",
      SYNC_STATUS: "Status",
      NO_SYNC_REQUEST_FOUND: "No Sync Request Found",
      TITLE: "Title",
      MESSAGE: "Message",
      STAFF: "Staff",
      NUMBERS: "Numbers",
      ACCOUNT: "Account",
      SETTINGS: "Settings",
      ADD_NOTIFICATION: "Add notification",
      DELETE_SELECTED_NOTIFICATION: "Delete selected notifications",
      WIDGETS_HOST: "Widgets host",
      SNO: "S. No.",
      PHONE_NO: "Phone No.",
      TIME: "Time",
      SMS_NOTIFICATIONS: "SMS notifications",
      EXTERNAL_SHARE: "External shares",
      CUSTOMER_OVERVIEW: "Customer Overview",
      LOGO: "Logo",
      URL: "Url",
    }
  },
  sv: {
    translation: {}
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    lng: "en",
    resources
  });

export default Translation;

export const t: TFunction = i18n.t;

export {i18n};
