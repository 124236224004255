import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import "../Style/Sass/PortingRequestForm.scss";
import {INewPortingRequest, IOrganization} from "../Interfaces/Common";
import {i18n} from "../i18n";
import {IAuthObject} from "../Interfaces/Redux";
import {alertInfo, alertConfirm, selectOrganization} from "../Store";
import {Roles} from "../Helpers/Roles";
import {PortingRequest} from "../Components/Form/PortingRequest/PortingRequest";
import SelectOrganization from "../Components/Form/SelectOrganization";
import {
  fetchPortingRequest,
  deletePortingRequest
} from "../Fetch/PortingRequests";
import Icon from "../Components/Icon";
import {PortingEditRequest} from "../Components/Form/PortingRequest/PortingEditRequest";

interface IProps {
  auth: IAuthObject;
  orgNr: number;
  organizations: IOrganization[];
}
export const PortingRequests: React.FC<IProps> = React.memo(
  ({auth, orgNr, organizations}) => {
    const {t} = useTranslation();
    const [showModal, toggleModal] = useState(false);
    const [reloadList, setReloadList] = useState(false);
    const [editPortingRequest, setEditPoringRequest] = useState(null);
    const [showEditModal, toggleEditModal] = useState(false);
    const openAddPortingForm = React.useCallback(() => toggleModal(true), []);
    const closeAddPortingForm = React.useCallback(() => toggleModal(false), []);
    const openEditPortingForm = React.useCallback(
      () => toggleEditModal(true),
      []
    );
    const closeEditPortingForm = React.useCallback(
      () => toggleEditModal(false),
      []
    );
    const loadList = async () => {
      const portingRequests: any = await fetchPortingRequest(orgNr);
      setPortingRequestsList(portingRequests.data.portingRequests);
    };

    useEffect(() => {
      try {
        if (orgNr) {
          loadList();
        } else {
          alertInfo(i18n.t("SELECT_ORGANIZATION"));
        }
      } catch (err) {
        setPortingRequestsList([]);
        alertInfo(i18n.t("NO_PORTING_REQUEST_FOUND"));
      }
    }, [orgNr]);

    useEffect(() => {
      if (reloadList) {
        try {
          if (orgNr) {
            setReloadList(false);
            loadList();
          } else {
            alertInfo(i18n.t("SELECT_ORGANIZATION"));
          }
        } catch (err) {
          setPortingRequestsList([]);
          alertInfo(i18n.t("NO_PORTING_REQUEST_FOUND"));
        }
      }
    }, [reloadList]);
    const [portingRequestsList, setPortingRequestsList] = useState([]);

    return (
      <div>
        <div className="container-header flex justify-between items-center px-2">
          <h1>{t("PORTING_REQUESTS")}</h1>

          <div className="flex">
            <div className="mr-2 cart-icon-container"></div>
            <button className="button" onClick={openAddPortingForm}>
              {t("ADD NEW")}
            </button>
          </div>
        </div>
        <div className="p-2">
          <SelectOrganization
            orgNr={orgNr}
            organizations={organizations}
            onChange={(orgNr) => {
              selectOrganization(orgNr);
            }}
          />
        </div>
        <article>
          <table className="list organizations">
            <thead>
              <tr>
                <th>{t("COUNTRY_NAME")}</th>
                <th>{t("NUMBER_TYPE")}</th>
                <th>{t("AREA_CODE")}</th>
                <th>{t("FROM_NUMBER")}</th>
                <th>{t("TO_NUMBER")}</th>
                <th>{t("PORTING_REQUEST_TITLE")}</th>
                <th>{t("PORTING_REQUEST_COMPANY")}</th>
                <th>{t("STATUS")}</th>
                <th>{t("COMMENTS")}</th>
              </tr>
            </thead>
            <tbody>
              {portingRequestsList.length > 0
                ? renderPoringRequestLists(
                    auth,
                    portingRequestsList,
                    openEditPortingForm,
                    setEditPoringRequest,
                    setReloadList
                  )
                : renderEmptyRowMessage()}
            </tbody>
          </table>
        </article>

        {showModal && (
          <PortingRequest
            closeAddPortingForm={closeAddPortingForm}
            reloadList={setReloadList}
            portingRequest={undefined}
          />
        )}
        {showEditModal && (
          <PortingEditRequest
            closeAddPortingForm={closeEditPortingForm}
            portingRequest={editPortingRequest}
            reloadList={setReloadList}
          />
        )}
      </div>
    );
  }
);

interface IStoreProps {
  auth: IAuthObject;
  organizations: Array<IOrganization>;
  orgNr: number;
}

function mapStateToProps(state: IStoreProps): IStoreProps {
  return {
    auth: state.auth,
    orgNr: state.orgNr,
    organizations: state.organizations
  };
}
const renderPoringRequestLists = (
  auth: IAuthObject,
  portingRequestsList: INewPortingRequest[],
  openEditPortingForm: Function,
  setEditPoringRequest: Function,
  setReloadList: Function
): React.ReactNode => {
  return portingRequestsList.map((portingRequest) => [
    <tr key={portingRequest.id}>
      <td>{portingRequest.country}</td>
      <td>{portingRequest.number_type}</td>
      <td>{portingRequest.area_code}</td>
      <td>{portingRequest.from_number}</td>
      <td>{portingRequest.to_number}</td>
      <td>{portingRequest.title}</td>
      <td>{portingRequest.company}</td>
      <td>{portingRequest.status}</td>
      <td>{portingRequest.comments}</td>
      <td>
        {auth.user.role_id === Roles.god && (
          <>
            <Icon
              icon="mode_edit"
              onClick={() => {
                setEditPoringRequest(portingRequest);
                openEditPortingForm();
              }}
              title={`${i18n.t("EDIT")} ${i18n.t("USER")}`}
            />
            <Icon
              icon="delete"
              onClick={() => {
                alertConfirm(
                  i18n.t("DELETE_PORTING_REQUEST_CONFIRM"),
                  async (e) => {
                    await deletePortingRequest(portingRequest.id);
                    setReloadList(true);
                  }
                );
              }}
              title={`${i18n.t("DELETE")} ${i18n.t("USER")}`}
            />
          </>
        )}
      </td>
    </tr>
  ]);
};

function renderEmptyRowMessage(): React.ReactNode {
  return (
    <tr>
      <td colSpan={7} className="portingListNoRecordFound">
        {i18n.t("NO_PORTING_REQUEST_FOUND")}
      </td>
    </tr>
  );
}

export default connect(mapStateToProps, {})(PortingRequests);
