import {Component} from "react";
import React from "react";
import Translation from "../Helpers/Translation";

export default class ErrorBoundary extends Component<any, {
  error: Array<string>,
  errorInfo: any
}> {
  constructor(props: any) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  public componentDidCatch(error: any, errorInfo: any): void {
    /*
    if (typeof error === "object" && error !== null && error.hasOwnProperty("data"))
      error = error.data.data.map(d => d.message);
    else
      error = [error];
      */
    this.setState({
      error: error?.data?.data?.map(e => e.message || e.error) || [error.toString()],
      errorInfo: errorInfo
    });
  }

  public render(): React.ReactNode {
    if (this.state.errorInfo) {
      return <Translation>
        {t =>
          <div>
            <h2>{t("ERROR")}</h2>
            {this.state.error && <ul>{
              this.state.error.map(e => {
                return <li>{e}</li>;
              })
            }</ul>}
          </div>
        }
      </Translation>;
    }
    return this.props.children;
  }
}