import React from "react";
import {connect} from "react-redux";
import Translation, {i18n} from "../i18n";
import {IEditNumber, IOrganization} from "../Interfaces/Common";
import {IAuthObject} from "../Interfaces/Redux";
import {IVoiceUri, INumber} from "../Interfaces/Common";
import fetchVoiceUris from "../Fetch/VoiceUris";
import unassignNumber from "../Fetch/Unassign";
import {alertConfirm, alertInfo, selectOrganization} from "../Store";
import SelectOrganization from "../Components/Form/SelectOrganization";

import "../Style/Sass/CustomerNumbers.scss";
import SyncOrgRoute from "../Components/SyncOrgRoute";
import { Roles } from "../Helpers/Roles";
import Icon from "../Components/Icon";
import EditCustomerNumber from "../Components/Form/CustomerNumber/EditCustomerNumber";

interface IProps {
  match: any;
  history: any;
  orgNr: number;
  auth: IAuthObject;
  organizations: IOrganization[];
}

interface IState {
  voiceUris: IVoiceUri[];
  assignNumber: INumber;
  editNumber: IEditNumber;
  showEditNumberModal: boolean;
}

/**
 *
 * @param number The DID object.
 * @returns The object's number formated with X or XX in the beginning if it's a 10- och 100-series.
 */
export function formatDid(number: INumber): string {
  return (
    (number.type === 10 && number.dids[0].slice(0, -1) + "X") ||
    (number.type === 100 && number.dids[0].slice(0, -2) + "XX") ||
    number.dids[0] ||
    ""
  );
}

export function isNumberBillable(isBillable: Boolean): string {
  return i18n.t(isBillable ? "YES" : "NO");
}

export function isNumberActive(number: INumber): string {
  return i18n.t(!number.didPending && (!number.validTo || Date.now() < new Date(number.validTo).getTime()) ? "YES" : "NO");
}

/**
 * View with information about a customer's numbers.
 */
class CustomerNumbers extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      assignNumber: null,
      voiceUris: [],
      editNumber: null,
      showEditNumberModal: false
    };

    if (props.orgNr) {
      this.fetchVoiceUris(props.orgNr);
    }
  }

  public render(): React.ReactNode {
    const editNumber = (number: INumber) => {
      this.setState({
        showEditNumberModal: true,
        editNumber: {
          orgNr: this.props.orgNr,
          phoneNumber: number.phoneNumber,
          description: number.description
        }
      });
    };

    const closeEditNumberModal = () => {
      this.setState({
        showEditNumberModal: false,
        editNumber: null
      });
    };

    const reloadList = () => {
      this.fetchVoiceUris(this.props.orgNr);
    };

    const getNumberRows: (voiceUris: IVoiceUri[]) => React.ReactNode[] = (
      voiceUris
    ) =>
      voiceUris.map((voiceUri) => [
        <tr key={voiceUri.id}>
          <td>{voiceUri.address}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>,
        voiceUri.numbers.map((number) => [
          <tr key={number.id}>
            <td></td>
            <td>{number.type}</td>
            <td>{number.phoneNumber}</td>
            <td>{number.created}</td>
            <td>{isNumberBillable(number.billable)}</td>
            <td>{isNumberActive(number)}</td>
            <td>
              {number.validTo ? (
                <p>
                  {i18n.t("TERMINATES")} {number.validTo}
                </p>
              ) : (
                <button
                  className="button"
                  onClick={() => this.unassignNumber(voiceUri, number)}
                >
                  {i18n.t("UNASSIGN_NUMBER")}
                </button>
              )}
            </td>
            <td>{number.description}</td>
            {this.props.auth.user.role_id <= Roles.manager && (
              <td>
                <Icon
                  icon="mode_edit"
                  onClick={() => editNumber(number)}
                  title={`${i18n.t("EDIT")} ${i18n.t("DESCRIPTION")}`}
                />
              </td>
            )}
          </tr>
        ])
      ]);

    const orgNrRouteParam: number = parseInt(this.props.match.params.orgNr, 10);

    return (
      <Translation>
        {(t) => (
          <>
            <SyncOrgRoute
              orgNr={this.props.orgNr}
              orgNrRoute={orgNrRouteParam}
              routeTail={"/numbers/"}
            />

            <div className="container-header flex justify-between items-center px-2">
              <h1>{t("CUSTOMER_NUMBERS")}</h1>
            </div>

            <div className="p-2">
              <SelectOrganization
                orgNr={this.props.orgNr}
                organizations={this.props.organizations}
                onChange={(orgNr) => {
                  selectOrganization(orgNr);
                  this.fetchVoiceUris(orgNr);
                }}
              />
              <div className="mt-2">
                <table className="list small-table">
                  <thead>
                    <tr>
                      <th>Linked DIDs</th>
                      <th>Linked URIs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.voiceUris.reduce(
                          (dids, uri) => dids + uri.numbers.length,
                          0
                        )}
                      </td>
                      <td>{this.state.voiceUris.length}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <article>
              <table className="list organizations">
                <thead>
                  <tr>
                    <th>{t("VOICE_URI_ADDRESS")}</th>
                    <th>{t("DID_TYPE")}</th>
                    <th>{t("NUMBER")}</th>
                    <th>{t("CREATED")}</th>
                    <th>{t("BILLABLE")}</th>
                    <th>{t("ACTIVE")}</th>
                    <th>{t("UNASSIGN_NUMBER")}</th>
                    <th>{t("DESCRIPTION")}</th>
                    {this.props.auth.user.role_id <= Roles.manager && <th />}
                  </tr>
                </thead>
                <tbody>
                  {this.state.voiceUris.length > 0 &&
                    getNumberRows(this.state.voiceUris)}
                </tbody>
              </table>
            </article>
            {this.state.showEditNumberModal && (
              <EditCustomerNumber
                closeModal={closeEditNumberModal}
                editNumber={this.state.editNumber}
                reloadList={reloadList}
              />
            )}
          </>
        )}
      </Translation>
    );
  }

  /**
   * Terminates a number from a voice URI.
   */
  private async unassignNumber(
    voiceUri: IVoiceUri,
    number: INumber
  ): Promise<void> {
    alertConfirm(
      `${i18n.t("CONFIRM_UNASSIGN")} ${number.dids.join(", ")} ${i18n.t(
        "FROM"
      )} ${i18n.t("VOICE_URI_ADDRESS")} ${voiceUri.address}`,
      async (e) => {
        try {
          const resp: any = await unassignNumber(
            this.props.orgNr,
            voiceUri.id,
            number.id
          );
          this.setState((state) => {
            const voiceUris: IVoiceUri[] = state.voiceUris.slice();
            const numbers: INumber[] = voiceUri.numbers.slice();
            const voiceUriIndex: number = voiceUris.findIndex(
              (el) => voiceUri.id === el.id
            );
            const numberIndex: number = voiceUri.numbers.findIndex(
              (n) => n.id === number.id
            );
            numbers[numberIndex] = {
              ...number,
              terminationPending: true,
              validTo: resp.data.validTo
            };
            voiceUris[voiceUriIndex] = {
              ...voiceUri,
              numbers
            };
            return {
              voiceUris
            };
          });

          alertInfo(`${i18n.t("UNASSIGN_SUCCESSFUL")} ${resp.data.validTo}.`);
        } catch (err) {
          console.log(err);
        }
      }
    );
  }

  /**
   * Fetches voice URIs, including all the corresponding numbers.
   */
  private async fetchVoiceUris(orgNr: number, options: {reload: boolean} = {reload: false}): Promise<void> {
    try {
      const voiceUris: IVoiceUri[] = await fetchVoiceUris(orgNr, options);
      this.setState({
        voiceUris
      });
    } catch (err) {
      this.setState({voiceUris: []});
      alertInfo(i18n.t("NO_VOICE_URIS"));
    }
  }
}

interface IStoreProps {
  auth: IAuthObject,
  organizations: Array<IOrganization>;
  orgNr: number;
}
function mapStateToProps(state: IStoreProps): IStoreProps {
  return {
    auth: state.auth,
    orgNr: state.orgNr,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(CustomerNumbers);
