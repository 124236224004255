import {HTMLevent, INode, IOrganization} from "./Common";
import {Roles} from "../Helpers/Roles";

export interface IAuthObject {
  id?: number;
  isAuth?: boolean;
  nodes?: Array<INode>;
  organizations?: Array<IOrganization>;
  token?: string;
  tokens?: {
    insights:string|null;
    motion:string|null;
    portal:string|null;
    skyview:string|null;
    survey:string|null;
  };
  user?: {
    id: number;
    name: string;
    role_id: Roles;
    username: string;
  };
}


export interface IConfigTable {
  sort: [string, "asc" | "desc"];
}

export interface IConfigObject {
  lang?: string;
  tables: {
    users: IConfigTable;
  };
  theme?: string;
  window?: {
    height: number;
    width: number;
  };
}

export type IConfigAction = IReduxAction<IConfigObject, Action.SET_CONFIG>;
export type DispatchConfig = (config: IConfigObject) => void;

export interface IStatusObject {
  type: Action.LOADING | Action.SAVING | Action.UPDATING | Action.DELETING;
  uuid: string;
}

export type IStatusAction = IReduxAction<
  {
    status: boolean;
    uuid: string;
  },
  IStatusObject["type"] | Action.LOADING_DONE
>;

export type DispatchEventLoading = (
  status: boolean,
  uuid: IStatusObject["uuid"]
) => void;

export interface IAlertObject {
  message: any;
  uuid: string;
  type: Action.ALERT_INFO | Action.ALERT_ERROR | Action.ALERT_CONFIRM;
  onConfirm?: (event?: any) => Promise<any>;
  onCancel?: HTMLevent;
  resolve: (confirmed: boolean) => void; // used to make alerts async
  inputHandlers?: Record<string, Function>,
}

export type IAlertAction = IReduxAction<
  {
    message: IAlertObject["message"];
    uuid: IAlertObject["uuid"];
    onConfirm: IAlertObject["onConfirm"];
    onCancel: IAlertObject["onCancel"];
    resolve: (confirmed: boolean) => void;
  },
  IAlertObject["type"] | Action.REMOVE_ALERT
>;

export type DispatchEventAlert = (
  message: IAlertObject["message"],
  onConfirm?: IAlertObject["onConfirm"],
  onCancel?: IAlertObject["onCancel"],
  inputHandlers?: Record<string, Function>,
) => void;
export type DispatchEventRemoveAlert = (uuid: IAlertObject["uuid"]) => void;

export interface IReduxAction<T, A> {
  type: A;
  payload: T;
}

export enum Action {
  ALERT_INFO = "ALERT_INFO",
  REMOVE_ALERT = "REMOVE_ALERT",
  ALERT_ERROR = "ALERT_ERROR",
  ALERT_CONFIRM = "ALERT_CONFIRM",
  LOADING = "LOADING",
  SAVING = "SAVING",
  DELETING = "DELETING",
  UPDATING = "UPDATING",
  LOADING_DONE = "LOADING_DONE",
  TOKEN_RESP = "TOKEN_RESP",
  NODES = "NODES",
  USERS = "USERS",
  SET_CONFIG = "SET_CONFIG",
  ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART",
  REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART",
  EDIT_CART_ITEM = "EDIT_CART_ITEM",
  SELECT_ORGANIZATION = "SELECT_ORGANIZATION"
}
