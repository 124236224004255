import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import {INewOrganizationUser, INewUser, IOrganization, IUser} from "../Interfaces/Common";
import Translation from "../i18n";
import {options as RoleOptions, Roles} from "../Helpers/Roles";
import UserForm from "../Components/Form/User";
import cloneDeep from "clone-deep";
import { CSVLink } from 'react-csv';
import {
  addOrganizationUser,
  addUser,
  alertConfirm,
  deleteUser,
  removeOrganizationUser,
  updateUser,
  updateOrganizationUser,
  setTableSettings, unlockUser
} from "../Store";
import Icon from "../Components/Icon";
import i18n from "i18next";
import {IAuthObject, IConfigObject} from "../Interfaces/Redux";

interface IProps {
  auth: IAuthObject;
  config: IConfigObject;
  organizations: Array<IOrganization>;
  users: Array<IUser>;
}

interface IState {
  page: number;
  search: string;
  user: INewUser;
  toDelete: {
    fromSurvey: boolean;
    fromMotion: boolean;
  };
}

let timeout: any = null;

class Users extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      page: 1,
      search: "",
      user: null,
      toDelete: {
        fromSurvey: false,
        fromMotion: false
      }
    };
  }

  public componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  public onScroll = () => {
    const height = document.body.offsetHeight as number;
    const scrollToBottom =
      document.body.scrollHeight - window.innerHeight - window.scrollY;
    let page: number = Math.ceil(height / 1750);

    if (scrollToBottom < 100) page++;

    if (page > this.state.page) this.setState({page});
  };

  public render(): React.ReactNode {
    const RoleObject = {};
    RoleOptions.forEach((role) => {
      RoleObject[role.key + ""] = role.title;
    });
    let users = cloneDeep(this.props.users);
    const excelData = users.map((user) => ({
      id: user.id,
      name: user.name,
      username: user.username,
      email: user.email,
      active: user.active,
      role: RoleObject[user.role_id],
      lastLogin: user.last_login
    }));
    if ((this.state.search + "").length)
      users = users.filter((u) => {
        return `${u.name} ${u.username} ${u.id}`
          .toLowerCase()
          .includes(this.state.search);
      });

    const sort: IConfigObject["tables"]["users"]["sort"] = this.props.config
      .tables?.users?.sort || ["id", "asc"];
    users.sort((a: IUser, b: IUser) => {
      const [col, dir] = sort;
      let resp: number = 0;
      if (!["id", "active"].includes(col)) {
        resp = (a[col] + "").localeCompare(b[col] + "", "en", {
          sensitivity: "base"
        });
      } else {
        if (a[col] > b[col]) resp = 1;
        else if (a[col] < b[col]) resp = -1;
      }
      if (dir === "desc") resp *= -1;
      return resp;
    });

    users = users.slice(0, this.state.page * 50);
    const arrow = (col: string) => {
      return (
        sort[0] === col &&
        (sort[1] === "desc" ? (
          <i className="arrow up"></i>
        ) : (
          <i className="arrow down"></i>
        ))
      );
    };
    return (
      <Translation>
        {(t) => (
          <>
            {this.state.user !== null && (
              <UserForm
                user={this.state.user}
                onCloseForm={() => {
                  this.User(null);
                }}
                onSave={this.onSaveUser}
                onAddNewUserPermission={this.onAddNewUserPermission}
                onRemoveUserPermission={this.onRemoveUserPermission}
                onEditNewUserPermission={this.onEditNewUserPermission}
              />
            )}
            <h1>
              {t("USERS")}
              <input
                className="search"
                placeholder={i18n.t("SEARCH") + "..."}
                onKeyUp={(e) => this.filterTable(e)}
              />
              {this.props.auth.user.role_id <= Roles.manager && (
                <>
                  <CSVLink
                    className="downloadButton"
                    data={excelData}
                    filename={"users.csv"}
                  >
                    Export
                  </CSVLink>
                  <a
                    className="button"
                    onClick={() =>
                      this.User({
                        active: true,
                        name: "",
                        organization_id: 0,
                        username: "",
                        role_id: 4,
                        motion_node_id: 0,
                        motion_user_id: 0,
                        insights_license: true,
                        skyview_license: true,
                        mfa_notify_email: false,
                        wallboard: false,
                        mfa_auth: false,
                      })
                    }
                  >
                    {t("ADD")} {t("USER")}
                  </a>
                </>
              )}
            </h1>
            <article>
              <table className="list users">
                <thead>
                  <tr>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("id")}
                    >
                      {t("ID")}
                      {arrow("id")}
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("name")}
                    >
                      {t("NAME")}
                      {arrow("name")}
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("username")}
                    >
                      {t("USERNAME")}
                      {arrow("username")}
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("email")}
                    >
                      {t("EMAIL")}
                      {arrow("email")}
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("phone_number")}
                    >
                      {t("NUMBER")}
                      {arrow("phoneNumber")}
                    </th>
                    <th>{t("ROLE")}</th>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("active")}
                    >
                      {t("ACTIVE")}
                      {arrow("active")}
                    </th>
                    <th
                      className="sortable"
                      onClick={() => this.sortTable("last_login")}
                    >
                      {t("LAST_LOGIN")}
                      {arrow("last_login")}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => {
                    return (
                      <tr
                        key={user.id}
                        className={user.is_deleted && "row_deleted"}
                      >
                        <td className={sort[0] === "id" ? "sorted" : ""}>
                          {user.id}
                        </td>
                        <td className={sort[0] === "name" ? "sorted" : ""}>
                          {user.name}
                        </td>
                        <td className={sort[0] === "username" ? "sorted" : ""}>
                          {user.username}
                        </td>
                        <td className={sort[0] === "email" ? "sorted" : ""}>
                          {user.email}
                        </td>
                        <td
                          className={sort[0] === "phone_number" ? "sorted" : ""}
                        >
                          {user.phone_number}
                        </td>
                        <td>{RoleObject[user.role_id]}</td>
                        <td>
                          <Icon
                            icon={`${user.active ? "checkmark" : "cross"}`}
                            style={{
                              color: `${user.active ? "green" : "red"}`
                            }}
                          />
                        </td>
                        <td
                          className={sort[0] === "last_login" ? "sorted" : ""}
                        >
                          {user.last_login
                            ? moment(user.last_login).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-"}
                        </td>
                        <td>
                          {this.props.auth.user.role_id <= Roles.manager &&
                            ((this.props.auth.user.role_id <= Roles.admin &&
                              user.role_id >= this.props.auth.user.role_id) ||
                              (this.props.auth.user.role_id === Roles.manager &&
                                user.role_id >
                                  this.props.auth.user.role_id)) && (
                              <>
                              {/* Restricts edit operation if user is deleted unless auth user is god */}
                                {(this.props.auth.user.role_id == Roles.god || !user.is_deleted) 
                                && (
                                  <Icon
                                    icon="mode_edit"
                                    onClick={() => {
                                      this.User({
                                        phone_number: user.phone_number,
                                        email: user.email,
                                        active: user.active,
                                        username: user.username,
                                        id: user.id,
                                        role_id: user.role_id,
                                        name: user.name,
                                        password: "",
                                        motion_node_id: user.motion_node_id,
                                        motion_user_id: user.motion_user_id,
                                        mfa_notify_email: user.mfa_notify_email,
                                        wallboard: user.wallboard,
                                        mfa_auth: user.mfa_auth,
                                      });
                                    }}
                                    title={`${i18n.t("EDIT")} ${i18n.t("USER")}`}
                                  />
                                )}
                                {/* Restricts delete if user is alredy soft deleted */}
                                {!user.is_deleted && (
                                  <Icon
                                    icon="delete"
                                    onClick={() => {
                                      alertConfirm(
                                        i18n.t("USER_DELETE_CONFIRM"),
                                        (e) => {
                                          const survey = this.state.toDelete?.fromSurvey || false;
                                          const motion = this.state.toDelete?.fromMotion || false;
                                          this.setState({toDelete: {fromMotion: false, fromSurvey: false}})
                                          return deleteUser(user.id, {
                                            survey,
                                            motion,
                                          });
                                        },
                                        () => this.setState({toDelete: {fromMotion: false, fromSurvey: false}}),
                                        {
                                          delete_survey: (e) => this.setState({toDelete: {...this.state.toDelete, fromSurvey: e.target.checked}}),
                                          delete_motion: (e) => this.setState({toDelete: {...this.state.toDelete, fromMotion: e.target.checked}})
                                        }
                                      );
                                    }}
                                    title={`${i18n.t("DELETE")} ${i18n.t(
                                      "USER"
                                    )}`}
                                  />
                                )}
                                {user.login_fails >= 5 && (
                                  <Icon
                                    icon="lock_open"
                                    onClick={() => {
                                      unlockUser(user.id).then((e) => {
                                        user.login_fails = 0;
                                      });
                                    }}
                                    title={`${i18n.t("UNLOCK")} ${i18n.t(
                                      "USER"
                                    )}`}
                                  />
                                )}
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </article>
          </>
        )}
      </Translation>
    );
  }

  private User = (user: INewUser) => {
    this.setState({
      user
    });
  };

  private onSaveUser = (user: INewUser) => {
    console.log("user", user);
    let promise: any = null;

    if (!user.id) promise = addUser(user);
    else promise = updateUser(user);

    promise.then(() => {
      this.setState({
        user: null
      });
    });
  };

  private sortTable = (col: string) => {
    this.setState({page: 1});
    const sort: IConfigObject["tables"]["users"]["sort"] = this.props.config
      .tables?.users?.sort || ["id", "asc"];
    setTableSettings("users", {
      sort: [col, sort[1] === "asc" ? "desc" : "asc"]
    });
  };

  private filterTable = (e: any) => {
    const search = e.target.value.toLowerCase();
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.setState({search});
    }, 350);
  };

  private onAddNewUserPermission(
    payload: INewOrganizationUser,
    callback: Function
  ): void {
    addOrganizationUser(payload).then(() => {
      callback();
    });
  }

  private onRemoveUserPermission(
    user_id: number,
    id: number,
    callback: Function
  ): void {
    removeOrganizationUser(user_id, id).then(() => {
      callback();
    });
  }

  private onEditNewUserPermission(
    user_id: number,
    id: number,
    key: string,
    val: any,
    callback: Function
  ) {
    updateOrganizationUser(user_id, id, key, val).then(() => {
      callback();
    });
  }
}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    config: state.config,
    organizations: state.organizations,
    users: state.users
  };
}

export default connect(mapStateToProps, {})(Users);
