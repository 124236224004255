import React, {Component} from "react";
import {INewOrganization, HTMLevent, HTMLElementEventHandler, IOrganization} from "../../Interfaces/Common";
import Translation from "../../i18n";
import cloneDeep from "clone-deep";
import Popup from "../../Components/Popup";
import Form from "../../Components/Form";
import {Text, Select, Switch} from "./Element";
import {connect} from "react-redux";
import {alertError} from "../../Store";
import { organizationNodeToOptions, organizationOptions} from "../../Helpers/Common";
import Number from "./Element/Number";
import Icon from "../Icon";
import {IAuthObject} from "../../Interfaces/Redux";
import {Roles} from "../../Helpers/Roles";
import Typeahead from "./Element/Typeahead";
import { LicenseType } from "connectel-shared/interfaces/Site";
import { DeepgramLanguagesLabels, DeepgramModalByLanguage, DeepgramTranscriptionLanguage, DeepgramTranscriptionModal } from "connectel-shared/interfaces/Deepgram";

interface IProps {
  organization: INewOrganization;
  onCloseForm: HTMLevent;
  onSave: Function;
  organizations: Array<IOrganization>;
  organizationsNode:Array<IOrganization>;
  auth: IAuthObject;
}

interface IState {
  organization: IProps["organization"];
}

const roles = [
  {id: 2, title: "Admin"},
  {id: 3, title: "Manager"},
  {id: 4, title: "Agent"}
];

export const survey_plans = [
  {name: LicenseType.ENTRY, isEnabled: false, icon: "home2"},
  {name: LicenseType.STANDARD, isEnabled: false, default: true, icon: "home3"},
  {name: LicenseType.ENTERPRISE, isEnabled: false, icon: "office"}
]

export const license_plans = [
  {name: LicenseType.ENTRY, isEnabled: true, icon: "home2"},
  {name: LicenseType.STANDARD, isEnabled: true, icon: "home3"},
  {name: LicenseType.ENTERPRISE, isEnabled: true, icon: "office"},
]


class Organization extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      organization: cloneDeep(this.props.organization)
    };
  }

  public render(): React.ReactNode {

    return <Translation>{
      t => <>
        <Popup onCloseForm={this.props.onCloseForm}>
          <Form onSubmit={this.onSave} onCancel={this.props.onCloseForm}>
            <h2>{t(this.props.organization.id ? "EDIT" : "ADD")} {t("ORGANIZATION")}</h2>

            <table style={{width: "100%"}}>
              <tbody>
              {
                this.props.auth.user.role_id === Roles.god &&
                <>
                  <tr>
                    <th style={{width: "30%"}}>
                      {t("NAME")}
                    </th>
                    <td className="nopadding">
                      <Text value={this.state.organization.name} name="name" onChange={this.onChange}/>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {t("PARENT")}
                    </th>
                    <td className="nopadding">
                      <Select selected={this.state.organization.parent_id}
                              options={organizationOptions(this.props.organizations)} name="parent_id"
                              onChange={this.onChange}/>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {t("ACTIVE")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.active} name="active" onChange={this.onChange}/>
                    </td>
                  </tr>                 
                  <tr>
                    <th>
                      {t("HAS_EXTERNAL_DOMAIN")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.has_external_domain} name="has_external_domain" onChange={this.onChange}/>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {t("UNLIMITED_INSIGHTS_LICENSES")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.insights_licenses === -1} name="active"
                              onChange={(_, val) => {
                                this.onChange("insights_licenses", val ? -1 : 10);
                              }
                              }/>
                    </td>
                  </tr>

                  {this.state.organization.insights_licenses !== -1 &&
                  <tr>
                    <th>
                      {t("INSIGHTS_LICENSES")}
                    </th>
                    <td className="nopadding">
                      <Number value={this.state.organization.insights_licenses} name="insights_licenses"
                              onChange={this.onChange}/>
                    </td>
                  </tr>
                  }

                  <tr>
                    <th>
                      {t("UNLIMITED_SKYVIEW_LICENSES")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.skyview_licenses === -1} name="active"
                              onChange={(_, val) => {
                                this.onChange("skyview_licenses", val ? -1 : 10);
                              }
                              }/>
                    </td>
                  </tr>

                  {this.state.organization.skyview_licenses !== -1 &&
                  <tr>
                    <th>
                      {t("SKYVIEW_LICENSES")}
                    </th>
                    <td className="nopadding">
                      <Number value={this.state.organization.skyview_licenses} name="skyview_licenses"
                              onChange={this.onChange}/>
                    </td>
                  </tr>
                  }

                  <tr>
                    <th>
                      {t("INSIGHTS_SKYVIEW_LICENSE_PLAN")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.license} name="license" onChange={this.onChange}/>
                    </td>
                  </tr>
                  { this.state.organization.license && <tr>
                    <td></td>
                    <td>
                      <ul style={{listStyle: "none", margin: "0"}}>
                        {license_plans.map(license_plan => {
                            return (
                              <li style={{ padding: "2px" }}>
                                <input
                                    type="radio"
                                    value={license_plan.name}
                                    checked={this.state.organization.license_type === license_plan.name}
                                    onClick={e => this.onChangeRadio(e, "license_type")}
                                    name="license_type"
                                    style={{ width: "20px", margin: "6px", height: "15px" }}
                                    disabled={!license_plan.isEnabled}
                                  />
                                <Icon
                                  icon={license_plan.icon}
                                  style={{ width: "20px", height: "20px", display: "inline-block" }}
                                  tooltip={{ id: `survey-license-type-${license_plan.name}`, message: license_plan.name}}
                                ></Icon>
                            </li>
                            );
                          })}
                        </ul>
                    </td>
                  </tr>}
                  <tr>
                    <th>
                      {t("SURVEY_LICENSE_PLAN")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.survey_license} name="survey_license" onChange={this.onChange}/>
                    </td>
                  </tr>
                  { this.state.organization.survey_license && <tr>
                    <td></td>
                    <td>
                      <ul style={{listStyle: "none", margin: "0"}}>
                        {survey_plans.map(survey_plan => {
                          return <li style={{ padding: "2px" }}>
                            <input
                              type="radio"
                              value="entry"
                              checked={this.state.organization.survey_license_type === survey_plan.name}
                              onClick={e => this.onChangeRadio(e, "survey_license_type")}
                              name="survey_license_type"
                              style={{ width: "20px", margin: "6px", height: "15px" }}
                              disabled={!survey_plan.isEnabled}
                            />
                            <Icon icon={survey_plan.icon} style={{ width: "20px", height: "20px", display: "inline-block" }} tooltip={{id: `survey-license-type-${survey_plan.name}`, message: survey_plan.name}}></Icon>
                          </li>
                        })}
                        </ul>
                    </td>
                  </tr>}
                  <tr>
                    <th>
                      {t("BENCHMARK")}
                    </th>
                    <td className="nopadding">
                      <Switch value={this.state.organization.benchmark} name="benchmark" onChange={this.onChange}/>
                    </td>
                  </tr>
                    <tr>
                      <th>
                        {t("AI_COACH")}
                      </th>
                      <td className="nopadding">
                        <Switch value={this.state.organization.ai_coach} name="ai_coach" onChange={this.onChange} />
                      </td>
                    </tr>
                     <tr>
                        <th>{t("SPEECH_ANALYTICS")}</th>
                          <td className="nopadding">
                            <Switch
                              value={this.state.organization.speech_analytics}
                              name="speech_analytics"
                              onChange={this.onChange}
                            />
                          </td>
                    </tr>
                   
                    {this.state.organization.speech_analytics && (
                      <>
                        <tr>
                          <th>{t("SPEECH_ANALYTICS_LANGUAGE")}</th>
                          <td className="nopadding">
                            <Select
                                  selected={
                                    this.state.organization
                                      .speech_analytics_language ?? ""
                                  }
                                  options={Object.keys(DeepgramLanguagesLabels).map(
                                    (value) => ({
                                      key: value,
                                      title: DeepgramLanguagesLabels[value]
                                    })
                                  )}
                                  name="speech_analytics_language"
                                  onChange={this.onChange}
                                />
                          </td>
                        </tr>
                        <tr>
                          <th>{t("SPEECH_ANALYTICS_MODAL")}</th>
                          <td className="nopadding">
                                <Select
                                  selected={
                                    this.state.organization
                                      .speech_analytics_modal ?? ""
                                  }
                                  options={
                                    this.state.organization
                                      .speech_analytics_language
                                      ? DeepgramModalByLanguage[
                                          this.state.organization
                                            .speech_analytics_language
                                        ].map((value) => ({
                                          key: value,
                                          title: value
                                        }))
                                      : []
                                  }
                                  name="speech_analytics_modal"
                                  onChange={this.onChange}
                                />
                              </td>
                        </tr>

                        <tr>
                          <th>{t("SPEECH_ANALYTICS_SENTIMENT")}</th>
                          <td className="nopadding">
                            <Switch
                              value={this.state.organization.speech_analytics_sentiment || false}
                              name="speech_analytics_sentiment"
                              onChange={this.onChange}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  <tr>
                    <th>
                      {t("NAVISION_IDS")}
                    </th>
                    <td className="nopadding navision_id_form">
                      <table>
                        <tbody>
                        {
                          this.state.organization.nav_ids && this.state.organization.nav_ids.split(",").map((nid, i) => {
                            return <tr key={i}>
                              <td>
                                <Number onChange={this.onNavIdChange} value={parseInt(nid)} index={i} name="nav_ids"
                                        min={0}/>
                              </td>
                              <td>
                                <Icon icon="delete" onClick={() => {
                                  this.removeNavId(i);
                                }}/>
                              </td>
                            </tr>;
                          })
                        }
                        </tbody>
                      </table>
                      <a onClick={this.addNavId} className="button">{t("ADD_NAVISION_ID")}</a>
                    </td>
                  </tr>
                </>
              }
              { [Roles.god,Roles.admin,Roles.manager].includes(this.props.auth.user.role_id)   &&
                <tr>
                  <th style={{width: "30%"}}>
                    {t("WIDGETS_HOST")}
                  </th>
                  <td className="nopadding">
                    <Select 
                      selected={this.state.organization.widgets_host ?? ""}
                      options={organizationNodeToOptions(this.props.organizationsNode)}
                      name="widgets_host"
                      onChange={this.onChange}
                    />
                  </td>
                </tr>
              }
              {this.props.auth.user.role_id === Roles.admin && <React.Fragment>
                <tr>
                  <th>
                    {t("ENABLE_2FA")}
                  </th>
                  <td className="nopadding">
                    <Typeahead
                      ukey="two_factor_auth"
                      multiple={true}
                      selected={
                        roles.filter(role => (this.state.organization.two_factor_auth || []).includes(role.id))
                          .map(role => ({
                            key: role.id,
                            title: role.title
                          }))
                      }
                      options={roles
                        .filter(role =>
                          !(this.state.organization.two_factor_auth || []).includes(role.id)
                        )
                        .map(role => ({title: role.title, key: role.id}))
                      }
                      onChange={selectedRoles =>
                        this.onChange("two_factor_auth", selectedRoles.map(role => role.key))
                      }
                    />
                  </td>
                </tr>
                <tr>
                <th>
                    {t("BENCHMARK_VISIBILITY")}
                  </th>
                  <td className="nopadding">
                    <Typeahead
                      ukey="benchmark_visibility"
                      multiple={true}
                      selected={
                        roles.filter(role => (this.state.organization.benchmark_visibility || []).includes(role.id))
                          .map(role => ({
                            key: role.id,
                            title: role.title
                          }))
                      }
                      options={roles
                        .filter(role =>
                          !(this.state.organization.benchmark_visibility || []).includes(role.id)
                        )
                        .map(role => ({title: role.title, key: role.id}))
                      }
                      onChange={selectedRoles =>
                        this.onChange("benchmark_visibility", selectedRoles.map(role => role.key))
                      }
                    />
                  </td>
                </tr>
              </React.Fragment>
              }

              </tbody>
            </table>
          </Form>
        </Popup>
      </>
    }</Translation>;
  }

  private onChangeRadio(e, key: string) {
    let changes = {}
    switch(key) {
      case "survey_license_type":
        changes = {survey_license_type: e.target.value}
        break;
      case "license_type":
        changes = {license_type: e.target.value}
        break;
    }

    this.setState({
      organization: {...this.state.organization, ...changes}
    })
  }

  private onSave: HTMLevent = (e) => {
    if (!this.state.organization.name)
      return alertError(`Name is required!`);
    if (isNaN(this.state.organization.parent_id))
      return alertError(`Parent is required!`);

    if (this.props.auth.user.role_id === Roles.god) {
      this.state.organization.benchmark_visibility = this.state.organization.benchmark_visibility || []
      this.props.onSave(this.state.organization);   
    } else if (this.props.auth.user.role_id <= Roles.admin) {
      // if not god, then you can only change two_factor_auth 
      this.props.onSave({
        id: this.state.organization.id,
        two_factor_auth: this.state.organization.two_factor_auth,
        benchmark_visibility: this.state.organization.benchmark_visibility || [],
        widgets_host: this.state.organization.widgets_host,
      });
    }
  }

  private onChange: HTMLElementEventHandler = (key: string, value: any) => {
    let organization: IState["organization"] = this.state.organization;
    organization[key] = value;
    switch(key){
      case "survey_license":
        if(!organization["survey_license_type"])
          organization["survey_license_type"] = LicenseType.STANDARD;
        break;
      case "speech_analytics": {
        organization.speech_analytics_language = DeepgramTranscriptionLanguage.SWEDISH;
        organization.speech_analytics_modal = DeepgramTranscriptionModal.NOVA_2;
        organization.speech_analytics_sentiment = false;
        break;
      }
            
    }
    this.setState({organization});
  }

  private onNavIdChange: HTMLElementEventHandler = (key: string, value: number, index: number) => {
    let organization = this.state.organization;
    let nav_ids = organization.nav_ids.split(",").map(n => parseInt(n));
    nav_ids[index] = value;
    organization.nav_ids = nav_ids.join(",");
    this.setState({organization});
  }

  private addNavId: any = (): void => {
    let organization = this.state.organization;
    organization.nav_ids = organization.nav_ids ? [...organization.nav_ids.split(","), "0"].join(",") : "0";
    this.setState({organization});
  }

  private removeNavId = (i) => {
    let organization = this.state.organization;
    let nav_ids = organization.nav_ids.split(",");
    nav_ids.splice(i, 1);
    organization.nav_ids = nav_ids.join(",");
    this.setState({organization});
  }

  /*
  private organizationOptions(): Array<IOption> {
    let flatten: Array<IOption> = [{key: "", title: i18n.t("SELECT_ORGANIZATION")}];
    function ls(organizations: Array<IOrganization>, parent: Array<string> = []): any {
      organizations.forEach(org => {
        flatten.push({
          key: org.id,
          title: `${[...parent, org.name].join(" - ")}`
        });
        if ((org.organizations || []).length)
          ls(org.organizations, [...parent, org.name]);
      });
    }
    ls(this.props.organizations);
    return flatten;
  }
  */

}

function mapStateToProps(
  state: { organizations: Array<IOrganization>, auth: IAuthObject }
): { organizations: Array<IOrganization>, auth: IAuthObject } {
  return {
    organizations: state.organizations,
    auth: state.auth
  };
}

export default connect(mapStateToProps, {})(Organization);
