import React, {Component} from "react";
import {connect} from "react-redux";
import {IStatusObject} from "../Interfaces/Redux";
import Overlay from "./Overlay";

import "../Style/Sass/Loading.scss";

class Loading extends Component<{
  loading: Array<IStatusObject>
}, any> {

  public render(): React.ReactNode {
    if (this.props.loading.length) {
      return <Overlay onClose={null}>
        <div className="loading">

          <div className="container">
            <div className="box1"></div>
            <div className="box2"></div>
            <div className="box3"></div>
          </div>

        </div>
      </Overlay>;
    }
    return <></>;
  }

}

function mapStateToProps(state: { loading: Array<IStatusObject> }): { loading: Array<IStatusObject> } {
  return {
    loading: state.loading
  };
}

export default connect(mapStateToProps, {})(Loading);
