import React, {Component} from "react";
import Icon from "./Icon";
import {HTMLevent} from "../Interfaces/Common";
import Overlay from "./Overlay";

import "../Style/Sass/Popup.scss";

interface IProps {
  onCloseForm: HTMLevent;
}

export default class Popup extends Component<IProps, { left: number }> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      left: -800
    };
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({left: 0});
    },         50);
  }

  public render(): React.ReactNode {
    return <Overlay onClose={this.close}>
      <div className="popup" style={{transform: `translateX(${this.state.left}px)`}}>
        <div className="close"><Icon onClick={this.close} icon="close"/></div>
        {this.props.children}
      </div>
    </Overlay>;
  }

  private close = () => {
    this.setState({left: -800});
    setTimeout(() => {
      this.props.onCloseForm();
    },         500);
  }
}
