import {Action, IReduxAction} from "../Interfaces/Redux";
import {INode} from "../Interfaces/Common";

export default function NodesReducer(state: Array<INode> = [], action: IReduxAction<Array<INode>, Action.NODES>): Array<INode> {
  switch (action.type) {
    case Action.NODES:
      return action.payload;
    default:
      return state;
  }
}
