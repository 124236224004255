import {HOST, NumbersReq} from "../Store";
import {IDidCartItem} from "../Interfaces/Common";

/**
 * Fetch request to purchase number.
 * @param orgNr
 * @param item
 * @returns fetch response
 */
export default async function purchaseItem(
  orgNr: number,
  item: IDidCartItem
): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/${item.voiceUriId}/purchase`,
    {
      didId: item.didObject.didId,
      quantity: item.quantity
    },
    "post"
  );
  return resp;
}
