import React from "react";

const Unauthorized = (props) => {
  if (props.history.location.pathname !== "/unauthorized") {
    props.history.replace("/unauthorized");
    return null;
  } else {
    return (
      <div className="col-md-12 text-center">
        <h1>Unauthorized</h1>
      </div>
    );
  }
};

export default Unauthorized;