import React, {Component} from "react";
import Icon from "./Icon";

import "../Style/Sass/DotMenu.scss";

export default class DotMenu extends Component {

  public state: any = {
    show: false
  };

  public render(): React.ReactNode {
    return <div className={`dotmenu ${this.state.show ? "show" : "hide"}`}>
      <div className="button">
        <Icon onClick={this.show} icon="dots-menu"/>
      </div>
      <div className="menu">
        {this.props.children}
      </div>
    </div>;
  }

  private show = (): void => {
    if (this.state.show)
      return;
    this.setState({show: true});
    const listener: any = () => {
      this.setState({show: false});
      window.removeEventListener("click", listener);
    };

    setTimeout(() => {
      window.addEventListener("click", listener);
    },         50);
  }

}
