import {HOST, NumbersReq} from "../Store";
import {IVoiceUri} from "../Interfaces/Common";

const voiceUriCache: {
  [orgNr: number]: IVoiceUri[];
} = {};

/**
 * Fetches voice URIs, with numbers, for orgnization. Caches requests but can force reload.
 * @param orgNr
 * @param options
 * @returns voice URIs, with corresponding numbers
 */
export default async function fetchVoiceUris(
  orgNr: number,
  options: {reload: boolean} = {reload: false}
): Promise<IVoiceUri[]> {
  const {reload} = options;
  if (voiceUriCache[orgNr] && !reload) {
    return voiceUriCache[orgNr];
  }

  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/numbers`,
    null,
    "get",
    {
      displayErrors: false
    }
  );
  const voiceUris: IVoiceUri[] = resp.data.voiceuris;
  voiceUriCache[orgNr] = voiceUris;
  return voiceUris;
}

/**
 * Clear cache for orgNr.
 * @param orgNr
 */
export function clearCache(orgNr: number): void {
  voiceUriCache[orgNr] = null;
}

/**
 * Updates customer number description.
 * @param orgNr
 * @param phoneNumber
 * @param description
 * @returns success or error message
 */
export async function customerNumberDescription(
  orgNr: number,
  phoneNumber: string,
  description: string
): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/numbers`,
    {
      phoneNumber,
      description
    },
    "post"
  );
  if(resp.status === 200){
    // Update cache
    Object.keys(voiceUriCache).forEach(org=>{
      voiceUriCache[org].forEach(({numbers})=>{
        numbers
          ?.filter((n) => n.phoneNumber === phoneNumber)
          ?.forEach((num) => {
            num.description = description;
          });
      })
    })
  }
  return resp;
}