import React, {Component} from "react";
import Icon from "./Icon";
import "../Style/Sass/Header.scss";
import {IAuthObject, IConfigObject} from "../Interfaces/Redux";
import {connect} from "react-redux";
import Translation from "../Helpers/Translation";
import {setConfig} from "../Store";
import moment from "moment";
import DotMenu from "./DotMenu";
import Tooltip from "../Helpers/Tooltip";
import {AUTH_LOCAL_STORAGE_KEY} from "../Helpers/Constants";
import config from "../config";
import { removeItem } from "../cookies";

interface IProps {
  config: IConfigObject;
  auth: IAuthObject;
  history?: any;
}

interface IState {
  time: string;
}

class Header extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      time: moment().format("HH:mm")
    };
  }

  public componentDidMount(): void {
    this.setTime();
  }

  public render(): React.ReactNode {
    let pathname: Array<string> = window.location.pathname.split("/");
    const path: string = `/${pathname.length && !isNaN(parseInt(pathname[1], 10)) ? pathname[1] : ""}`;
    return <Translation>
      {
        t => <header>
          <Tooltip id="DARK_THEME" message={t("DARK_THEME")}/>
          <Tooltip id="LIGHT_THEME" message={t("LIGHT_THEME")}/>
          <div className="mobile-menu">
            <DotMenu>
              <ul>
                <li>
                  <Icon icon="language" tooltip={{id: "SELECT_LANGUAGE", message: t("SELECT_LANGUAGE")}}/>
                  <span className="box sv clickable" onClick={e => this.setConfig({lang: "sv"})}>Sv</span>
                  <span className="box en clickable" onClick={e => this.setConfig({lang: "en"})}>En</span>
                </li>
                <li>
                  <span className="theme">
                    <Icon icon="palette" tooltip={{id: "SELECT_THEME", message: t("SELECT_THEME")}}/>
                    <span className="box dark clickable" onClick={e => this.setConfig({theme: "dark"})}>D</span>
                    <span className="box light clickable" onClick={e => this.setConfig({theme: "light"})}>L</span>
                  </span>
                </li>
                <li onClick={this.signOut}><Icon icon="logout"/> {t("SIGN_OUT")}</li>
              </ul>
            </DotMenu>
          </div>
          <div className="left">
            <span className="theme">
              <Icon icon="palette" tooltip={{id: "SELECT_THEME", message: t("SELECT_THEME")}}/>
              <span className="box dark clickable" onClick={e => this.setConfig({theme: "dark"})} data-tip
                    data-for="DARK_THEME">D</span>
              <span className="box light clickable" onClick={e => this.setConfig({theme: "light"})} data-tip
                    data-for="LIGHT_THEME">L</span>
            </span>
            <span className="delimiter"></span>
            <span className="language">
              <Icon icon="language" tooltip={{id: "SELECT_LANGUAGE", message: t("SELECT_LANGUAGE")}}/>
              <span className="box sv clickable" onClick={e => this.setConfig({lang: "sv"})}>Sv</span>
              <span className="box en clickable" onClick={e => this.setConfig({lang: "en"})}>En</span>
            </span>
          </div>
          <div className="center">
            <div className="date">{moment().format("YYYY-MM-DD")}</div>
            <div className="clickable logo" onClick={e => window.location.href = path}>
              <img
                alt="Connectel"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIUDxUdplCd1AAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAADMUlEQVRYw7WXS2jUQBjHf9k+XK3gKyooSIuxoIKlehi1gaoX14snISp4yWl7Ew8F8YFtwYMIKnjIKScVlyI9CLIHBZH4iIjiQaU1iIKHKoutz1asjQcnEEM2Sbfxg7DDNzP7/+ab7/EfhYxiu84CYCOwFdgBdAItptB3Mg9pzgg+APQDLUBTaGqSeUpzCnAZGARW1lni52qA7TqYQsd2nSJwG+jhP4sSc+pW4DWwLsP+WVPoTfMxoBABL6aAfwSGgUNAO9Bqu06uMXAnAXwIGAB+m0L/59py8YDtOn1AXEpNAt2m0E9HwQH2v/2G75X/RqT8bdQDgzHzE8AqU+gzAVitUkU1StQq1TZgmWqU3kvwNcA08CkwRtGsbB6wXWcIUGPm95hCnwncrBqlAPwE4AGjobUjwBvfK98EMoEDKLLCTQLFyNyAKfQzYUWtUm2ScdIrVVOqUVokT/wIEFI/DWxTNOtlmicKwCZZ4cLyARgKTl6rVAP98xB4khSBF75XbkvzRAHYFimvAHfDASfdfhDYPMcYG/G9spJmQFzk3wjAQ6fvbyDLdgFtaQZ0xugfBgN5+gLQ3YABLcDetDRcEVWaQn8fUXUl/YnvlRVFs7YnzNcNxOY8OpqiWb7vlZviegswq2jWbNIVfIppSGsjqmcZ7BgGxiLfW2B3WgyMxeh7QrmPapSyGLEG6Ih8q4H7SSW6ADyI0R8IVz8p5xq4HQf4meaBJ8DvaPpIYhL2wnXg3hzAfwCGoll+WiUcBaYi+pW26xwPjJCpiGqUeoHHGcC/Al2KZn1M65AFU+hTwMWYubO263SF60EoPo5I1y6MlF+AS4CmaJaXpSMWJA88VYfh3grqRIh4zKhG6YpqlIqh5gPQp2iWomjWUcmcsrdjKSfrRLZnu87aSF8Ixo9rlWpQaB7OhQf8Q0pDbPgWsK/O2rPAoCn0n+E9ubFi6eIW4BWwPmGPBVwGxoFfptC/5E3LFwNPgQ0pe6eBcVPoHXnScoDvwBb5MEkjHUtyf5hEjDkMXE3YP2EKfXluHohpy9eApcAx4N3/eJoVEsCD4WdT6BdMobdL/nhecsPxPF7HfwADlSrRee0rogAAAABJRU5ErkJggg=="/>
            </div>
            <div className="time">{this.state.time}</div>
          </div>
          <div className="right">
            <Icon icon="logout" tooltip={{id: "SIGN_OUT", message: t("SIGN_OUT"), type: "warning", place: "left"}}
                  onClick={this.signOut}/>
            <span className="user">{this.props.auth.user.username}</span>
          </div>
        </header>
      }
    </Translation>;
  }

  private setConfig: any = (payload: IConfigObject) => {
    setConfig(payload);
  }

  private setTime(): void {
    setTimeout(() => {
        this.setState({time: moment().format("HH:mm")});
        this.setTime();
      },
               (61 - Math.round((Date.now() / 1000)) % 60) * 1000
    );
  }

  private signOut = () => {
    removeItem(AUTH_LOCAL_STORAGE_KEY);
    setTimeout(() => {
      window.location.href = `${config.loginUrl}/?signout=1`;
    },         500);
  }

}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    config: state.config
  };
}

export default connect(mapStateToProps, {})(Header);
