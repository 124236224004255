import React, {Component} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface HighchartProps {
    title: string;
    data: any
}

export default class Highchart extends Component<HighchartProps, {}> {
    constructor(props: HighchartProps) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={this.getChartOptions()}
            />
        );
    }

    public getChartOptions() {
        return {
            title: {
                text: `<span style="font-size: 26px">${this.props.title}</span>`
            },
            chart: {
                type: "spline",
                zoomType: "x"
            },
            xAxis: {
                type: "datetime",
                startOfWeek: 0,
                tickInterval: 24 * 3600 * 1000,
                tickWidth: 0,
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat("%e %b %Y", this.value);
                    }
                }
            },
            series: [
                {
                    showInLegend: false,
                    name: "Value",
                    data: [...this.props.data]
                }
            ]
        };
    }
}
