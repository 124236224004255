import React from "react";
import {Redirect} from "react-router-dom";

/**
 * Syncs the orgNr route parameter with the orgNr prop using react-router-dom's redirect.
 * Will change props.orgNrRoute (through redirect) to be in sync with props.orgNr.
 */
export default function SyncOrgRoute(props: {orgNr: number, orgNrRoute: number, routeTail: string}): JSX.Element {
    if (props.orgNr !== props.orgNrRoute) {
      if (props.orgNr) {
        return (
          <Redirect
            to={`/organization/${props.orgNr}${props.routeTail}`}
          />
        );
      } else if (props.orgNrRoute) {
        return <Redirect to={props.routeTail} />;
      }
    }
    return null;
}