import { ICountry } from "../Interfaces/Common";
import {HOST, NumbersReq} from "../Store";

let cache: ICountry[] = null;

/**
 * Fetches countries one can purchase numbers from.
 * @param orgNr
 * @returns a list of countries that can be selected to fetch numbers from
 */
export default async function fetchCountries(
  orgNr: number
): Promise<ICountry[]> {
  if (cache) return cache;

  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/inventory/countries`,
    null,
    "get"
  );
  const countries: ICountry[] = resp.data[0].countries;
  cache = countries;
  return countries;
}
