import React, {Component} from "react";
import {HTMLevent} from "../Interfaces/Common";

import "../Style/Sass/Form.scss";

export default class Form extends Component<{
  onSubmit: HTMLevent;
  onCancel: HTMLevent;
  propSave?: boolean;
  stepperFooter?: boolean;
}> {
  public render(): React.ReactNode {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="inner-content">{this.props.children}</div>

        {this.props.stepperFooter ? (
          <div className="footer">
            <div className="btn ok" onClick={this.props.onSubmit}>
              Check Portability
            </div>
            <div className="btn cancel" onClick={this.props.onCancel}>
              Cancel
            </div>
          </div>
        ) : (
          <div className="footer">
            {this.props.propSave && (
              <div className="btn ok" onClick={this.props.onSubmit}>
                OK
              </div>
            )}
            {!this.props.propSave && (
              <div className="btn save" onClick={this.props.onSubmit}>
                Save
              </div>
            )}
            <div className="btn cancel" onClick={this.props.onCancel}>
              Cancel
            </div>
          </div>
        )}
      </form>
    );
  }

  private onSubmit: HTMLevent = (e) => {
    e.preventDefault();
    this.props.onSubmit(e);
  };
}
