import React, {useState} from "react";
import Popup from "../../Popup";
import "../../../Style/Sass/PortingRequestForm.scss";
import {INewPortingRequest} from "../../../Interfaces/Common";
import {PortingRequestCheckForm} from "./PortingRequestCheckForm";
import PortingRequestUserDetailForm from "./PortingRequestUserDetailForm";
import {portingRequestSave} from "../../../Fetch/PortingRequests";

type TProps = {
  closeAddPortingForm: () => void;
  portingRequest: INewPortingRequest;
  reloadList: Function;
};

export const PortingRequest: React.FC<TProps> = React.memo(
  ({closeAddPortingForm, portingRequest, reloadList}) => {
    const [showUserDetailForm, setUserDetailForm] = useState(false);
    const [portingRequestData, setPortingRequestData] =
      useState(portingRequest);
    const closeForm = () => {
      setUserDetailForm(false);
      closeAddPortingForm();
    };

    const setPortingRequestDataFromUser = (data) => {
      setPortingRequestData({...portingRequest, ...data});
    };
    const savePortingRequest = async (data) => {
      await portingRequestSave(data);
      reloadList(true);
      closeAddPortingForm();
    };
    return (
      <Popup onCloseForm={closeForm}>
        {!showUserDetailForm ? (
          <PortingRequestCheckForm
            closeForm={closeAddPortingForm}
            portingRequest={portingRequest}
            setUserDetailForm={setUserDetailForm}
            showUserDetailForm={showUserDetailForm}
            setPortingRequestCheckData={setPortingRequestDataFromUser}
          />
        ) : (
          <PortingRequestUserDetailForm
            closeForm={closeAddPortingForm}
            portingRequest={portingRequestData}
            setUserDetailForm={setUserDetailForm}
            showUserDetailForm={showUserDetailForm}
            setCustomerDetailForm={savePortingRequest}
          />
        )}
      </Popup>
    );
  }
);
