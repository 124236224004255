import React, {Component} from "react";
import {connect} from "react-redux";
import {IConfigObject} from "../Interfaces/Redux";

import "../Style/Sass/Sidebar.scss";

interface IProps {
  config: IConfigObject;
}

class Body extends Component<IProps> {

  public render(): React.ReactNode {
    let classes: Array<string> = ["body"];
    classes.push(`theme-${this.props.config.theme}`);
    return <div className={classes.join(" ")}>
      {this.props.children}
      <div className="sidebar">
        <ul>
          <li>
            Sign out
          </li>
        </ul>
      </div>
    </div>;
  }
}

function mapStateToProps(state: IProps): IProps {
  return {
    config: state.config
  };
}

export default connect(mapStateToProps, {})(Body);
