import React, {Component} from "react";
import {connect} from "react-redux";
import {IAlertObject} from "../Interfaces/Redux";
import Overlay from "./Overlay";
import ReactHtmlParser from 'react-html-parser';
import "../Style/Sass/Alert.scss";

class Alert extends Component<{
  alerts: Array<IAlertObject>
}, any> {

  public render(): React.ReactNode {
    if (this.props.alerts.length)
      return <Overlay onClose={null}>
        <div className="alerts">
          {
            this.props.alerts.map((a, i) => {
              let message: any = a.message?.data?.data?.map(e => e.message || e.error).join("\n") || a.message;
              if (typeof message === "string") {
                message = message.replace("Error: ", "").replaceAll("\n", "<br>");
              }
              return <div key={i} className={`alert ${a.type}`}>
                <div className="inner-content">{ReactHtmlParser(message, {
                  transform: domNode => {
                    if (domNode.name === 'input' && domNode.attribs.class?.includes("external-html")) {
                      return (
                        <input
                          {...domNode.attribs}
                          onClick={a?.inputHandlers[domNode.attribs?.id] || undefined}
                        />
                      );
                    }
                  }
                }
            )}</div>
                <div className={`footer ${((+!!a.onCancel) + (+!!a.onConfirm)) === 1 ? " wide" : ""}`}>
                  {a.onCancel && <div className="btn cancel" onClick={a.onCancel}>Cancel</div>}
                  {a.onConfirm && <div className="btn save" onClick={a.onConfirm}>OK</div>}
                </div>
              </div>;
            })
          }
        </div>
      </Overlay>;

    return <></>;

  }

}

function mapStateToProps(state: { alerts: Array<IAlertObject> }): { alerts: Array<IAlertObject> } {
  return {
    alerts: state.alerts
  };
}

export default connect(mapStateToProps, {})(Alert);
