import React from "react";
import {connect} from "react-redux";
import {fetchOrganizationUsers, fetchTokenAs} from "../Store";
import {INode, IOrganization, IUser} from "../Interfaces/Common";
import Translation from "../i18n";
import {flattenOrganizations, getLoginCookieDomain} from "../Helpers/Common";
import Popup from "../Components/Popup";
import Icon from "../Components/Icon";
import {options as RoleOptions, Roles} from "../Helpers/Roles";
import {IAuthObject} from "../Interfaces/Redux";
import { setCookie } from "typescript-cookie";
import config from "../config";

interface IState {
  flatten_organizations: Array<IOrganization>;
  login_as: {user: IUser};
  users: Array<IUser>;
  organization: IOrganization;
  organization_id: number;
}

interface IProps {
  auth: IAuthObject;
  organizations: Array<IOrganization>;
  match: any;
}

class OrganizationUsers extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    const organization_id: number = parseInt(this.props.match.params.organization_id, 10);
    const flatten_organizations: Array<IOrganization> = flattenOrganizations(this.props.organizations);
    this.state = {
      flatten_organizations,
      login_as: null,
      organization: flatten_organizations.find(org => org.id === organization_id),
      organization_id,
      users: []
    };
    this.fetchOrganizationUsers();
  }

  public componentWillReceiveProps(current: IProps, nextProps: IProps): void {
    if (!this.state.organization)
      this.setState({organization: flattenOrganizations(current.organizations).find(org => org.id === this.state.organization_id)});
  }

  public render(): React.ReactNode {
    return <Translation>{
      t => <>
        {this.state.login_as !== null && <Popup onCloseForm={() => this.setState({login_as: null})}>
          <div>
            <table className="list">
              <thead>
              <tr>
                <th>Node</th>
                <th>{t("LAUNCH")}</th>
              </tr>
              </thead>
              <tbody>
              {flattenOrganizations([this.state.organization]).map(org => {
                return org.nodes.map(node => {
                  return <tr key={node.id}>
                    <td>{node.name}</td>
                    <td><Icon icon="launch" onClick={() => this.loginAs(this.state.organization, this.state.login_as.user, node)}></Icon></td>
                  </tr>;
                });
              }).flat()
              }
              <tr></tr>
              </tbody>
            </table>
          </div>
        </Popup>}
        <h1>{t("ORGANIZATION_USERS")} ({this.state.organization?.name || "Organization not found!"})</h1>
      <table className="list organization_user">
        <thead>
        <tr>
          <th>{t("ID")}</th>
          <th>{t("NAME")}</th>
          <th>{t("USERNAME")}</th>
          <th>{t("ROLE")}</th>
          {this.props.auth.user.role_id <= Roles.admin && <th></th>}
        </tr>
        </thead>
        <tbody>
        {
          this.state.users.map(user => {
            return <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.username}</td>
              <td>{RoleOptions.find(ro => ro.key === user.role_id).title}</td>
              {this.props.auth.user.role_id <= Roles.admin && <td><Icon icon="list" onClick={e => this.setState({login_as: {user}})}></Icon></td>}
            </tr>;
          })
        }
        </tbody>
      </table>
      </>
    }</Translation>;
  }

  private fetchOrganizationUsers(): void {
    fetchOrganizationUsers(this.state.organization_id, this.props.match.params.license).then(({data: users}) => {
      this.setState({users});
    });
  }

  private loginAs(organization: IOrganization, user: IUser, node: INode): void {
    fetchTokenAs(organization.id, user.id, this.props.match.params.license).then(res => {
      const cookieKey = `${this.props.match.params.license}-token`
      const cookieValue = JSON.stringify({token: res.data.token});
      setCookie(cookieKey, cookieValue, {
        path: `/${this.props.match.params.license}`, 
        expires: new Date(Date.now() + config.loginCookieExpiry), 
        domain: getLoginCookieDomain()
      });
      const href: string = `${node.hostname}${this.props.match.params.license}`;

      const win: any = window.open(href, "_blank");
      win.focus();

    });
  }

}

function mapStateToProps(state: Pick<IProps, "organizations" | "auth">): Pick<IProps, "organizations" | "auth"> {
  return {
    auth: state.auth,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(OrganizationUsers);
