import React, {Component} from "react";
import {HTMLevent} from "../Interfaces/Common";

import "../Style/Sass/Overlay.scss";

export default class Popup extends Component<{
  onClose: HTMLevent
}> {
  public render(): React.ReactNode {
    return <div className="overlay">
      <div className="close" onClick={this.props.onClose}></div>
      {this.props.children}
    </div>;
  }
}
