import React, {useState, useEffect} from "react";
import moment from "moment";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {INewSyncRequest, IOrganization} from "../Interfaces/Common";
import {i18n} from "../i18n";
import {IAuthObject} from "../Interfaces/Redux";
import {alertInfo, fetchTrends} from "../Store";
import {fetchSyncRequest} from "../Fetch/SyncRequests";
import PaginatedFooter from "../Components/PaginatedFooter";
import {SYNC_REQUEST_PAGINATION_SIZE} from "../Helpers/Constants";
import Modal from "../Components/Modal";
import Highchart from "../Components/HighChart";
import "../Style/Sass/Modal.scss";
import { Select } from "../Components/Form/Element";

interface IProps {
  orgNr: number;
}

export const SyncRequests: React.FC<IProps> = React.memo(({orgNr}) => {
  const {t} = useTranslation();
  const [syncRequestsList, setSyncRequestsList] = useState([]);
  const [days, setDays] = useState(7);
  const [syncRequestsTrends, setSyncRequestsTrends] = useState([]);
  const [isModalOpen, setModalState] = React.useState(false);
  const toggleModal = () => setModalState(!isModalOpen);
  const [page, setPage] = useState(1);
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);

  const loadList = async () => {
    const syncRequests: any = await fetchSyncRequest(
      orgNr,
      page,
      SYNC_REQUEST_PAGINATION_SIZE
    );
    const trends = await fetchTrends(days);
    setSyncRequestsList(syncRequests.data.syncRequests);
    setIsNextPageAvailable(syncRequests.data._metadata.nextPage);
    const response = groupDataForHighCharts(trends.data);
    setSyncRequestsTrends(response);
  };

  const onPreviousPage = () => {
    if (page <= 1) return;
    setPage(page - 1);
  };

  const onNextPage = () => {
    if (!isNextPageAvailable) return;
    setPage(page + 1);
  };

  const getHighchart = (syncRequestsTrends: number[][]) => {
    return (
      <div style = {{"backgroundColor" : "white"}}>
        <Select
            className={"input-select"}
            name={"days"}
            selected={days}
            onChange={(_, days) => onSelectChange(days)}
            options={getTimeDurationOptions()}
        >
        </Select>
        <Highchart
          title={`Last ${days} days trend`}
          data={syncRequestsTrends}
        />
      </div>
    )
  }

  const onSelectChange = async (val: number)  => {
    const trends = await fetchTrends(val);
    const response = groupDataForHighCharts(trends.data);
    setDays(val);
    setSyncRequestsTrends(response);
  }

  const groupDataForHighCharts = (trends: {date: string, count: string}[]) => {
    let response = [];

    trends.forEach(trendInfo => {
      let startDay = moment(trendInfo.date, "DD MMM YYYY").format("YYYY-MM-DD");
       let timestamp = new Date(startDay).getTime();
       response.push([timestamp, trendInfo.count]);
    });

    return response;
  }

  useEffect(() => {
    try {
      loadList();
    } catch (err) {
      setSyncRequestsList([]);
      alertInfo(i18n.t("NO_SYNC_REQUEST_FOUND"));
    }
  }, [page]);

  return (
    <div>
      <div className="container-header flex justify-between items-center px-2">
        <h1>{t("SYNC_REQUESTS")}</h1>
        <button
          className={"default-button"}
          onClick={toggleModal}
        >
          Trends
        </button>
      </div>

      <Modal
        modalBody={getHighchart(syncRequestsTrends)}
        isOpen={isModalOpen}
        onClose={toggleModal}
      ></Modal>

      <article>
        <table className="list sync-requests">
          <thead>
            <tr>
              <th>{t("HOST_NAME")}</th>
              <th>{t("SYNC_STATUS")}</th>
              <th>{t("USER")}</th>
              <th>{t("DESCRIPTION")}</th>
              <th>{t("STARTED_AT")}</th>
              <th>{t("COMPLETED_AT")}</th>
            </tr>
          </thead>
          <tbody>
            {syncRequestsList?.length > 0
              ? renderSyncRequestLists(syncRequestsList)
              : renderEmptyRowMessage()}
          </tbody>
        </table>
        {!(page === 1 && !isNextPageAvailable) && (
          <PaginatedFooter
            onPreviousClick={onPreviousPage}
            onNextClick={onNextPage}
            pageNumber={page}
            isNextPageAvailable={isNextPageAvailable}
          />
        )}
      </article>
    </div>
  );
});

interface IStoreProps {
  auth: IAuthObject;
  organizations: Array<IOrganization>;
  orgNr: number;
}

function mapStateToProps(state: IStoreProps): IStoreProps {
  return {
    auth: state.auth,
    orgNr: state.orgNr,
    organizations: state.organizations
  };
}
const renderSyncRequestLists = (
  syncRequestsList: INewSyncRequest[]
): React.ReactNode => {
  return syncRequestsList.map((syncRequest) => [
    <tr key={syncRequest.id}>
      <td>{syncRequest.hostname}</td>
      <td>{syncRequest.active ? "Running" : "Completed"}</td>
      <td>{syncRequest.username}</td>
      <td>{syncRequest.feedback || "-"}</td>
      <td>{syncRequest.createdAt}</td>
      <td>{syncRequest.updatedAt}</td>
    </tr>
  ]);
};

function renderEmptyRowMessage(): React.ReactNode {
  return (
    <tr>
      <td colSpan={7} className="portingListNoRecordFound">
        {i18n.t("NO_SYNC_REQUEST_FOUND")}
      </td>
    </tr>
  );
}

function getTimeDurationOptions() {
  return [
    {key : 7, title : "Last 7 days"},
    {key : 30, title : "Last 30 days"}
  ];
}

export default connect(mapStateToProps, {})(SyncRequests);
