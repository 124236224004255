import React, {Component} from "react";
import {HTMLevent, IUser} from "../../Interfaces/Common";
import Translation from "../../i18n";
import Popup from "../Popup";
import Form from "../Form";
import Typeahead from "./Element/Typeahead";
import Text from "./Element/Text";
import {createNotification} from "../../Store";
import {options as RoleOptions} from "../../Helpers/Roles";

interface TypeaheadOptions {
  key: number;
  title: string;
}
interface IState {
  title: string;
  message: string;
  recievers: Array<TypeaheadOptions>;
}

interface IProps {
  onCloseForm: HTMLevent;
  users: Array<IUser>;
}

export default class NewNotification extends Component<IProps, IState> {
  formFieldWidth: number;
  constructor(props: IProps) {
    super(props);
    this.state = {
      title: "",
      message: "",
      recievers: []
    };
    this.formFieldWidth = 300;
  }

  public render(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <>
            <Popup onCloseForm={this.props.onCloseForm}>
              <Form onSubmit={this.onSave} onCancel={this.props.onCloseForm}>
                <h2>
                  {t("CREATE")} {t("NEW")} {t("NOTIFICATION")}
                </h2>
                <table style={{width: "100%"}}>
                  <tbody>
                    <tr>
                      <th>{t("TITLE")}</th>
                      <td className="nopadding" colSpan={1}>
                        <Text
                          name="title"
                          onChange={this.onChange}
                          value={this.state.title}
                          width={this.formFieldWidth}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("MESSAGE")}</th>
                      <td className="nopadding" colSpan={1}>
                        <textarea
                          name="message"
                          rows={4}
                          value={this.state.message}
                          onChange={(e) =>
                            this.onChange("message", e.target.value)
                          }
                          style={{resize: "vertical", width: this.formFieldWidth}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("RECEIVERS")}</th>
                      <td className="nopadding" colSpan={1}>
                        <Typeahead
                          ukey="receivers"
                          multiple={true}
                          onChange={(val: Array<TypeaheadOptions>) =>
                            this.setState({
                              recievers: val
                            })
                          }
                          options={this.props.users.map((u) => ({
                            key: u.id,
                            title: `${u.username} [${
                              RoleOptions.find((role) => role.key == u.role_id)
                                ?.title || ""
                            }]`
                          })).filter(item => !this.state.recievers.some(selectedUser => selectedUser.key === item.key))}
                          name="receivers"
                          selected={this.state.recievers}
                          width={this.formFieldWidth}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Popup>
          </>
        )}
      </Translation>
    );
  }

  private onSave = async (): Promise<void> => {
    const payload = {
      title: this.state.title,
      message: this.state.message,
      receivers: this.state.recievers.map((r) => r.key)
    };
    try {
      await createNotification(payload);
      this.props.onCloseForm();
    } catch (e) {
      console.log(e);
    }
  };

  private onChange = (key: string, val: any): void => {
    this.setState({[key]: val} as Pick<IState, keyof IState>);
  };
}
