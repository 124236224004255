import React, {Component} from "react";
import {connect} from "react-redux";
import {validateToken} from "../Store";
import {withRouter} from "react-router";
import {Route} from "react-router-dom";
import {AUTH_LOCAL_STORAGE_KEY} from "../Helpers/Constants";
import {IAuthObject} from "../Interfaces/Redux";
import config from "../config";
import { getItem, removeItem, setItem } from "../cookies";
import {getCookie, removeCookie } from "typescript-cookie";
import { getLoginCookieDomain } from "../Helpers/Common";
import { getUrlParams } from "../Helpers/Tools";

interface IProps {
  validateToken: Function;
  exact: boolean;
  path: string;
  component: React.ComponentClass;
  location: any;
  auth: IAuthObject;
}

class AuthorizedRoute extends Component<IProps, {
  render: boolean;
}> {
  constructor(props: IProps) {
    super(props);
    this.state = {render: true};
  }

  public componentWillReceiveProps(nextProps: IProps): void {
    if (nextProps.auth && nextProps.auth.isAuth) {
      let AuthObject: IAuthObject = nextProps.auth;
      if (!localStorage)
        return;
      setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(AuthObject));
      if (getUrlParams().hasOwnProperty("token")) {
        window.location.href = `${config.host}${this.props.location.pathname}`;
      }
      if (this.getSSOCookieData())  {
        const {name, attributes} = this.getSSOCookieInfo();
        removeCookie(name, attributes)
      }
    }
  }

  public async componentWillMount(): Promise<void> {

    const url: any = getUrlParams();
    let token: IAuthObject["token"] = url.token ?? this.getSSOCookieData()?.token

    if (localStorage && (!token || token.length < 30)) {

      let StorageAuth: string = getItem(AUTH_LOCAL_STORAGE_KEY);
      let AuthObject: IAuthObject = {token: "unauthorized"};
      if (StorageAuth) {
        AuthObject = JSON.parse(StorageAuth);
      }

      token = AuthObject.token;
    }

    try {
      if (!token || token.length < 30)
        throw new Error("Unauthorized");
      await validateToken(token);
    } catch (e) {
      removeItem(AUTH_LOCAL_STORAGE_KEY);
      return window.location.replace(`${config.loginUrl}/?license=portal`);
    }

    this.setState(() => {
      return {render: true};
    });


  }

  private getSSOCookieInfo(): {name: string, attributes: Record<string, any>} {
    const module = "portal";
    return {
      name: `${module}-token`,
      attributes: {
        path: `/`,
        domain: getLoginCookieDomain()
      }
    };
  }

  private getSSOCookieData(): {token: string, userId: any} {
    const cookieData = getCookie(
      this.getSSOCookieInfo().name
    );
    if(cookieData) {
      return JSON.parse(cookieData)
    }

    return null;
  }

  public render(): React.ReactNode {
    const {component: Component, ...rest} = this.props;
    if (this.state.render) {
      if (!this.props.auth.isAuth)
        return <></>;
      return (
        <Route {...rest} render={crops => {
          return <Component {...{
            component: this.props.component,
            location: this.props.location as any,
            path: this.props.path
          }} {...crops} />;
        }}/>
      );
    } else {
      return null;
    }
  }
}


function mapStateToProps(state: IProps): {auth: IProps["auth"]} {
  return {
    auth: state.auth
  };
}

export default withRouter(connect(mapStateToProps)(AuthorizedRoute) as any) as any;
