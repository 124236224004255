"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeepgramTranscriptionModal = exports.DeepgramTranscriptionLanguage = exports.DeepgramLanguagesLabels = exports.DeepgramModalByLanguage = void 0;
var DeepgramTranscriptionLanguage;
(function (DeepgramTranscriptionLanguage) {
    DeepgramTranscriptionLanguage["SWEDISH"] = "sv";
    DeepgramTranscriptionLanguage["ENGLISH"] = "en";
    DeepgramTranscriptionLanguage["ENGLISH_GREAT_BRITAIN"] = "en-GB";
    DeepgramTranscriptionLanguage["ENGLISH_UNITED_STATES"] = "en-US";
    DeepgramTranscriptionLanguage["DANISH"] = "da";
    DeepgramTranscriptionLanguage["NORWEGIAN"] = "no";
    DeepgramTranscriptionLanguage["DUTCH"] = "nl";
    DeepgramTranscriptionLanguage["GERMAN"] = "de";
})(DeepgramTranscriptionLanguage || (DeepgramTranscriptionLanguage = {}));
exports.DeepgramTranscriptionLanguage = DeepgramTranscriptionLanguage;
var DeepgramTranscriptionModal;
(function (DeepgramTranscriptionModal) {
    DeepgramTranscriptionModal["ENHANCED"] = "enhanced";
    DeepgramTranscriptionModal["WHISPER_CLOUD"] = "whisper-cloud";
    DeepgramTranscriptionModal["BASE"] = "base";
    DeepgramTranscriptionModal["NOVA"] = "nova";
    DeepgramTranscriptionModal["NOVA_2"] = "nova-2";
})(DeepgramTranscriptionModal || (DeepgramTranscriptionModal = {}));
exports.DeepgramTranscriptionModal = DeepgramTranscriptionModal;
const DeepgramLanguagesLabels = {
    [DeepgramTranscriptionLanguage.SWEDISH]: "Swedish",
    [DeepgramTranscriptionLanguage.DANISH]: "Danish",
    [DeepgramTranscriptionLanguage.NORWEGIAN]: "Norwegian",
    [DeepgramTranscriptionLanguage.DUTCH]: "Dutch",
    [DeepgramTranscriptionLanguage.GERMAN]: "German",
    [DeepgramTranscriptionLanguage.ENGLISH]: "English",
    [DeepgramTranscriptionLanguage.ENGLISH_GREAT_BRITAIN]: "English (Great Britain)",
    [DeepgramTranscriptionLanguage.ENGLISH_UNITED_STATES]: "English (United States)",
};
exports.DeepgramLanguagesLabels = DeepgramLanguagesLabels;
const DeepgramModalByLanguage = {
    [DeepgramTranscriptionLanguage.SWEDISH]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE,
        DeepgramTranscriptionModal.NOVA,
        DeepgramTranscriptionModal.NOVA_2,
    ],
    [DeepgramTranscriptionLanguage.ENGLISH]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE,
        DeepgramTranscriptionModal.NOVA,
        DeepgramTranscriptionModal.NOVA_2
    ],
    [DeepgramTranscriptionLanguage.ENGLISH_GREAT_BRITAIN]: [
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE,
        DeepgramTranscriptionModal.NOVA,
        DeepgramTranscriptionModal.NOVA_2
    ],
    [DeepgramTranscriptionLanguage.ENGLISH_UNITED_STATES]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE,
        DeepgramTranscriptionModal.NOVA,
        DeepgramTranscriptionModal.NOVA_2
    ],
    [DeepgramTranscriptionLanguage.GERMAN]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE,
        DeepgramTranscriptionModal.NOVA_2
    ],
    [DeepgramTranscriptionLanguage.NORWEGIAN]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE
    ],
    [DeepgramTranscriptionLanguage.DANISH]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE
    ],
    [DeepgramTranscriptionLanguage.DUTCH]: [
        DeepgramTranscriptionModal.ENHANCED,
        DeepgramTranscriptionModal.WHISPER_CLOUD,
        DeepgramTranscriptionModal.BASE
    ],
};
exports.DeepgramModalByLanguage = DeepgramModalByLanguage;
