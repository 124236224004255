import React from "react";
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import "../Style/Sass/voiceBot.scss";
import {Roles} from "../Helpers/Roles";
import {Switch, Text} from "../Components/Form/Element";
import {IAuthObject} from "../Interfaces/Redux";
import {
  HTMLevent,
  IOrganization,
  IUser
} from "../Interfaces/Common";
import {checkVbsVoiceBot, updateOrganization} from "../Store";
import {flattenOrganizations} from "../Helpers/Common";

interface IProps {
  auth: IAuthObject;
  users: IUser[];
  organizations: Array<IOrganization>;
}

interface IState {
  settings: {
    voice_bot: boolean;
    voice_bot_maps: boolean;
    voice_bot_production_mode: boolean;
    vbs_host: string;
    voice_bot_vbs_settings: {
      enabled: boolean;
      towards_enabled: boolean;
      future_bookings_enabled: boolean;
    };
  };
  organizations: Array<any>;
  selectedOrganization: any;
}

class VoiceBotSettings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const organizations = flattenOrganizations(this.props.organizations);
    this.state = {
      settings: {
        voice_bot: false,
        voice_bot_maps: false,
        voice_bot_production_mode: true,
        vbs_host: "",
        voice_bot_vbs_settings: {
          enabled: false,
          towards_enabled: false,
          future_bookings_enabled: false
        }
      },
      organizations: organizations,
      selectedOrganization: organizations[0]
    };
  }

  async componentDidMount() {
    this.updateOrganizationData();
  }
  
  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.organizations !== this.props.organizations) {
      this.updateOrganizationData();
    }
  }
  
  async updateOrganizationData() {
    const organizations = flattenOrganizations(this.props.organizations);
    const selectedOrganization = this.state.selectedOrganization
      ? organizations.find(({ id }) => id === this.state.selectedOrganization.id)
      : organizations[0];
    if (selectedOrganization) {
      let settings = {
        ...this.state.settings,
        voice_bot: selectedOrganization.voice_bot,
        vbs_host: selectedOrganization.vbs_host,
        voice_bot_maps: selectedOrganization.voice_bot_maps
      };
      const vbsSettings = await checkVbsVoiceBot(selectedOrganization.id);
      settings.voice_bot_vbs_settings = vbsSettings.data;
      this.setState({
        organizations,
        selectedOrganization,
        settings
      });
    }
  }  

  public render(): React.ReactNode {

    return (
      <Translation>
        {(t) => (
          <div className="vb-host">
            <h1>
              {t("VOICE_BOT")}{" "}
              {this.props.auth.user.role_id <= Roles.admin && (
                <a className="button" onClick={this.handleSave}>
                  {t("SAVE")}
                </a>
              )}
            </h1>
            <div className="p-2">
              <select
                name="selectedOrganization"
                value={this.state.selectedOrganization?.id || 1 }
                className="select field-border select-org"
                onChange={(event) =>
                  this.onChange("selectedOrganization", event.target.value)
                }
              >
                <option>Select Organization</option>
                {this.state.organizations?.map((node) => {
                  return (
                    <option value={node.id} key={node.id}>
                      {node.name} &nbsp;{" "}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="main paper">
              <div className="vb-row">
                <div className="title"> {t("VOICE_BOT")}</div>

                <Switch
                  value={this.state.settings?.voice_bot}
                  name="voice_bot"
                  onChange={(key, value) => this.onChange(key, value)}
                />
              </div>
              {this.state.settings.voice_bot && this.props.auth.user.role_id === Roles.god && (
                <>
                  <div className="vb-row">
                    <div className="title">{`${t("PRODUCTION_MODE")}`}</div>

                    <Switch
                      value={this.state.settings?.voice_bot_production_mode}
                      name="voice_bot_production_mode"
                      onChange={(key, value) => this.onChange(key, value)}
                    />
                  </div>
                  <div className="vb-row">
                    <div className="title">{t("VOICE_BOT_MAPS")}</div>

                    <Switch
                      value={this.state.settings?.voice_bot_maps}
                      name="voice_bot_maps"
                      onChange={(key, value) => this.onChange(key, value)}
                    />
                  </div>
                  <div className="vb-row">
                    <div className="title">{t("VBS_HOST")}</div>

                    <Text
                      value={this.state.settings?.vbs_host || ""}
                      name="vbs_host"
                      onChange={(key, value) => this.onChange(key, value)}
                    />
                  </div>
                </>
              )}

              {this.state.settings.voice_bot &&
                this.state.settings.vbs_host && (
                  <>
                    <div className="vb-row">
                      <div className="title"> {t("ENABLE_VOICEBOT")}</div>

                      <Switch
                        value={
                          this.state.settings.voice_bot_vbs_settings?.enabled
                        }
                        name="enabled"
                        onChange={(key, value) => this.onChange(key, value)}
                      />
                    </div>
                    <div className="vb-row">
                      <div className="title">
                        {" "}
                        {t("ENABLE_TOWARDS_ADDRESS")}
                      </div>

                      <Switch
                        value={
                          this.state.settings.voice_bot_vbs_settings
                            ?.towards_enabled
                        }
                        name="towards_enabled"
                        onChange={(key, value) => this.onChange(key, value)}
                      />
                    </div>
                    <div className="vb-row">
                      <div className="title"> {t("ENABLE_FUTURE_BOOKING")}</div>

                      <Switch
                        value={
                          this.state.settings?.voice_bot_vbs_settings
                            ?.future_bookings_enabled
                        }
                        name="future_bookings_enabled"
                        onChange={(key, value) => this.onChange(key, value)}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
      </Translation>
    );
  }

  async onChange(key: string, value: any) {
    let settings: IState["settings"] = {...this.state.settings};
    switch (key) {
      case "selectedOrganization":
        const organization = this.state.organizations.filter(({id}) => id == value)[0];
        settings.voice_bot = organization.voice_bot;
        settings.vbs_host = organization.vbs_host;
        settings.voice_bot_maps = organization.voice_bot_maps;
        settings.voice_bot_production_mode = organization.voice_bot_production_mode;
        const vbsSettings = await checkVbsVoiceBot(organization.id)
        settings.voice_bot_vbs_settings = vbsSettings.data;
        this.setState({selectedOrganization: organization, settings});
        return;
      case "enabled":
      case "towards_enabled":
      case "future_bookings_enabled":
        settings.voice_bot_vbs_settings[key] = value;
        break;
      default:
        settings[key] = value;
        break;
    }

    this.setState({settings});
  };

  private handleSave: HTMLevent = async (e) => {
    if (this.state.selectedOrganization) {
      await updateOrganization({
        ...this.state.selectedOrganization,
        voice_bot: this.state.settings.voice_bot,
        vbs_host: this.state.settings.vbs_host,
        voice_bot_maps: this.state.settings.voice_bot_maps,
        voice_bot_vbs_settings: this.state.settings.voice_bot_vbs_settings,
        voice_bot_production_mode: this.state.settings.voice_bot_production_mode,
      });
    }
  };
}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    users: state.users,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(VoiceBotSettings);
