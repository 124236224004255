import React, {Component} from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalBody: React.ReactNode
}

export default class Modal extends Component<ModalProps, {}> {

  constructor(props: ModalProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return this.props.isOpen ? (
      <>
        <div className={"modal"}>
          <div
            className={"modal__overlay"}
            onClick={(e) => this.handleOverlay(e)}
          />
          <div className={"modal__box"}>
            <div style={{width: "200%", height: "200%", marginLeft: "-50%"}}>
              {this.props.modalBody}
            </div>
            <button className={"modal__close"} onClick={(e) => this.handleOverlay(e)}>
              X
            </button>
          </div>
        </div>
      </>
    ) : null;
  }
  public handleOverlay(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    this.props.onClose();
  }

}