import React, {Component} from "react";
import {HTMLElementEventHandler} from "../../../Interfaces/Common";

interface IProps {
  onChange: HTMLElementEventHandler;
  name: string;
  value: boolean;
}

export default class FormElementSwitch extends Component<IProps> {

  public render(): React.ReactNode {
    return <div className={`switch${this.props.value ? " checked" : ""}`}
                onClick={e => this.props.onChange(this.props.name, !this.props.value)}>
      <div className="bg"></div>
      <div className="ball"></div>
    </div>;
  }
}
