import {IOption, IOrganization} from "../Interfaces/Common";
import i18n from "i18next";
import moment from "moment";
import config from "../config";

export function organizationOptions(organizations: Array<IOrganization>): Array<IOption> {
  let flatten: Array<IOption> = [{key: "", title: i18n.t("SELECT_ORGANIZATION")}];
  function ls(organizations: Array<IOrganization>, parent: Array<string> = []): any {
    organizations.forEach(org => {
      flatten.push({
        key: org.id,
        title: `${[...parent, org.name].join(" > ")}`
      });
      if ((org.organizations || []).length)
        ls(org.organizations, [...parent, org.name]);
    });
  }
  ls(organizations);
  return flatten;
}

export function flattenNodes(organizations: Array<any>) {
  let nodes: Array<any> = [];
  function flatten(orgs: Array<any>) {
    orgs.forEach((org) => {
      org.nodes.forEach((node) => {
        nodes.push({
            host: node.hostname,
            id: node.id,
            name: node.name,
            voice_bot: org.voice_bot,
            voice_bot_maps: org.voice_bot_maps,
            vbs_host: org.vbs_host,
            insights_license: org.insights_license,
            skyview_license: org.skyview_license
        });
      });

      if (Array.isArray(org.organizations))
        flatten(org.organizations);
    });

    return nodes;
  }

  return flatten(organizations);
}

export function flattenOrganizations(organizations: Array<IOrganization>): Array<IOrganization> {
  let flatten: Array<IOrganization> = [];
  function ls(organizations: Array<IOrganization>, parent: Array<string> = []): any {
    organizations.forEach(org => {
      flatten.push(org);
      if ((org.organizations || []).length)
        ls(org.organizations, [...parent, org.name]);
    });
  }
  ls(organizations);
  return flatten;
}
export function organizationNodeToOptions(organizations: Array<IOrganization>):Array<IOption> {
  let nodes: Array<IOption> = [{key: "", title: i18n.t("SELECT_WIDGETS_HOST")}];
    function flatten(orgs: Array<IOrganization>, parent: Array<string> = []) {
        orgs.forEach((org) => {
            org.nodes.forEach((node) => {
                nodes.push({
                    key: node.id,
                    title: `${[...parent, node.name].join(" > ")}`
                });
            });

            if (Array.isArray(org.organizations))
                flatten(org.organizations, [...parent, org.name]);
        });
        return nodes;
    }
    return flatten(organizations);
}

export const getDates = (days: number) => {
    const dates = [];
    dates.push(moment().format("yyyy-MM-DD"));
    while (dates.length !== days) {
        dates.push(
            moment().subtract(dates.length, "days").format("yyyy-MM-DD")
        );
    }
    return dates;
};

export const getPastDateForWeeks = (numberOfWeeks: number) => {
    const dates = [];
    for (let i = 0; i < numberOfWeeks; i++) {
        dates.push(
            moment()
                .subtract(7 * i, "days")
                .format("yyyy-MM-DD")
        );
    }
    return dates.reverse();
};

export function getLoginCookieDomain(): string {
  if(window.location.hostname.includes("xcally.com")) {
    return ".xcally.com";
  }

  return config.loginCookieDomain;
}

export const checkImageValidityByUrl = async (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      //The image URL is valid
      resolve(true);
    };

    img.onerror = () => {
      //The image URL is not valid
      resolve(false); 
    };

    img.src = url;

    // If the image is already in the browser cache, the `load` event
    // might have been triggered before the event listener was attached.
    // Checking `img.complete` to handle this scenario.
    if (img.complete && img.naturalWidth !== 0) {
      //The image URL is valid and was loaded from cache
      resolve(true);
    }
  });
};