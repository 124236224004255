import React from "react";
import {connect} from "react-redux";
import {IAuthObject} from "../Interfaces/Redux";
import IStatsThresholdNotificationSMSSent from "connectel-shared/interfaces/StatsThresholdNotificationSMSSent";
import { fetchSmsNotification} from "../Store/index";
import {Translation} from "react-i18next";
import "../Style/Sass/Modal.scss";
import { IUser} from "../Interfaces/Common";
import {Roles} from "../Helpers/Roles";
import { flattenNodes } from "../Helpers/Common";

interface IProps {
    auth: IAuthObject;
    users: IUser[];
    organizations:any;
}
interface ICustomOrganization {
    name: string;
    host: string;
    id: string;
    insights_license: boolean;
    skyview_license: boolean;
}

interface IState {
    nodes: Array<ICustomOrganization>;
    label: string;
    thresholdSmsSentList: Array<IStatsThresholdNotificationSMSSent>;
}

const header = ["SNO", "PHONE_NO", "MESSAGE", "TIME"];
class SmsNotificationPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            nodes: [],
            label: "",
            thresholdSmsSentList: []
        };
    }

    async componentDidMount() {
        try {
            const nodes = flattenNodes(this.props.organizations);
            this.setState({nodes: nodes});
        } catch (error) {}
    }

    public render(): React.ReactNode {
        if (this.props.auth.user.role_id !== Roles.god) return <></>;
        return (
            <Translation>
                {(t) => (
                    <div>
                        <h1>{t("SMS_NOTIFICATIONS")}</h1>
                        <div className="p-2">
                            <select
                                className="select field-border select-org"
                                onChange={(event) => this.handleChange(event)}
                            >
                                <option>Select Organization</option>
                                {this.state.nodes?.map((organization) => {
                                    return (
                                        <option value={organization.host} key={organization.host}>
                                            {organization.name} &nbsp;{" "}
                                        </option>
                                    );
                                })}
                            </select>
                            <article>
                                <table className="list organizations">
                                    <thead>
                                        <tr>
                                            {header.map((value) => (
                                                <th>{t(value)}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.thresholdSmsSentList?.map(
                                        (user,index) => {
                                            return (
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{user.phone_number}</td>
                                                    <td>{user.message_text}</td>
                                                    <td>{user.createdAt}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    </tbody>
                                </table>
                            </article>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }

     handleChange(event) {
        try {
            const host = event.target.value;
             fetchSmsNotification(host).then((response)=>{
                this.setState({thresholdSmsSentList: response?.data ?? []});
             });
        } catch (error) {
            this.setState({thresholdSmsSentList: []});
        }
    }
}

function mapStateToProps(state: IProps): IProps {
    return {
        auth: state.auth,
        users: state.users,
        organizations:state.organizations
    };
}

export default connect(mapStateToProps, {})(SmsNotificationPage);
