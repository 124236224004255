import {Action, IConfigAction, IConfigObject} from "../Interfaces/Redux";


const DefaultState: IConfigObject = {
  lang: "en",
  theme: "light",
  window: {
    height: null,
    width: null
  },
  tables: {
    users: {
      sort: ["id", "asc"]
    }
  }
};

export default function ConfigReducer(state: IConfigObject = DefaultState, action: IConfigAction): IConfigObject {
  switch (action.type) {
    case Action.SET_CONFIG:
      state = {
        lang: action.payload.lang || state.lang || DefaultState.lang,
        tables: action.payload.tables || state.tables || DefaultState.tables,
        theme: action.payload.theme || state.theme || DefaultState.theme,
        window: action.payload.window || state.window || DefaultState.window
      };
      break;
    default:
      break;
  }
  return state;
}
