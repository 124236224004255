import React from "react";
import {i18n} from "../i18n";
import {alertInfo} from "../Store";
import uuidv4 from "uuid/v4";
import {
  IObject,
  IDidObject,
  IVoiceUri,
  IDidCartItem,
  IOrganization
} from "../Interfaces/Common";
import {Select} from "./Form/Element";
import fetchVoiceUris from "../Fetch/VoiceUris";
import SelectOrganization from "./Form/SelectOrganization";

export const DidTableHead = (
  props: {orgNrChangeable: boolean} = {orgNrChangeable: false}
) => (
  <thead>
    <tr>
      <th>{i18n.t("COUNTRY_NAME")}</th>
      <th>{i18n.t("AREA_CODE")}</th>
      <th>{i18n.t("CITY_NAME")}</th>
      <th>{i18n.t("DID_TYPE")}</th>
      <th>{i18n.t("SETUP_PRICE")}</th>
      <th>{i18n.t("MONTHLY_PRICE")}</th>
      {props.orgNrChangeable && <th>{i18n.t("SELECT_ORG_NR")}</th>}
      <th>{i18n.t("SELECT_VOICE_URI")}</th>
      <th>{i18n.t("STOCK")}</th>
      <th>{i18n.t("QUANTITY")}</th>
    </tr>
  </thead>
);

interface IProps {
  id?: string; // cart item id
  didObject: IDidObject;
  orgNr: number;

  initialQuantity?: number;
  initialVoiceUriId?: number;

  onItemChange?: (item: IObject) => void;

  organizations?: IOrganization[]; // if provided, orgNr is changeable
  voiceUris?: IVoiceUri[]; // used if provided, otherwise fetches them based on orgNr

  addToCart?: (cartItem: IDidCartItem) => void;
  removeFromCart?: (itemId: string) => void;
}
interface IState {
  quantity: number;
  voiceUriId: number;
  voiceUris: IVoiceUri[];
  orgNr: number;
}

export default class DidTableRow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    if (props.organizations && props.voiceUris) {
      throw new Error(
        "Can't pass both organizations and voiceUris to DidObject"
      );
    }

    this.state = {
      quantity: props.initialQuantity || 0,
      voiceUriId: props.initialVoiceUriId,
      orgNr: props.orgNr,
      voiceUris: []
    };

    if (!this.props.voiceUris) {
      this.fetchVoiceUris(props.orgNr);
    }
  }

  public componentDidUpdate() {
    if (this.props.orgNr !== this.state.orgNr) {
      this.setState({orgNr: this.props.orgNr});
    }
  }

  public render(): React.ReactNode {
    const didObject = this.props.didObject;

    return (
      <tr key={didObject.didId}>
        <td>{didObject.countryName}</td>
        <td>{didObject.areaCode}</td>
        <td>{didObject.cityName || ""}</td>
        <td>{didObject.didType}</td>
        <td>
          {didObject.setupPrice} {didObject.currency}
        </td>
        <td>
          {didObject.monthlyPrice} {didObject.currency}
        </td>
        {this.props.organizations && (
          <td>
            <SelectOrganization
              orgNr={this.state.orgNr}
              organizations={this.props.organizations}
              onChange={(orgNr) => this.onOrgChange(orgNr)}
            />
          </td>
        )}
        <td>
          {/*<select
            name="voiceUri"
            className="select field-border"
            onChange={e => this.onVoiceUriChange(parseInt(e.target.value))}
            value={
              this.state.voiceUriId || (i18n.t('SELECT_VOICE_URI') as string)
            }>

              {
                !this.state.voiceUriId &&
                  <option value={i18n.t('SELECT_VOICE_URI') as string}>{i18n.t('SELECT_VOICE_URI')}</option>
              }

              {
                this.props.voiceUris.map(voiceUri => (
                  <option key={voiceUri.id} value={voiceUri.id}>{voiceUri.address}</option>
                ))
              }

            </select>*/}
          <Select
            className="select field-border"
            selected={this.state.voiceUriId}
            options={(this.state.voiceUriId
              ? []
              : [{key: undefined, title: i18n.t("SELECT_VOICE_URI")}]
            ).concat(
              (this.props.voiceUris || this.state.voiceUris)
                .filter(
                  (e1, i, self) => self.findIndex((e2) => e1.id === e2.id) === i
                )
                .map((voiceUri) => ({
                  key: voiceUri.id,
                  title: voiceUri.address + " - " + voiceUri.description
                }))
            )}
            name="voiceUriId"
            onChange={(key, val) => this.onVoiceUriChange(val)}
          />
        </td>
        <td>{didObject.stock}</td>
        <td>
          <input
            className="field-border quantity-input"
            type="number"
            name="quantity"
            value={Number(this.state.quantity).toString()}
            onChange={(e) => this.onQuantityChange(e.target.value)}
          />
        </td>
        <td>
          {this.props.removeFromCart && this.props.id && (
            <button
              className="button ml-1"
              onClick={() => {
                this.props.removeFromCart(this.props.id);
              }}
            >
              {i18n.t("REMOVE_FROM_CART")}
            </button>
          )}

          {this.props.addToCart && (
            <button
              disabled={this.isAddToCartDisabled(didObject)}
              className="button ml-1"
              onClick={() => this.addToCart()}
            >
              {i18n.t("ADD_TO_CART")}
            </button>
          )}
        </td>
      </tr>
    );
  }

  private isAddToCartDisabled(didObject: IDidObject): boolean {
    return didObject.stock === 0 || this.state.quantity > didObject.stock;
  }

  private onVoiceUriChange(voiceUriId: number) {
    this.setState({voiceUriId});
    if (this.props.onItemChange) {
      this.props.onItemChange({voiceUriId});
    }
  }

  /*private onVoiceUriChange(voiceUriId: number) {
    this.setState({ voiceUriId });
    if (this.props.onVoiceUriChange) {
      this.props.onVoiceUriChange(voiceUriId);
    }
  }*/

  private onQuantityChange(val: string) {
    const quantity = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(quantity)) {
      this.setState({quantity});
      if (this.props.onItemChange) {
        this.props.onItemChange({quantity});
      }
    }
  }

  private onOrgChange(orgNr: number) {
    this.setState({orgNr, voiceUriId: undefined});
    if (this.props.onItemChange) {
      this.props.onItemChange({orgNr, voiceUriId: undefined});
    }
    this.fetchVoiceUris(orgNr);
  }

  private addToCart() {
    if (!this.state.voiceUriId) {
      return alertInfo(
        "You need to specify voice URI the number should be assigned to."
      );
    } else if (!this.state.quantity) {
      return alertInfo("You need to specify quantity.");
    }

    const uuid: string = this.props.id || uuidv4();
    this.props.addToCart({
      id: uuid,
      didObject: this.props.didObject,
      quantity: this.state.quantity,
      voiceUriId: this.state.voiceUriId,
      orgNr: this.state.orgNr
    });
    this.setState({quantity: 0});
  }

  private async fetchVoiceUris(orgNr: number) {
    try {
      const voiceUris = await fetchVoiceUris(orgNr);
      this.setState({voiceUris});
    } catch (err) {
      this.setState({voiceUris: []});
      alertInfo(i18n.t("NO_VOICE_URIS"));
    }
  }
}
