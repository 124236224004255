import React from "react";
import {connect} from "react-redux";
import Translation, {i18n} from "../i18n";
import {
  INumber,
  INumberOrder,
  IOrganization,
  IVoiceUri
} from "../Interfaces/Common";
import {IAuthObject} from "../Interfaces/Redux";
import {alertInfo, selectOrganization} from "../Store";
import SelectOrganization from "../Components/Form/SelectOrganization";

import "../Style/Sass/CustomerNumbers.scss";
import fetchOrders from "../Fetch/Orders";
import fetchVoiceUris from "../Fetch/VoiceUris";
import {formatDid} from "./CustomerNumbers";
import SyncOrgRoute from "../Components/SyncOrgRoute";

interface IProps {
  match: any;
  history: any;
  orgNr: number;
  auth: IAuthObject;
  organizations: IOrganization[];
}

interface INumberOrderExtended extends INumberOrder {
  did: INumber;
}

interface IState {
  orders: INumberOrderExtended[];
  voiceUris: IVoiceUri[];
}

/**
 * View for displaying order history.
 */
class NumberOrders extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      orders: [],
      voiceUris: []
    };

    if (props.orgNr) {
      this.fetchOrders(props.orgNr);
    }
  }

  public render(): React.ReactNode {
    const getOrderRows: (orders: INumberOrderExtended[]) => React.ReactNode[] =
      (orders) =>
        orders.map((order) => [
          <tr key={order.id}>
            <td>{order.orderDate}</td>
            <td>{order.auditUserName}</td>
            <td>{order.auditOrgName}</td>
            <td>{order.didType}</td>
            <td>{order.didCountry}</td>
            <td>{order.orderQuantity}</td>
            <td>
              {order.orderSetup && order.orderSetup + " " + order.orderCurrency}
            </td>
            <td>
              {order.orderMonthly &&
                order.orderMonthly + " " + order.orderCurrency}
            </td>
            <td>{order.did && formatDid(order.did)}</td>
            <td>{order.did && (!order.did.didPending).toString()}</td>
          </tr>
        ]);

    const orgNrRouteParam: number = parseInt(this.props.match.params.orgNr, 10);

    return (
      <Translation>
        {(t) => (
          <>
            <SyncOrgRoute
              orgNr={this.props.orgNr}
              orgNrRoute={orgNrRouteParam}
              routeTail={"/numbers/orders"}
            />

            <div className="container-header flex justify-between items-center px-2">
              <h1>{t("NUMBER_ORDERS")}</h1>
            </div>

            <div className="p-2">
              <SelectOrganization
                orgNr={this.props.orgNr}
                organizations={this.props.organizations}
                onChange={(orgNr) => {
                  selectOrganization(orgNr);
                  this.fetchOrders(orgNr);
                }}
              />
            </div>

            <article>
              <table className="list organizations">
                <thead>
                  <tr>
                    <th>{t("ORDER_DATE")}</th>
                    <th>{t("USER")}</th>
                    <th>{t("ORGANIZATION")}</th>
                    <th>{t("DID_TYPE")}</th>
                    <th>{t("COUNTRY_NAME")}</th>
                    <th>{t("QUANTITY")}</th>
                    <th>{t("SETUP_PRICE")}</th>
                    <th>{t("MONTHLY_PRICE")}</th>
                    <th>{t("NUMBER")}</th>
                    <th>{t("PURCHASE_FINALIZED")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orders.length > 0 &&
                    getOrderRows(this.state.orders)}
                </tbody>
              </table>
            </article>
          </>
        )}
      </Translation>
    );
  }

  /**
   * Fetches order history.
   */
  private async fetchOrders(orgNr: number): Promise<void> {
    try {
      const voiceUris: IVoiceUri[] = await fetchVoiceUris(orgNr);

      const numbers: {
        [didId: number]: INumber & {voiceUri: IVoiceUri};
      } = {};
      voiceUris.forEach((voiceUri: IVoiceUri) => {
        voiceUri.numbers.forEach((number: INumber) => {
          numbers[number.id] = {
            ...number,
            voiceUri
          };
        });
      });

      const orders: INumberOrderExtended[] = (await fetchOrders(orgNr)).map(
        (order) => ({
          ...order,
          did: numbers[order.orderDid]
        })
      );

      this.setState({
        orders
      });
    } catch (err) {
      this.setState({orders: []});
      alertInfo(i18n.t("NO_NUMBER_ORDERS"));
    }
  }
}

interface IStoreProps {
  organizations: Array<IOrganization>;
  orgNr: number;
}
function mapStateToProps(state: IStoreProps): IStoreProps {
  return {
    orgNr: state.orgNr,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(NumberOrders);
