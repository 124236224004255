import {Action, IStatusAction, IStatusObject} from "../Interfaces/Redux";

export default function LoadingReducer(state: Array<IStatusObject> = [], action: IStatusAction): Array<IStatusObject> {
  switch (action.type) {
    case Action.SAVING:
    case Action.DELETING:
    case Action.UPDATING:
    case Action.LOADING:
      state = [{
        type: action.type,
        uuid: action.payload.uuid
      }, ...state];
      break;
    case Action.LOADING_DONE:
      state = state.filter(s => s.uuid !== action.payload.uuid);
      break;
    default:
      break;
  }
  return state;
}
