import {HOST, NumbersReq} from "../Store";
import {INumberOrder} from "../Interfaces/Common";

const orderCache: {
  [orgNr: number]: INumberOrder[];
} = {};

/**
 * Fetches order history for organization. Caches data but can force reload.
 * @param orgNr
 * @param options
 * @returns order history
 */
export default async function fetchOrders(
  orgNr: number,
  options: {reload: boolean} = {reload: false}
): Promise<any[]> {
  const {reload} = options;
  if (orderCache[orgNr] && !reload) {
    return orderCache[orgNr];
  }

  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/orders`,
    null,
    "get",
    {
      displayErrors: false
    }
  );
  const orders: INumberOrder[] = resp.data.orders[0].orders;
  orderCache[orgNr] = orders;
  return orders;
}

/**
 * Clears cache for specified orgNr.
 * @param orgNr
 */
export function clearCache(orgNr: number): void {
  orderCache[orgNr] = null;
}
