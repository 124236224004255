import React from "react";
import {useFormik} from "formik";
import Popup from "../../Popup";
import "../../../Style/Sass/PortingRequestForm.scss";
import {IEditNumber} from "../../../Interfaces/Common";
import Translation from "../../../i18n";
import Form from "../../Form";
import {customerNumberDescription} from "../../../Fetch/VoiceUris";

type TProps = {
  closeModal: () => void;
  editNumber: IEditNumber;
  reloadList: Function;
};

const EditCustomerNumber: React.FC<TProps> = React.memo(
  ({closeModal, editNumber, reloadList}) => {
    const {handleChange, handleSubmit, values} = useFormik({
      initialValues: {
        description: editNumber.description
      },
      onSubmit: async (values) => {
        await customerNumberDescription(
          editNumber.orgNr,
          editNumber.phoneNumber,
          values.description
        );
        reloadList(true);
        closeModal();
      }
    });
    const closeForm = () => {
      closeModal();
    };
    return (
      <Popup onCloseForm={closeForm}>
        <Translation>
          {(t) => (
            <Form onCancel={closeForm} onSubmit={handleSubmit}>
              <h2>
                {t("Edit")} {t("DESCRIPTION")}
              </h2>
              <div className="portingFormLayout">
                <div className="fieldStyle">
                  <div className="fieldTitle">Phone Number</div>
                  <div className="fieldValue">{editNumber.phoneNumber}</div>
                </div>
                <div className="fieldStyle">
                  <div className="fieldTitle">Description</div>
                  <div className="fieldValue">
                    <textarea
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Description"
                      rows={4}
                      cols={50}
                      style={{resize: "vertical"}}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Translation>
      </Popup>
    );
  }
);
export default EditCustomerNumber;
