import React from "react";
import {connect} from "react-redux";
import Translation, {i18n} from "../i18n";
import {addToCart, alertInfo, selectOrganization} from "../Store";
import fetchVoiceUris from "../Fetch/VoiceUris";
import fetchInventory from "../Fetch/Inventory";
import DidTableRow, {DidTableHead} from "../Components/DidObject";
import SelectOrganization from "../Components/Form/SelectOrganization";
import {
  IVoiceUri,
  IDidObject,
  IDidCart,
  IOrganization,
  IDidObjectRaw
} from "../Interfaces/Common";
import {COUNTRY_KEY} from "../Helpers/Constants";
import Icon from "../Components/Icon";
import "../Style/Sass/Inventory.scss";
import {Select} from "../Components/Form/Element";
import InfiniteScroll from "react-infinite-scroll-component";
import fetchCountries from "../Fetch/InventoryCountries";
import SyncOrgRoute from "../Components/SyncOrgRoute";
import { getItem, setItem } from "../cookies";

interface IProps {
  match: any;
  history: any;
  didCart: IDidCart;
  orgNr: number;
  organizations: Array<IOrganization>;
}

const NOT_SELECTED: string = "NOT_SELECTED";
const ALL_COUNTRIES: string = "ALL_COUNTRIES";

interface ICountries {
  [key: string]: string;
}

interface IState {
  countries: ICountries;

  didInventory: IDidObject[];
  voiceUris: IVoiceUri[];

  countryCode: string; // country code

  renderedItems: number;
}

const RENDER_ITEMS_CHUNK: number = 50;

class Inventory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const orgNr: number = parseInt(props.match.params.orgNr, 10);
    if (orgNr) {
      selectOrganization(orgNr);
    }

    this.state = {
      countries: {},
      didInventory: [],
      voiceUris: [],

      countryCode: getItem(COUNTRY_KEY),

      renderedItems: RENDER_ITEMS_CHUNK
    };

    this.onParamsChange();
  }

  public componentDidUpdate(prevProps: any, prevState: any): void {
    if (
      prevProps.orgNr !== this.props.orgNr ||
      prevState.countryCode !== this.state.countryCode
    ) {
      this.onParamsChange();
    }
  }

  public render(): React.ReactNode {
    const orgNrRouteParam: number = parseInt(this.props.match.params.orgNr, 10);
    return (
      <Translation>
        {(t) => (
          <>
            <SyncOrgRoute
              orgNr={this.props.orgNr}
              orgNrRoute={orgNrRouteParam}
              routeTail={"/numbers/inventory"}
            />

            <div className="container-header flex justify-between items-center px-2">
              <h1>{t("PURCHASE_NUMBERS")}</h1>

              <div className="flex">
                <div className="mr-2 cart-icon-container">
                  <Icon
                    icon="cart"
                    className="cart-icon"
                    tooltip={{id: "CART", message: t("CART")}}
                  />
                  <span className="cart-badge">
                    {this.props.didCart.items.reduce(
                      (sum, item) => sum + item.quantity,
                      0
                    )}
                  </span>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    if (this.props.orgNr) {
                      this.props.history.push("/numbers/checkout");
                    } else {
                      alertInfo(i18n.t("NO_ORG_NR"));
                    }
                  }}
                >
                  {t("CHECK_OUT")}
                </button>
              </div>
            </div>

            <div className="p-2">
              <div className="flex justify-between py-2">
                <h2>{t("INVENTORY")}</h2>
              </div>

              <div>
                <SelectOrganization
                  orgNr={this.props.orgNr}
                  organizations={this.props.organizations}
                  onChange={(orgNr) => {
                    selectOrganization(orgNr);
                  }}
                />
              </div>

              <div>
                <Select
                  className="select field-border mt-1"
                  selected={this.state.countryCode}
                  options={(this.state.countryCode ? [] : [
                    {key: NOT_SELECTED, title: i18n.t("SELECT_COUNTRY")}
                  ])
                    .concat([{key: ALL_COUNTRIES, title: i18n.t(ALL_COUNTRIES)}])
                    .concat(
                      Object.keys(this.state.countries).map((countryCode) => ({
                        key: countryCode,
                        title: this.state.countries[countryCode]
                      }))
                    )
                  }
                    name="country"
                  onChange={(key, countryCode) => this.setState({countryCode})}
                  width={300}
                />
              </div>
            </div>

            <article>
              <InfiniteScroll
                dataLength={this.state.renderedItems}
                next={() => this.setState(state => ({ renderedItems: state.renderedItems + RENDER_ITEMS_CHUNK }))}
                hasMore={this.state.renderedItems < this.state.didInventory.length}
                loader={<p className="loading-inventory">{i18n.t("LOADING_ITEMS")}</p>}
              >
                <table className="list">
                  <DidTableHead orgNrChangeable={false} />
                  <tbody>
                    {
                      this.state.didInventory.slice(0, this.state.renderedItems).map(didObject => (
                        <DidTableRow
                          key={didObject.didId}
                          didObject={didObject}
                          orgNr={this.props.orgNr}
                          voiceUris={this.state.voiceUris}
                          addToCart={(cartItem) => addToCart(cartItem)}
                        />
                      ))
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </article>
          </>
        )}
      </Translation>
    );
  }

  private onParamsChange(): void {
    if (this.props.orgNr) {
      this.fetchVoiceUris(this.props.orgNr);

      fetchCountries(this.props.orgNr).then(countries => {
        const countriesObject: ICountries = countries.reduce(
          (acc, c) => (
            { ...acc, [c.countryCodeA2]: c.countryName}
          ),
          {}
        );
        this.setState({countries: countriesObject});
      });

      if (this.state.countryCode && this.state.countryCode !== NOT_SELECTED) {
        this.fetchInventory(this.props.orgNr, this.state.countryCode);
      }
    }
  }

  private async fetchInventory(
    orgNr: number,
    countryCode: string
  ): Promise<void> {
  setItem(COUNTRY_KEY, countryCode);

    if (countryCode === ALL_COUNTRIES) {
      countryCode = "";
    }

    try {
      // used to filter out duplicates
      const added: {[key: string]: boolean} = {};

      const didInventoryRaw: IDidObjectRaw[] = await fetchInventory(orgNr, countryCode);
      const didInventory: IDidObject[] = didInventoryRaw
        .reduce(
          (didObjects: IDidObject[], didObject: IDidObjectRaw) => {
            if (added[didObject.didId]) {
              return didObjects;
            } else {
              added[didObject.didId] = true;
              didObjects.push({
                countryCode: countryCode || didObject.countryCodeA2,
                countryName: this.state.countries[countryCode],
                ...didObject
              });
              return didObjects;
            }
          },
          []
        );

      this.setState({didInventory});
    } catch (err) {
      this.setState({didInventory: []});
    }
  }

  private async fetchVoiceUris(orgNr: number): Promise<void> {
    try {
      const voiceUris: IVoiceUri[] = await fetchVoiceUris(orgNr);
      this.setState({
        voiceUris
      });
    } catch (err) {
      this.setState({voiceUris: []});
      alertInfo(i18n.t("NO_VOICE_URIS"));
    }
  }
}

interface IStoreProps {
  didCart: IDidCart;
  organizations: Array<IOrganization>;
  orgNr: number;
}
function mapStateToProps(state: IStoreProps): IStoreProps {
  return {
    didCart: state.didCart,
    orgNr: state.orgNr,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(Inventory);
