import React, {Component} from "react";
import {HTMLElementEventHandler} from "../../../Interfaces/Common";

interface IProps {
  index?: number;
  onChange: HTMLElementEventHandler;
  value: string;
  name: string;
  width?: number;
  placeholder?: string;
}

export default class Text extends Component<IProps> {
  public render(): React.ReactNode {
    const style: React.CSSProperties = {};
    if (this.props.width) style.width = this.props.width;
    return (
      <input
        type="text"
        style={style}
        name={this.props.name}
        value={this.props.value}
        onChange={(e) =>
          this.props.onChange(this.props.name, e.target.value, this.props.index)
        }
        placeholder={this.props.placeholder}
      />
    );
  }
}
