import {HOST, NumbersReq} from "../Store";

/**
 * Fetch request to purchase number.
 * @param numberToCheck
 * @returns fetch response
 */
export default async function portabilityCheck(
  numberToCheck: String
): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}opm/portability_check/${numberToCheck}`,
    null,
    "post"
  );
  return resp;
}
