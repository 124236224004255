import React from "react";
import Icon from "../Components/Icon";
import Translation from "../i18n";
import {BrowserRouter, Route, Switch, NavLink} from "react-router-dom";
import AuthorizedRoute from "./AuthorizedRoute";
import RouteSites from "./Nodes";
import RouteSite from "./Site";
import RouteUsers from "./Users";
import RouteUser from "./User";
import RouteApps from "./App";
import RouteOrganizationUsers from "./OrganizationUsers";
import RouteInventory from "./Inventory";
import RouteCheckout from "./Checkout";
import RouteCustomerNumbers from "./CustomerNumbers";
import RouteNumberOrders from "./NumberOrders";
import NotFound from "../Components/NotFound";
import Unauthorized from "../Components/Unauthorized";
import {IObject} from "../Interfaces/Common";
import AnalyticsPage from "./AnalyticsPage";
import CreateNotification from "./CreateNotification";
import SmsNotificationPage from "./SmsNotificationPage";
import ExternalSharePage from "./ExternalShare";
import PortingRequests from "./PortingRequests";
import SyncRequests from "./SyncRequests";
import {connect} from "react-redux";
import {IAuthObject} from "../Interfaces/Redux";
import {Roles} from "../Helpers/Roles";
import "../Style/Sass/Nav.scss";
import Overview from "./Overview";
import VoiceBot from "./VoiceBot";

interface IProps {
    auth: IAuthObject;
}

interface IState {
    catgoryCollapser: Array<number>;
}

class NavBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            catgoryCollapser: []
        };
    }

    categoryOnClickHandler = (id: number) => {
        let {catgoryCollapser} = this.state;
        if (catgoryCollapser.includes(id)) {
            catgoryCollapser = catgoryCollapser.filter(
                (categoryId) => categoryId !== id
            );
        } else {
            catgoryCollapser.push(id);
        }
        this.setState({
            catgoryCollapser
        });
    };

    arrowIcon = (id: number) => (
        <Icon
            className="arrow"
            icon={`keyboard_arrow_${
                this.state.catgoryCollapser.includes(id) ? "down" : "up"
            }`}
        />
    );

    render(): React.ReactNode {
        const isGod = this.props.auth.user.role_id === Roles.god;
        return (
            <Translation>
                {(t) => (
                    <BrowserRouter basename="/portal">
                        <nav>
                            <div className="category">
                                <div
                                    className="title"
                                    onClick={() => {
                                        this.categoryOnClickHandler(1);
                                    }}
                                >
                                    <Icon icon="people_alt" />
                                    {t("STAFF")}
                                    {this.arrowIcon(1)}
                                </div>
                                <div
                                    className={
                                        this.state.catgoryCollapser.includes(1)
                                            ? "collapse"
                                            : ""
                                    }
                                >
                                    <NavLink to="/organizations">
                                        {t("ORGANIZATIONS")}
                                    </NavLink>
                                    <NavLink to="/users">{t("USERS")}</NavLink>
                                </div>
                            </div>
                            <div className="category">
                                <div
                                    className="title"
                                    onClick={() => {
                                        this.categoryOnClickHandler(2);
                                    }}
                                >
                                    <Icon icon="phone" />
                                    {t("NUMBERS")}
                                    {this.arrowIcon(2)}
                                </div>
                                <div
                                    className={
                                        this.state.catgoryCollapser.includes(2)
                                            ? "collapse"
                                            : ""
                                    }
                                >
                                    <NavLink to={"/numbers/inventory"}>
                                        {t("PURCHASE_NUMBERS")}
                                    </NavLink>
                                    <NavLink to={"/numbers"}>
                                        {t("CUSTOMER_NUMBERS")}
                                    </NavLink>
                                    <NavLink to={"/porting"}>
                                        {t("PORTING_REQUESTS")}
                                    </NavLink>
                                </div>
                            </div>
                            <div className="category">
                                <div
                                    className="title"
                                    onClick={() => {
                                        this.categoryOnClickHandler(3);
                                    }}
                                >
                                    <Icon icon="servers" /> {t("ACCOUNT")}
                                    {this.arrowIcon(3)}
                                </div>
                                <div
                                    className={
                                        this.state.catgoryCollapser.includes(3)
                                            ? "collapse"
                                            : ""
                                    }
                                >
                                    <NavLink to={"/sync-requests"}>
                                        {t("SYNC_REQUESTS")}
                                    </NavLink>
                                    <NavLink to={"/numbers/orders"}>
                                        {t("NUMBER_ORDERS")}
                                    </NavLink>
                                    <NavLink to="/apps">{t("APPS")}</NavLink>
                                </div>
                            </div>
                            {isGod && (
                                <div className="category">
                                    <div
                                        className="title"
                                        onClick={() => {
                                            this.categoryOnClickHandler(4);
                                        }}
                                    >
                                        <Icon icon="settings" /> {t("SETTINGS")}
                                        {this.arrowIcon(4)}
                                    </div>
                                    <div
                                        className={
                                            this.state.catgoryCollapser.includes(
                                                4
                                            )
                                                ? "collapse"
                                                : ""
                                        }
                                    >
                                        <NavLink to="/notification/create">
                                            {t("NOTIFICATIONS")}
                                        </NavLink>
                                        <NavLink to="/analytics">
                                            {t("ANALYTICS")}
                                        </NavLink>
                                        <NavLink to="/notification/sms">
                                            {t("SMS_NOTIFICATIONS")}
                                        </NavLink>
                                        <NavLink to="/external/share">
                                            {t("EXTERNAL_SHARE")}
                                        </NavLink>
                                        <NavLink to="/overview">
                                            {t("CUSTOMER_OVERVIEW")}
                                        </NavLink>
                                                                           </div>
                                </div>
                            )}
                            {this.props.auth.user.role_id <= Roles.admin && (
                                <NavLink to="/voiceBot">{t("VOICE_BOT")}</NavLink>
                            )}
                        </nav>
                        <section>
                            <Switch>
                                <AuthorizedRoute
                                    path="/"
                                    exact
                                    component={RouteSites}
                                />
                                <AuthorizedRoute
                                    path="/organizations"
                                    exact
                                    component={RouteSites}
                                />
                                <Route
                                    path="/unauthorized"
                                    render={(props: IObject) => (
                                        <Unauthorized {...props} />
                                    )}
                                />
                                <AuthorizedRoute
                                    path="/site/:site_id"
                                    exact
                                    component={RouteSite}
                                />
                                <AuthorizedRoute
                                    path="/users"
                                    exact
                                    component={RouteUsers}
                                />
                                <AuthorizedRoute
                                    path="/user/:user_id"
                                    exact
                                    component={RouteUser}
                                />
                                <AuthorizedRoute
                                    path="/organization/:organization_id/users/:license"
                                    exact
                                    component={RouteOrganizationUsers}
                                />

                                <AuthorizedRoute
                                    path="/organization/:orgNr/numbers/inventory"
                                    exact
                                    component={RouteInventory}
                                />
                                <AuthorizedRoute
                                    path="/numbers/inventory"
                                    exact
                                    component={RouteInventory}
                                />

                                <AuthorizedRoute
                                    path="/organization/:orgNr/numbers"
                                    exact
                                    component={RouteCustomerNumbers}
                                />
                                <AuthorizedRoute
                                    path="/numbers"
                                    exact
                                    component={RouteCustomerNumbers}
                                />

                                <AuthorizedRoute
                                    path="/numbers/checkout"
                                    exact
                                    component={RouteCheckout}
                                />
                                <AuthorizedRoute
                                    path="/organization/:orgNr/numbers/checkout"
                                    exact
                                    component={RouteCheckout}
                                />
                                <AuthorizedRoute
                                    path="/numbers/orders"
                                    exact
                                    component={RouteNumberOrders}
                                />
                                <AuthorizedRoute
                                    path="/organization/:orgNr/numbers/orders"
                                    exact
                                    component={RouteNumberOrders}
                                />
                                <AuthorizedRoute
                                    path="/porting"
                                    exact
                                    component={PortingRequests}
                                />
                                <AuthorizedRoute
                                    path="/sync-requests"
                                    exact
                                    component={SyncRequests}
                                />
                                <AuthorizedRoute
                                    path="/apps"
                                    exact
                                    component={RouteApps}
                                />
                                <AuthorizedRoute
                                    path="/notification/create"
                                    exact
                                    component={CreateNotification}
                                />
                                <AuthorizedRoute
                                    path="/analytics"
                                    exact
                                    component={AnalyticsPage}
                                />
                                <AuthorizedRoute
                                    path="/notification/sms"
                                    exact
                                    component={SmsNotificationPage}
                                />
                                <AuthorizedRoute
                                    path="/external/share"
                                    exact
                                    component={ExternalSharePage}
                                />
                                <AuthorizedRoute
                                    path="/overview"
                                    exact
                                    component={Overview}
                                />
                                 <AuthorizedRoute
                                    path="/voiceBot"
                                    exact
                                    component={VoiceBot}
                                />

                                <Route
                                    render={(props: IObject) => (
                                        <NotFound {...props} />
                                    )}
                                />
                            </Switch>
                        </section>
                    </BrowserRouter>
                )}
            </Translation>
        );
    }
}

function mapStateToProps(state: IProps): IProps {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {})(NavBar);
