import {IOption} from "../Interfaces/Common";

export enum Roles {
  god = 1,
  admin = 2,
  manager = 3,
  user = 4,
  signedin = 99
}

export const options: Array<IOption> = [
  {key: 1, title: "Superadmin"},
  {key: 2, title: "Admin"},
  {key: 3, title: "Manager"},
  {key: 4, title: "Agent"}
];

export const title: Array<any> = [
  {key: 1, title: "Superadmin"},
  {key: 2, title: "Admin"},
  {key: 3, title: "Manager"},
  {key: 4, title: "Agent"}
];
