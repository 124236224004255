import React from "react";
import {useFormik} from "formik";
import {INewPortingRequest} from "../../../Interfaces/Common";
import Form from "../../Form";
import Translation, {i18n} from "../../../i18n";
import {alertError} from "../../../Store";
import {
  PORTING_APPLICABLE_COUNTRY,
  PORTING_NUMBER_TYPE
} from "./Constants/PortingRequestFormConstants";
import portabilityCheck from "../../../Fetch/PortablityCheck";
import {formKeyToFormLabel} from "./PortingRequestUtilityFunction";
type TProps = {
  closeForm: () => void;
  setUserDetailForm: Function;
  showUserDetailForm: Boolean;
  portingRequest: INewPortingRequest;
  setPortingRequestCheckData: Function;
};
export const PortingRequestCheckForm: React.FC<TProps> = React.memo(
  ({
    closeForm,
    showUserDetailForm,
    portingRequest,
    setUserDetailForm,
    setPortingRequestCheckData
  }) => {
    const {handleChange, handleSubmit, values} = useFormik({
      initialValues: {
        country: "",
        number_type: "",
        service_provider: "",
        area_code: "",
        from_number: "",
        to_number: ""
      },
      onSubmit: (values) => {
        if (!checkValidation(values)) {
          return;
        }
        checkPortability(values);
      }
    });
    const countryCode = getCountryCode(values.country);
    const areaCode = values.area_code;
    const formFields = [
      {
        fieldName: "country",
        fieldTitle: "Country",
        fieldValue: values.country,
        type: "select",
        placeholder: "Select Country"
      },
      {
        fieldName: "number_type",
        fieldTitle: "Number Type",
        fieldValue: values.number_type,
        type: "select",
        placeholder: "Your number"
      },
      {
        fieldName: "area_code",
        fieldTitle: "Area Code",
        fieldValue: values.area_code,
        type: "text",
        placeholder: "Area code"
      },
      {
        fieldName: "service_provider",
        fieldTitle: "Service provider",
        fieldValue: values.service_provider,
        type: "text",
        placeholder: "Current service provider"
      },
      {
        fieldName: "from_number",
        fieldTitle: "From Number Range",
        fieldValue: values.from_number,
        type: "text",
        placeholder: "From Number"
      },
      {
        fieldName: "to_number",
        fieldTitle: "To Number Range",
        fieldValue: values.to_number,
        type: "text",
        placeholder: "To Number"
      }
    ];
    return (
      <Translation>
        {(t) => (
          <Form
            onCancel={closeForm}
            onSubmit={handleSubmit}
            stepperFooter={!showUserDetailForm}
          >
            <h2>
              {t(portingRequest && portingRequest.id ? "EDIT" : "ADD")}{" "}
              {t("Porting Request")}
            </h2>
            <div className="portingFormLayout">
              {formFields.map((field) =>
                field.type !== "select" ? (
                  <div className="fieldStyle">
                    <div className="fieldTitle">
                      {field.fieldTitle}
                      {(field.fieldName == "from_number" ||
                        field.fieldName == "to_number") && (
                        <span> {countryCode + " " + areaCode}</span>
                      )}
                    </div>
                    <div className="fieldValue">
                      <input
                        type="text"
                        name={field.fieldName}
                        value={field.fieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="fieldStyle">
                    <div className="fieldTitle">{field.fieldTitle}</div>
                    <div className="fieldValue">
                      <select
                        placeholder={field.placeholder}
                        name={field.fieldName}
                        value={field.fieldValue}
                        onChange={handleChange}
                      >
                        {field.fieldName === "country"
                          ? PORTING_APPLICABLE_COUNTRY.map(
                              ({displayValue, value}) => (
                                <option value={value}>{displayValue}</option>
                              )
                            )
                          : PORTING_NUMBER_TYPE.map(({displayValue, value}) => (
                              <option value={value}>{displayValue}</option>
                            ))}
                      </select>
                    </div>
                  </div>
                )
              )}
            </div>
          </Form>
        )}
      </Translation>
    );

    function checkValidation(values) {
      let isFormValid = true;
      const formKeys = Object.keys(values);
      for (let counter = 0; counter < formKeys.length; counter++) {
        if (
          values[formKeys[counter]] === "" &&
          formKeys[counter] !== "to_number"
        ) {
          isFormValid = false;
          alertError(formKeyToFormLabel(formKeys, counter) + " is required");
          break;
        }
        if (values.to_number) {
          isFormValid = false;
          const fromValues = values.from_number.split("");
          const fromNum = Number(fromValues[fromValues.length - 1]);
          const toValues = values.to_number.split("");
          const toNum = Number(toValues[toValues.length - 1]);
          if (fromNum === 0 && toNum === 9) {
            isFormValid = true;
            break;
          }
          alertError(i18n.t("INVALID_RANGE"));
          break;
        }
      }
      return isFormValid;
    }

    async function checkPortability(formValues) {
      const responseFormValue: any = await portabilityCheck(
        countryCode + formValues.area_code + formValues.from_number
      );
      if (formValues.to_number) {
        const responseToValue: any = await portabilityCheck(
          countryCode + formValues.area_code + formValues.to_number
        );
        if (
          responseFormValue.data.data.portable &&
          responseToValue.data.data.portable
        ) {
          setPortingRequestCheckData({
            ...formValues,
            country_code: getCountryCode(formValues.country)
          });
          setUserDetailForm(true);
        }
      } else {
        if (responseFormValue.data.data.portable) {
          setPortingRequestCheckData({
            ...formValues,
            country_code: getCountryCode(formValues.country)
          });
          setUserDetailForm(true);
        }
      }
    }
  }
);
const getCountryCode = (country: string): string => {
  return country.substring(country.indexOf("(") + 1, country.indexOf(")"));
};
