import {Action, DispatchEventLoading} from "../Interfaces/Redux";
import {HOST, NumbersReq} from "../Store";

let store: any;
export const loadingFunc: DispatchEventLoading = (status, uuid) => {
  store.dispatch({
    payload: {
      uuid
    },
    type: status ? Action.LOADING : Action.LOADING_DONE
  });
};
/**
 * savePorting request.
 * @returns fetch response
 */
export async function portingRequestSave(data: any): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}porting-request/add-porting`,
    data,
    "post"
  );
  return resp;
}

export async function fetchPortingRequest(
  organizationId: number
): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}porting-request/fetch-porting-requests/${organizationId}`,
    null,
    "get"
  );
  return resp;
}

export async function portingRequestUpdate(data: any): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}porting-request/${data.id}/update`,
    data,
    "put"
  );
  return resp;
}

export async function deletePortingRequest(id: any): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}porting-request/${id}/delete`,
    null,
    "delete"
  );
  return resp;
}
