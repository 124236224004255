import React from "react";
import ReactDOM from "react-dom";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import reducers from "./Reducers";
import "./i18n";

import Header from "./Components/Header";
import Alert from "./Components/Alert";
import Loading from "./Components/Loading";
import ErrorBoundary from "./Containers/ErrorBoundary";
import Body from "./Containers/Body";
import Translation from "./Helpers/Translation";
// import {AUTH_LOCAL_STORAGE_KEY} from "./Helpers/Constants";

import {setStore} from "./Store";

import "./Style/Sass/Global.scss";
import "./Style/Sass/Mobile.scss";
import "./Style/Sass/Utilities.scss";
import NavBar from "./Containers/NavBar";


const store: any = createStore(reducers, applyMiddleware(thunk));

setStore(store);

// development auth token
/*if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTH_LOCALSTORAGE) {
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, process.env.REACT_APP_AUTH_LOCALSTORAGE)
}*/

ReactDOM.render(
  <Translation>
    {(t) => (
      <ErrorBoundary>
        <Provider store={store}>
          <Body>
            <Header />
            <NavBar />
            <Loading />
            <Alert />
          </Body>
        </Provider>
      </ErrorBoundary>
    )}
  </Translation>,
  document.getElementById("root")
);
