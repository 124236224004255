import React from "react";
import {IOrganization} from "../../Interfaces/Common";
import {organizationOptions} from "../../Helpers/Common";

interface IProps {
  orgNr: number;
  organizations: IOrganization[];
  onChange: (orgNr: number) => void;
  className?: string;
}

/**
 * Select element to select organization.
 * @returns React component
 */
export default function SelectOrganization(props: IProps): JSX.Element {
  return (
    <select
      className={`select field-border select-org ${props.className || ""}`}
      onChange={e => {
        const orgNr: number = parseInt(e.target.value, 10) || null;
        props.onChange(orgNr);
      }}
      value={props.orgNr || undefined}
    >
      {
        props.organizations.length > 0 && organizationOptions(props.organizations).map(org => (
          <option key={org.key} value={org.key}>{org.title}</option>
        ))
      }
    </select>
  );
}
