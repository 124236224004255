import React from "react";
import {connect} from "react-redux";
import NewNotification from "../Components/Form/NewNotification";
import {Roles} from "../Helpers/Roles";
import Translation from "../i18n";
import {IUser} from "../Interfaces/Common";
import {IAuthObject} from "../Interfaces/Redux";
import {getAllNotification, removeNotifications,searchNotifications} from "../Store";
import INotification from "connectel-shared/interfaces/Notifications";
import "../Style/Sass/Notifications.scss";
import Icon from "../Components/Icon";
import {options as RoleOptions} from "../Helpers/Roles";

interface IProps {
  auth: IAuthObject;
  users: IUser[];
}

interface IState {
  openNewNotificationPopup: boolean;
  notificationsList: Array<INotification>;
  selectedNotifications: Array<number>;
  searchText: string;
  isSelected: boolean;
}

class CreateNotification extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openNewNotificationPopup: false,
      notificationsList: [],
      selectedNotifications: [],
      searchText: "",
      isSelected:false
    };
  }

  fetchAllNotifications = async () => {
    const response = await getAllNotification();
    this.setState({notificationsList: response.data});
};

  componentDidMount(): void {
    this.fetchAllNotifications();
  }


 handleSelectAllClick = (event) => {
    if (event.target.checked) {
        const ids = this.state.notificationsList.map(({id}) =>
            id
        );
        this.setState({selectedNotifications: ids, isSelected: true});
        return;
    }
    this.setState({selectedNotifications: [],searchText:"",isSelected: false});
};

handleSearch = async () => {
  const response = await searchNotifications({searchText:this.state.searchText})
  this.setState({notificationsList: response.data}); 
};

handlerDeleteAllClick = async () => {
    try {
        const ids = this.state.selectedNotifications;
        await removeNotifications(ids);
        await this.fetchAllNotifications();
        this.setState({selectedNotifications:[],isSelected: false,searchText: ""})
    } catch (err) {}
};

onChange = (event: { target: { checked: boolean; }; },id: number) =>{
    const prevState = [];
    if (event.target.checked) {
        prevState.push(
            ...this.state
                .selectedNotifications,
            id
        );
    } else {
        prevState.push(
            ...this.state.selectedNotifications?.filter(
                (value) =>
                    value!==
                    id
            )
        );
    }
    this.setState({
        selectedNotifications:
            prevState
    });
}
  public render(): React.ReactNode {
    if(this.props.auth.user.role_id !== Roles.god) return <></>

    return (
      <Translation>
        {(t) => (
          <>
            {this.state.openNewNotificationPopup && (
              <NewNotification
                onCloseForm={() => {
                  this.setState({openNewNotificationPopup: false});
                  this.fetchAllNotifications();
                }}
                users={this.props.users}
              />
            )}
            <h1>
            {t("CREATED")} {t("NOTIFICATION")}
              {this.props.auth.user.role_id === Roles.god && (
                <Icon
                className="button notification-button"
                icon="add"
                onClick={() => {
                      this.setState({openNewNotificationPopup: true});
                    }}
                tooltip={{
                  id: "add-report",
                  message: t("ADD_NOTIFICATION"),
                  place: "left",
                }}
              />
              )}
            </h1>
            <div className="search-box">
                <input
                    type="checkbox"
                    disabled={this.state.notificationsList.length===0}
                    className="checkbox-main"
                    checked={this.state.isSelected}
                    onClick={(event: any) => {
                        this.handleSelectAllClick(event);
                    }}
                />
                <input
                    placeholder="Search..."
                    className="search-box-input"
                    type="search"
                    value={this.state.searchText}
                    onChange={(event: any) => this.setState({searchText:event.target.value})}
                />
                 <Icon
                    className={`button notification-button`}
                    onClick={() => this.handleSearch()}
                    icon="search"
                />
                <Icon
                    className={`button notification-button ${
                        this.state.selectedNotifications.length ===
                        0
                            ? "notification-disabled"
                            : ""
                    }`}
                    onClick={() => this.handlerDeleteAllClick()}
                    tooltip={{
                        id: "notification-delete",
                        message: t("DELETE_SELECTED_NOTIFICATION"),
                        type: "warning",
                        place: "left"
                    }}
                    icon="delete"
                />
            </div>
            <div>
              {this.state.notificationsList?.map((notification) => {
                const createdBy =
                  this.props.users.find((u) => u.id === notification.createdBy)
                    ?.name || "-";
                return (
                  <>
                  <div key={notification.id} className="notification">
                    <h3>
                    <input
                        type="checkbox"
                        className="individual-checkbox"
                        checked={
                            this.state.selectedNotifications?.includes(
                                notification.id
                            )
                        }
                        onChange={(event: any) => this.onChange(event,notification.id)}
                    />
                      {notification.title}
                      <Icon
                        icon="delete"
                        onClick={async () => {
                          const res = await removeNotifications(
                           [notification.id]
                          );
                          if (res.status === 200) {
                            this.fetchAllNotifications();
                          }
                        }}
                      />
                    </h3>
                    <hr></hr>
                    <p>{notification.message}</p>
                    <h6>
                      <span>Created By:</span> {createdBy}{" "}
                      <span className="delimeter">|</span>{" "}
                      <span>Receivers:</span>
                      {notification.receivers.map((receiver) => {
                          const user = this.props.users.find(
                            (u) => u.id === receiver.userId
                          );
                          if (!user) return "";
                          const role = RoleOptions.find(
                              (role) => role.key == user?.role_id
                            )?.title || "";

                          return `${user?.name} (${role})`;
                        })

                        .join(", ") || "-"}
                      <span className="delimeter">|</span>
                      <span>Publised At:</span> {notification.createdAt}
                    </h6>
                      <details>
                        <summary
                          style={{float: "right", marginTop: "-1%"}}
                        ></summary>
                        <div  style={{paddingTop: '20px'}}>
                          <table className="basic-table" style={{width: "100%"}}>
                            <thead>
                              <tr>
                                <td>Name</td>
                                <td>Time</td>
                                <td>Read</td>
                              </tr>
                            </thead>
                            <tbody>
                              {notification.receivers.map((receiver) => {
                                const user = this.props.users.find(
                                  (u) => u.id === receiver.userId
                                );
                                const roles = RoleOptions.find(
                                  (role) => role.key == user?.role_id
                                )?.title || "";

                                return (
                                  <tr>
                                    <td>{`${user?.name}`}{`${"(" + roles + ")"}`}</td>
                                    <td>{receiver.read ? receiver.read_time : "null"}</td>
                                    <td><span className={receiver.read ? "icon icon-mark_as_unread": "icon icon-email"} style={{color: `${receiver.read ? "green": "grey"}`}}></span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </details>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </Translation>
    );
  }
}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    users: state.users
  };
}

export default connect(mapStateToProps, {})(CreateNotification);
