import React, {Component} from "react";
import {HTMLevent} from "../Interfaces/Common";
import Translation from "../Helpers/Translation";
import Tooltip, {IProps as ITooltip} from "../Helpers/Tooltip";

import "../Assets/Icomoon/style.css";

export default class Icon extends Component<{
  icon: string;
  onMouseOver?: HTMLevent;
  onMouseLeave?: HTMLevent;
  style?: React.CSSProperties;
  size?: number;
  title?: string;
  tooltip?: ITooltip;
  onClick?: HTMLevent;
  className?: string;
}> {
  public render(): React.ReactNode {
    if (this.props.tooltip)
      return <Translation>
        {t =>
          <span className={`icon icon-${this.props.icon}${this.props.onClick ? " clickable" : ""}${this.props.className ? ` ${this.props.className}` : ""}`}
                style={this.props.style}
                onClick={this.props.onClick}
                data-tip data-for={this.props.tooltip.id}>
            {this.props.tooltip &&
                <Tooltip {...this.props.tooltip} />
            }
          </span>
        }
      </Translation>;
    return <span className={`icon icon-${this.props.icon} ${this.props.onClick ? " clickable" : ""}${this.props.className ? ` ${this.props.className}` : ""}`}
                 title={this.props.title}
                 style={this.props.style}
                 onClick={this.props.onClick}
                 onMouseOver={this.props.onMouseOver || null}
                 onMouseLeave={this.props.onMouseLeave || null}
    />;
  }
}
