import React from "react";
import {useFormik} from "formik";
import Popup from "../../Popup";
import "../../../Style/Sass/PortingRequestForm.scss";
import {INewPortingRequest} from "../../../Interfaces/Common";
import Translation from "../../../i18n";
import Form from "../../Form";
import {PORTING_STATUS} from "./Constants/PortingRequestFormConstants";
import {portingRequestUpdate} from "../../../Fetch/PortingRequests";

type TProps = {
  closeAddPortingForm: () => void;
  portingRequest: INewPortingRequest;
  reloadList: Function;
};

export const PortingEditRequest: React.FC<TProps> = React.memo(
  ({closeAddPortingForm, portingRequest, reloadList}) => {
    const {handleChange, handleSubmit, values} = useFormik({
      initialValues: {
        status: portingRequest.status,
        comments: portingRequest.comments
      },
      onSubmit: async (values) => {
        await portingRequestUpdate({...portingRequest, ...values});
        reloadList(true);
        closeAddPortingForm();
      }
    });
    const closeForm = () => {
      closeAddPortingForm();
    };

    const formFields = [
      {
        fieldName: "status",
        fieldTitle: "Status",
        fieldValue: values.status,
        type: "select",
        placeholder: "Select Status"
      },
      {
        fieldName: "comments",
        fieldTitle: "Comments",
        fieldValue: values.comments,
        type: "text",
        placeholder: "Please enter comments"
      }
    ];
    return (
      <Popup onCloseForm={closeForm}>
        <Translation>
          {(t) => (
            <Form onCancel={closeForm} onSubmit={handleSubmit}>
              <h2>
                {t("Edit")} {t("Porting Request")}
              </h2>
              <div className="portingFormLayout">
                {formFields.map((field) =>
                  field.type !== "select" ? (
                    <div className="fieldStyle">
                      <div className="fieldTitle">{field.fieldTitle}</div>
                      <div className="fieldValue">
                        <textarea
                          name={field.fieldName}
                          value={field.fieldValue}
                          onChange={handleChange}
                          placeholder={field.placeholder}
                          rows={4}
                          cols={50}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="fieldStyle">
                      <div className="fieldTitle">{field.fieldTitle}</div>
                      <div className="fieldValue">
                        <select
                          placeholder={field.placeholder}
                          name={field.fieldName}
                          value={field.fieldValue}
                          onChange={handleChange}
                        >
                          {field.fieldName === "status" &&
                            PORTING_STATUS.map(({displayValue, value}) => (
                              <option value={value}>{displayValue}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )
                )}
              </div>
            </Form>
          )}
        </Translation>
      </Popup>
    );
  }
);
