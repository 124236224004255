import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import Translation from "../Helpers/Translation";

export interface IProps {
  id: string;
  message: string;
  type?: ReactTooltip.Type;
  place?: ReactTooltip.Placement;
}

export default class Tooltip extends Component<IProps> {
  public render(): React.ReactNode {
    return <Translation>
      {t => <ReactTooltip key={this.props.id} id={this.props.id} type={this.props.type || "info"} place={this.props.place || "top"} effect="solid">
        <span>{t(this.props.message)}</span>
      </ReactTooltip>
      }
    </Translation>;
  }
}
