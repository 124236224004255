import React from "react";
import {connect} from "react-redux";
import Translation, {i18n} from "../i18n";
import DidTableRow, {DidTableHead} from "../Components/DidObject";
import purchaseItem from "../Fetch/Purchase";
import {clearCache} from "../Fetch/VoiceUris";
import {fetchRestrictions} from "../Fetch/Inventory";
import {alertInfo, alertConfirm, removeFromCart, editCartItem} from "../Store";
import {
  IDidCart,
  IDidCartItem,
  IOrganization,
  IRestriction
} from "../Interfaces/Common";
import "../Style/Sass/Checkout.scss";

interface IAgreement extends IRestriction {
  countryCode: string;
}

interface IProps {
  match: any;
  history: any;
  didCart: IDidCart;
  organizations: IOrganization[];
}

interface IState {
  agreements: IAgreement[];
  acceptedAgreements: boolean;
}

/**
 * Checkout view for purchasing numbers in cart.
 */
class Checkout extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      acceptedAgreements: false,
      agreements: []
    };

    this.fetchAgreements();
  }

  public componentDidUpdate(prevProps: IProps): void {
    if (prevProps.didCart !== this.props.didCart) {
      this.fetchAgreements();
    }
  }

  public render(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <>
            <h1>{t("CART")}</h1>
            <article>
              <table className="list">
                <DidTableHead orgNrChangeable={true} />
                <tbody>
                  {this.props.didCart.items.map((item) => (
                    <DidTableRow
                      key={item.id}
                      id={item.id}
                      didObject={item.didObject}
                      initialQuantity={item.quantity}
                      initialVoiceUriId={item.voiceUriId}
                      orgNr={item.orgNr}
                      organizations={this.props.organizations}
                      removeFromCart={(id) => {
                        removeFromCart(id);
                      }}
                      onItemChange={(update) => {
                        editCartItem(item.id, update);
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </article>

            <div className="p-2">
              <div className="flex">
                <div>
                  <p>
                    {t("TOTAL_SETUP_PRICE")}:{" "}
                    {Object.values(this.props.didCart.items).reduce(
                      (sum, item) =>
                        sum +
                        parseInt(item.didObject.setupPrice, 10) * item.quantity,
                      0
                    )} {this.props.didCart.items.length > 0 ? this.props.didCart.items[0].didObject.currency : ""}
                  </p>
                  <p className="mt-2">
                    {t("TOTAL_MONTHLY_PRICE")}:{" "}
                    {Object.values(this.props.didCart.items).reduce(
                      (sum, item) =>
                        sum +
                        parseInt(item.didObject.monthlyPrice, 10) * item.quantity,
                      0
                    )} {this.props.didCart.items.length > 0 ? this.props.didCart.items[0].didObject.currency : ""}
                  </p>
                </div>
                <div className="flex-1 mx-8">
                  {/*<h2 className="text-lg">Agreements</h2>*/}
                  <h2 className="text-xl">{t("AGREEMENTS")}</h2>

                  {this.state.agreements && (
                    <div>
                      {this.state.agreements.map((agreement) => (
                        <div
                          key={agreement.restrictionMessage}
                          className="flex items-center agreement my-1 p-1"
                        >
                          <p className="ml-1">{agreement.restrictionMessage}</p>
                        </div>
                      ))}
                      <div className="flex items-center mt-2">
                        <input
                          className="checkbox"
                          type="checkbox"
                          checked={this.state.acceptedAgreements}
                          onChange={(e) =>
                            this.setState((state) => ({
                              acceptedAgreements: !state.acceptedAgreements
                            }))
                          }
                        />
                        <p className="ml-1 text-lg">{t("ACCEPT_AGREEMENTS")}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <button
                className="button mt-2"
                disabled={!this.state.acceptedAgreements}
                onClick={() => {
                  for (let i: number = 0; i < this.props.didCart.items.length; i++) {
                    const item: IDidCartItem = this.props.didCart.items[i];
                    if (!item.voiceUriId) {
                      return alertInfo(
                        i18n.t("ONE_OR_MORE_MISSING_VOICE_URI_ID")
                      );
                    } else if (!item.quantity) {
                      return alertInfo(i18n.t("ONE_OR_MORE_INVALID_QUANTITY"));
                    }
                  }

                  alertConfirm(t("CONFIRM_PURCHASE"), async (e) => {
                    try {
                      const respData: any[] = await Promise.all(
                        this.props.didCart.items.map(async (item) => {
                          const resp: any = await purchaseItem(item.orgNr, item);
                          // success
                          removeFromCart(item.id);
                          // new numbers, clear cache for this org
                          clearCache(item.orgNr);
                          return resp.data;
                        })
                      );
                      const dids: Array<{
                        type: string;
                        did: string;
                        voiceUri: string;
                        didPending?: boolean;
                      }> = respData.reduce(
                        (acc, data) => [
                          ...acc,
                          ...data.purchase.dids.map((d) => ({
                            ...d,
                            voiceUri: data.assigns[0].voiceUri
                          }))
                        ],
                        []
                      );
                      await alertInfo(<div className="text-left">
                        <p className="m-2">{i18n.t("PURCHASE_SUCCESSFUL")}. {i18n.t("ASSIGNED_NUMBERS")}:</p>
                        <table className="table mt-2">
                          <thead>
                            <tr>
                              <th>{i18n.t("VOICE_URI_ADDRESS")}</th>
                              <th>{i18n.t("DID_TYPE")}</th>
                              <th>{i18n.t("NUMBER")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {dids.map(did => (
                              <tr key={did.did}>
                                <td>{did.voiceUri}</td>
                                <td>{did.type}</td>
                                <td>{did.did}</td>
                                <td>{did.didPending ? i18n.t("PURCHASE_PENDING") : ""}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {dids.some(d => d.didPending) && <p className="m-2">{i18n.t("PURCHASE_PENDING_EXPLANATION")}</p>}
                      </div>);
                      this.props.history.push("/numbers");
                    } catch (err) {
                      console.log(err);
                    }
                  });
                }}
              >
                {t("PURCHASE_NUMBERS")}
              </button>
            </div>
          </>
        )}
      </Translation>
    );
  }

  /**
   * Fetches agreements and saves them to state.
   * @returns
   */
  private async fetchAgreements(): Promise<void> {
    // all unique country codes from the items currently in cart
    const params: {countryCode: string; orgNr: number}[] = this.props.didCart.items
      .map((item) => ({
        countryCode: item.didObject.countryCode,
        orgNr: item.orgNr
      }))
      .filter(
        (param, index, params) =>
          params.findIndex((p) => param.countryCode === p.countryCode) === index
      );

    // creating agreements for all the new country codes for which there are no agreements in state
    let newAgreements: IAgreement[] = [];
    await Promise.all(
      params.map(async (paramObject) => {
        const countryRestrictions: IRestriction[] = await fetchRestrictions(
          paramObject.orgNr,
          paramObject.countryCode
        );
        const countryAgreements: IAgreement[] = countryRestrictions.map(
          (restriction, index) => ({
            accepted: false,
            ...restriction,
            countryCode: paramObject.countryCode
          })
        );
        newAgreements = newAgreements.concat(countryAgreements);
      })
    );

    // filter our duplicates
    const agreements: IAgreement[] = this.state.agreements
      .concat(newAgreements)
      .filter(
        (agreement, index, agreements) =>
          newAgreements.find(
            (a) => a.restrictionMessage === agreement.restrictionMessage
          ) &&
          agreements.findIndex(
            (a) => a.restrictionMessage === agreement.restrictionMessage
          ) === index
      );

    this.setState({
      // add new agreements to the ones that are in state and should remain there
      agreements: agreements
    });
    return;
  }
}

function mapStateToProps(state: {
  didCart: IDidCart;
  organizations: IOrganization[];
}): {} {
  return {
    didCart: state.didCart,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(Checkout);
