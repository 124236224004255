import {Action, IReduxAction} from "../Interfaces/Redux";
import {IDidCartItem, IDidCart, IObject} from "../Interfaces/Common";
import {CART_KEY} from "../Helpers/Constants";
import { getItem, setItem } from "../cookies";

// import mockItems from "../mock/items";

type ItemId = string;

const savedCart: string = getItem(CART_KEY);

export default function DidInventoryReducer(
  state: IDidCart = savedCart ? JSON.parse(savedCart) : {items: []},
  action:
    | IReduxAction<IDidCartItem, Action.ADD_ITEM_TO_CART>
    | IReduxAction<ItemId, Action.REMOVE_ITEM_FROM_CART>
    | IReduxAction<{id: ItemId; edit: IObject}, Action.EDIT_CART_ITEM>
): IDidCart {
  const cartItems: IDidCartItem[] = state.items.slice();
  let newState: IDidCart;
  let updateIndex: number;
  let id: string;

  switch (action.type) {
    // adds a new item to cart
    // adds the quantity of the item already exists
    case Action.ADD_ITEM_TO_CART:
      const newItem: IDidCartItem = action.payload;
      updateIndex = cartItems.findIndex(
        (item) =>
          item.id === newItem.id ||
          (item.voiceUriId === newItem.voiceUriId &&
            item.didObject.didId === newItem.didObject.didId)
      );
      if (
        updateIndex !== -1 &&
        cartItems[updateIndex].voiceUriId === newItem.voiceUriId
      ) {
        const updateItem: IDidCartItem = cartItems[updateIndex];
        const quantity: number = newItem.quantity + updateItem.quantity;
        if (quantity > 0) {
          cartItems[updateIndex] = {
            ...updateItem,
            quantity
          };
        }
      } else {
        if (newItem.quantity > 0) {
          cartItems.push(newItem);
        }
      }
      newState = {
        ...state,
        items: cartItems
      };
      break;
    case Action.REMOVE_ITEM_FROM_CART:
      id = action.payload;
      newState = {
        ...state,
        items: cartItems.filter((item) => item.id !== id)
      };
      break;
    case Action.EDIT_CART_ITEM:
      const edit: IObject = action.payload.edit;
      id = action.payload.id;
      updateIndex = cartItems.findIndex((item) => item.id === id);
      if (updateIndex !== -1) {
        cartItems[updateIndex] = {...cartItems[updateIndex], ...edit};
      } else {
        throw new Error("Trying to update non-existing item");
      }
      newState = {
        ...state,
        items: cartItems
      };
      break;
    default:
      newState = state;
  }

  setItem(CART_KEY,JSON.stringify(newState));
  return newState;
}
