"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppScopeOptions = exports.AppScopes = exports.AppScope = void 0;
var AppScope;
(function (AppScope) {
    AppScope[AppScope["WidgetApi"] = 1] = "WidgetApi";
})(AppScope = exports.AppScope || (exports.AppScope = {}));
exports.AppScopes = [AppScope.WidgetApi];
exports.AppScopeOptions = [
    { key: AppScope.WidgetApi + "", title: "Widget Api" }
];
