import {Action} from "../Interfaces/Redux";
import {IAuthObject, IReduxAction} from "../Interfaces/Redux";

const defaultAuth: IAuthObject = {
    isAuth: false,
    nodes: [],
    token: "",
    tokens: null,
    user: {
        id: 0,
        name: "",
        role_id: 0,
        username: ""
    }
};

export default function AuthReducer(
    state: IAuthObject = defaultAuth,
    action: IReduxAction<IAuthObject, Action.TOKEN_RESP>
): IAuthObject {
    switch (action.type) {
        case Action.TOKEN_RESP:
            if (
                !action.payload.token ||
                action.payload.token === "unauthorized"
            )
                action.payload = defaultAuth;
            return {...state, ...action.payload};
        default:
            return state;
    }
}
export function parseJwt(token: string): any {
  let base64Url: string = token.split(".")[1];
  let base64: string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload: string = decodeURIComponent(atob(base64).split("").map((c: string) => {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));

  return JSON.parse(jsonPayload);
}
