import {Action, IReduxAction} from "../Interfaces/Redux";
import {ORG_NR_KEY} from "../Helpers/Constants";
import { getItem, removeItem, setItem } from "../cookies";

type OrgNr = number;

const savedOrgNr = getItem(ORG_NR_KEY);
export default function OrganizationReducer(
  state: OrgNr = savedOrgNr ? parseInt(savedOrgNr) : null,
  action:
    IReduxAction<number, Action.SELECT_ORGANIZATION>
): OrgNr {
  switch (action.type) {
    case Action.SELECT_ORGANIZATION:
      const orgNr = action.payload;
      if (typeof orgNr === "number") {
        setItem(ORG_NR_KEY, orgNr.toString());
      } else {
        removeItem(ORG_NR_KEY);
      }
      return orgNr;
    default:
      return state;
  }
}
