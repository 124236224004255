import {Action, IAlertObject, IAlertAction} from "../Interfaces/Redux";
import {removeAlert} from "../Store";

export default function AlertReducer(
  state: Array<IAlertObject> = [],
  action: IAlertAction
): Array<IAlertObject> {
  switch (action.type) {
    case Action.ALERT_ERROR:
    case Action.ALERT_CONFIRM:
    case Action.ALERT_INFO:
      const {onCancel, onConfirm, resolve} = action.payload;

      if (!onConfirm && !onCancel) {
        action.payload.onConfirm = async (e) => {
          removeAlert(action.payload.uuid);
          resolve(true);
          return true;
        };
      } else {
        if (typeof action.payload.onConfirm === "function") {
          action.payload.onConfirm = async (e) => {
            removeAlert(action.payload.uuid);
            onConfirm(e);
            resolve(true);
            return true;
          };
        }
        action.payload.onCancel = async (e) => {
          if (onCancel) {
            onCancel(e);
          }
          removeAlert(action.payload.uuid);
          resolve(false);
          return true;
        };
      }

      state = [...state, {type: action.type, ...action.payload}];
      break;
    case Action.REMOVE_ALERT:
      state = state.filter((s) => s.uuid !== action.payload.uuid);
      break;
    default:
      break;
  }
  return state;
}
