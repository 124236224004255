import React, {ChangeEvent, Component, ReactNode} from "react";

interface IProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export default class UploadFile extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e);
  };

  render(): ReactNode {
    return (
      <div>
        <input type="file" onChange={this.handleChange} 
        // accept=".png"
        />
      </div>
    );
  }
}
