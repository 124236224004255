import React from "react";
import Icon from "./Icon";
import "../Style/Sass/PaginatedFooter.scss";

interface IProps {
  pageNumber: number;
  isNextPageAvailable: boolean;
  onPreviousClick: PaginatedFooterAction;
  onNextClick: PaginatedFooterAction;
}
interface PaginatedFooterAction {
  (): void;
}

const PaginatedFooter = ({
  pageNumber,
  isNextPageAvailable,
  onPreviousClick,
  onNextClick
}: IProps) => {
  return (
    <div id="paginated-footer" className="col-md-12 text-center">
      <Icon
        icon="circle-left"
        className={`button left-arrow  ${pageNumber === 1 ? "disabled" : ""}`}
        onClick={() => onPreviousClick()}
      />
      <span>{pageNumber}</span>
      <Icon
        icon="circle-right"
        className={`button right-arrow ${isNextPageAvailable ? "" : "disabled"}`}
        onClick={() => onNextClick()}
      />
    </div>
  );
};

export default PaginatedFooter;
