import {combineReducers} from "redux";
import AuthReducer from "./Auth";
import AlertReducer from "./Alert";
import LoadingReducer from "./Loading";
import ConfigReducer from "./Config";
import NodesReducer from "./Nodes";
import UsersReducer from "./Users";
import DidCartReducer from "./DidCart";
import OrganizationReducer from "./Organization";

export default combineReducers({
  alerts: AlertReducer,
  auth: AuthReducer,
  config: ConfigReducer,
  loading: LoadingReducer,
  organizations: NodesReducer,
  users: UsersReducer,
  didCart: DidCartReducer,
  orgNr: OrganizationReducer
});
