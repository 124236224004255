import React, {Component} from "react";
import {INewNode, HTMLevent, HTMLElementEventHandler, IOrganization} from "../../Interfaces/Common";
import Translation from "../../i18n";
import cloneDeep from "clone-deep";
import Popup from "../../Components/Popup";
import Form from "../../Components/Form";
import {Text, Select, Switch} from "./Element";
import {connect} from "react-redux";
import {alertConfirm, alertError} from "../../Store";
import {checkImageValidityByUrl, organizationOptions} from "../../Helpers/Common";
import {Roles} from "../../Helpers/Roles";
import {IAuthObject} from "../../Interfaces/Redux";

interface IProps {
  node: INewNode;
  onCloseForm: HTMLevent;
  onSave: Function;
  organizations: Array<IOrganization>;
  auth: IAuthObject;
}

interface IState {
  node: IProps["node"];
  logoUrlValidationMessage: string;
}

class Node extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      node: cloneDeep(this.props.node),
      logoUrlValidationMessage: "Validate"
    };
  }

  public render(): React.ReactNode {
    return <Translation>{
      t => <>
        <Popup onCloseForm={this.props.onCloseForm}>
          <Form onSubmit={this.onSave} onCancel={this.props.onCloseForm}>
            <h2>{t(this.props.node.id ? "EDIT" : "ADD")} {t("NODE")}</h2>

            <table style={{width: "100%"}}>
              <tbody>
              {this.props.auth.user.role_id === Roles.god && <>
                <tr>
                  <th style={{width: "30%"}}>
                    {t("NAME")}
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.node.name} name="name" onChange={this.onChange}/>
                  </td>
                </tr>

                <tr>
                  <th>
                    {t("HOSTNAME")}
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.node.hostname} name="hostname" placeholder="https://hostname.connectel.io/"
                          onChange={this.onChange}/>
                  </td>
                </tr>

                <tr>
                  <th>
                    {t("MOTION_HOSTNAME")} <span className="sup">1</span>
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.node.motion_host} name="motion_host"
                          placeholder="https://hostname.connectel.io/" onChange={this.onChange}/>
                  </td>
                </tr>

                <tr>
                  <th>
                    {t("SURVEY")}
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.node.eviexa} name="eviexa" onChange={this.onChange}/>
                  </td>
                </tr>

                {!this.props.node.id &&
                <tr>
                  <th>
                    {t("ORGANIZATION")}
                  </th>
                  <td className="nopadding">
                    <Select selected={this.state.node.organization_id}
                            options={organizationOptions(this.props.organizations)} name="organization_id"
                            onChange={this.onChange}/>
                  </td>
                </tr>}

                <tr>
                  <th>
                    {t("ACTIVE")}
                  </th>
                  <td className="nopadding">
                    <Switch value={this.state.node.active} name="active" onChange={this.onChange}/>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul className="helpers">
                      <li><span className="sup">1</span> {t("LEAVE_EMPTY_MOTION_HOST")}</li>
                    </ul>
                  </td>
                </tr>
              </>
              }

              {this.props.auth.user.role_id <= Roles.admin && <>
                <tr>
                  <th>
                    {t("INSIGHTS_PSEUDONYMIZE_AGENTS")}
                  </th>
                  <td className="nopadding">
                    <Switch value={this.state.node.insights_pseudonymize_agents || false}
                            name="insights_pseudonymize_agents" onChange={this.onChange}/>
                  </td>
                </tr>
                <tr>
                  <th>
                    {t("SMS")}
                  </th>
                  <td className="nopadding">
                    <Switch value={this.state.node.sms_pack} name="sms_pack" onChange={this.onChange}/>
                    {this.state.node.sms_pack &&
                    <>
                      <input type="number" value="500" disabled />
                      <span></span>
                    </>
                    }
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul className="helpers">
                      <li><span className="sup">1</span> {t("LEAVE_EMPTY_MOTION_HOST")}</li>
                    </ul>
                  </td>
                </tr>
                { [Roles.god,Roles.admin].includes(this.props.auth.user.role_id)   &&
                <tr>
                  <th>
                    {t("LOGO")} {t("URL")}
                  </th>
                  <td className="nopadding">
                    <Text value={this.state.node.logo_url} name="logo_url" onChange={this.onChange} placeholder="Ex: https://<domain>.<ext>/<path>"/>
                    <div className="logo-validation">
                      <span onClick={this.validateUrl} className="logo-validation-message">{this.state.logoUrlValidationMessage}</span>
                    </div>
                  </td>
                </tr>
              }
              </>}

              </tbody>
            </table>
          </Form>
        </Popup>
      </>
    }</Translation>;
  }

  private onSave: HTMLevent = (e) => {
    if (!this.state.node.name)
      return alertError(`Name is required!`);
    if (!this.state.node.name)
      return alertError(`Hostname is required!`);
    if (!this.state.node.hostname)
      return alertError(`Parent is required!`);
    this.props.onSave(this.state.node);
  }

  private onChange: HTMLElementEventHandler = (key: string, value: any) => {
    let node: IState["node"] = this.state.node;
    node[key] = value;
    switch(key) {
      case "sms_pack":
        if(value === true) {
          alertConfirm("By enabling SMS you will get access to SMS alarms/notifications in Skyview and you accept the cost of 2000 SEK/month (500 text messages are included each month).", async (e)=> true, ()=> {this.setState({node: {...this.state.node, sms_pack: false}})})
        }
        break;
      case "logo_url":
        this.setState({
          logoUrlValidationMessage: "Validate"
        })
        break;
    }
    this.setState({node});
  }

  private validateUrl = (e) => {
    e.preventDefault();
    this.setState({
      logoUrlValidationMessage: "Validating..."
    })
    const url = this.state.node.logo_url
    checkImageValidityByUrl(url).then((isValid)=>{
      this.setState({
        logoUrlValidationMessage: isValid ? "Valid image": "Invalid image"
      })
    });
  }

  /*
  private organizationOptions(): Array<IOption> {
    let flatten: Array<IOption> = [{key: "", title: i18n.t("SELECT_ORGANIZATION")}];
    function ls(organizations: Array<IOrganization>, parent: Array<string> = []): any {
      organizations.forEach(org => {
        flatten.push({
          key: org.id,
          title: `${[...parent, org.name].join(" - ")}`
        });
        if ((org.organizations || []).length)
          ls(org.organizations, [...parent, org.name]);
      });
    }
    ls(this.props.organizations);
    return flatten;
  }
  */

}

function mapStateToProps(state: { organizations: Array<IOrganization>, auth: IAuthObject }): { organizations: Array<IOrganization>, auth: IAuthObject } {
  return {
    auth: state.auth,
    organizations: state.organizations
  };
}

export default connect(mapStateToProps, {})(Node);
