import React, {Component} from "react";
import Icon from "../../Icon";
import Tooltip from "../../../Helpers/Tooltip";
import Translation from "../../../Helpers/Translation";
import {connect} from "react-redux";

type IOption = {
  title: string;
  key: any;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  icon?: React.ReactNode;
};

interface IProps {
  options: Array<IOption>;
  selected: Array<IOption>;
  onChange: (selected: IOption[]) => void;

  removable?: boolean;
  sortable?: boolean;
  onOptionEdit?: Function;
  onHover?: Function;
  onClickSelected?: Function;
  ukey?: string;
  multiple: boolean;
  width?: number;
}

interface IState {
  draggedItemIndex: number;
  draggedItemDest: number;
  search: string;
  editable: boolean;
  sortable: boolean;
  showOptions: boolean;
}

class Typeahead extends Component<IProps, IState> {

  private searchInput: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      draggedItemDest: null,
      draggedItemIndex: null,
      editable: !!props.onOptionEdit,
      search: "",
      showOptions: false,
      sortable: !!this.props.sortable
    };
  }

  handleClickOutside = (event:any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({ showOptions: false, search: "" });
    }
  }

  public render(): React.ReactNode {
    const ukey: string = this.props.ukey || "1";

    let style: React.CSSProperties = {};
    if (this.props.width)
      style.width = this.props.width;

    return <Translation>
      {t => <div className="typeahead" style={style} onBlur={this.handleClickOutside}>

        <div className="focus-area" onClick={e => this.searchInput.focus()}></div>

        {
          this.props.selected.map((it: IOption, i) => {
            return <Tooltip id={ukey + "-" + i} key={ukey + "-" + i} message={it.title}/>;
          })
        }

        <div className="selected" onDrop={e => e.preventDefault()} onDragOver={e => e.preventDefault()}>
          {
            this.getSelected().map((it: IOption, i) => {
              const styleBG: React.CSSProperties = {};
              const styleTXT: React.CSSProperties = {};
              if (it.color)
                styleTXT.color = it.color;
              if (it.backgroundColor)
                styleBG.backgroundColor = it.backgroundColor;
              if (it.borderColor)
                styleBG.borderColor = it.borderColor;

              if (!styleTXT.color && styleBG.backgroundColor) {
                styleTXT.color = styleBG.backgroundColor;
                styleTXT.filter = "invert(100%)";
              }

              return <>
                {this.state.draggedItemDest === i && this.state.draggedItemIndex > i &&
                  <div className="dropArea">{t("DROP_HERE")}</div>
                }
                <div
                  className={`option`}
                  draggable={true}
                  key={it.key}
                  onClick={() => this.props.onClickSelected ? this.props.onClickSelected(it, i) : null}
                  onDragOver={() => this.onDragOver(i)}
                  style={styleBG}
                  onMouseOver={e => this.props.onHover && this.props.onHover(it, this.props.ukey)}
                  onMouseOut={e => this.props.onHover && this.props.onHover(null)}
                >
                  <span className="txt" style={styleTXT}>{it.title}</span>
                  {this.state.editable && <Icon icon="edit" onClick={() => this.props.onOptionEdit(it)}/>}
                  {this.state.sortable &&
                    <div
                      draggable={true}
                      onDragStart={e => this.onDragStart(e, i)}
                      onDragEnd={this.onDragEnd}
                    ><Icon icon="move"/></div>}
                  {(
                    !this.props.hasOwnProperty("removable") || (this.props.hasOwnProperty("removable") && this.props.removable)) && <Icon icon="close" onClick={() => this.removeOption(it)}/>}

                </div>
                {this.state.draggedItemDest === i && this.state.draggedItemIndex < i && <div className="dropArea">{t("DROP_HERE")}</div> }
              </>;
            })
          }
          <input
            value={this.state.search}
            placeholder={`${t("SEARCH")}...`}
            ref={(e) => {
              this.searchInput = e;
            }}
            style={{width: ((this.state.search.length * 10) + 18) + "px"}}
            onChange={e => this.setState({search: e.target.value})}
            onFocus={e => this.setState({showOptions: true})}
          />
        </div>

        {this.state.showOptions &&
          <div className="options">
            {
              this.options().map((it, index) => {
                return <div
                  className="option"
                  tabIndex={index-1}
                  onClick={e => this.addOption(it)}
                  title={it.title}
                  onMouseOver={e => this.props.onHover && this.props.onHover(it)}
                  onMouseOut={e => this.props.onHover && this.props.onHover(null)}
                  key={it.key}
                >
                  {it.icon}{it.title}
                </div>;
              })
            }
          </div>
        }

      </div>
      }
    </Translation>;
  }

  // ----- Dragging -----

  private onDragStart = (e: any, draggedItemIndex: number) => {
    this.setState({draggedItemIndex});
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text", e.target.id);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  }
  private onDragEnd = (e: any) => {
    if (this.state.draggedItemDest === null) {
      return;
    }

    // let selecIted: Array<number> = this.props.selected.map((_, i) => i);

    const selected = this.props.selected.slice();

    let [movedItem] = selected.splice(this.state.draggedItemIndex, 1);
    selected.splice(this.state.draggedItemDest, 0, movedItem);

    if (selected.every((e, i) => e === this.props.selected[i])) {
      return;
    }

    this.props.onChange(selected);
    this.setState({
      draggedItemDest: null,
      draggedItemIndex: null
    });
  }
  private onDragOver = (draggedItemDest: number) => {
    this.setState({draggedItemDest});
  }

  // ----- Options and selected -----

  private getSelected(): Array<IOption> {
    return this.props.multiple ? this.props.selected : (this.props.selected.length ? [this.props.selected[0]] : []);
  }

  private options(): Array<IOption> {
    if (!this.state.search)
      return this.props.options;
    return this.props.options.filter(it => it.title.toLowerCase().includes(this.state.search.toLowerCase()));
  }

  private addOption = (it: IOption): void => {
    const items: Array<IOption> = this.props.multiple ? [
      ...this.props.selected,
      it
    ] : [it];
    this.setState({search: ""});
    this.searchInput.focus();
    this.onChange(items);
  }

  private removeOption = (item: IOption) => {
    this.onChange(this.props.selected.filter(it => it.key !== item.key));
  }

  private onChange(selected: Array<IOption>): void {
    if (this.props.multiple) {
      this.props.onChange(selected);
    } else
      this.props.onChange(selected.length ? [selected[0]] : []);
  }

}

function mapStateToProps(state: {}): {} {
  return {
  };
}

export default connect(mapStateToProps, {})(Typeahead);
