import {HOST, NumbersReq, Req} from "../Store";

export async function fetchSyncRequest(
  organizationNumber: number,
  page: number,
  size: number
): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}sync_request/fetch-sync-requests/${organizationNumber}?page=${page}&&size=${size}`,
    null,
    "get"
  );
  return resp;
}

export async function fetchExternalShares(): Promise<{data: any[]}> {
  return Req(
    `${HOST}share`,
    {}
  )
}