import React, {Component} from "react";
import {HTMLElementEventHandler} from "../../../Interfaces/Common";
import Translation from "../../../Helpers/Translation";

interface IProps {
  onChange: HTMLElementEventHandler;
  options: Array<{
    key: any;
    title: string;
  }>;
  index?: number;
  name: string;
  selected?: any;
  width?: number;
  className?: string;
}

export default class Select extends Component<IProps> {
  public render(): React.ReactNode {
    // const style: React.CSSProperties = {};
    const style: React.CSSProperties = {};
    if (this.props.width) style.width = this.props.width;
    return (
      <Translation>
        {(t) => (
          <select
            className={this.props.className || ""}
            style={style}
            name={this.props.name}
            value={this.props.selected}
            onChange={(e) =>
              this.props.onChange(
                this.props.name,
                e.target.value,
                this.props.index
              )
            }
            data-index={~this.props.index ? this.props.index : -1}
          >
            {this.props.options.map(({key, title}) => (
              <option key={key || -1} value={key}>
                {!!~title.indexOf(":") ? title : t(title)}
              </option>
            ))}
          </select>
        )}
      </Translation>
    );
  }
}
