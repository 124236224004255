import React, {Component} from "react";
import {HTMLElementEventHandler} from "../../../Interfaces/Common";
import Icon from "../../Icon";

interface IProps {
  index?: number;
  onChange: HTMLElementEventHandler;
  value: string;
  name: string;
  width?: number;
  placeholder?: string;
}

interface IState {
  showPassword: boolean;
}

export default class Password extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  public render(): React.ReactNode {
    const style: React.CSSProperties = {};
    if (this.props.width) style.width = this.props.width;
    return (
      <>
        <input
          type={this.state.showPassword ? "text" : "password"}
          style={style}
          name={this.props.name}
          value={this.props.value}
          onChange={(e) =>
            this.props.onChange(this.props.name, e.target.value, this.props.index)
          }
          placeholder={this.props.placeholder}
        />
        <Icon icon={this.state.showPassword ? "visibility_off" : "remove_red_eye"} onClick={e => {
          this.setState({showPassword: !this.state.showPassword});
        }} style={{float: "left", display: "block", marginTop: "2px"}}></Icon>
      </>
    );
  }
}
