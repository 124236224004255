import {IObject} from "../Interfaces/Common";

export function SecToTime(value: number, short: boolean = false): string {
  value = Math.round(value);
  if (!value)
    return "00:00";
  /*
  const days: number = Math.floor(value / 86400);
  const hours: number = Math.floor((value - (days * 86400)) / 3600);
  const minutes: number = Math.floor((value - (days * 86400) - (hours * 3600)) / 60);
  const seconds: number = Math.round(value - (days * 86400) - (hours * 3600) - (minutes * 60));
  */
  const days: number = Math.floor((value - 1) / 86400);
  value -= days * 86400;
  const hours: number = Math.floor(value / 3600);
  value -= hours * 3600;
  const minutes: number = Math.floor(value / 60);
  value -= minutes * 60;
  const seconds: number = value;
  if (short && !hours)
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  return `${days ? `${days}d ` : ""}${hours ? `${hours < 10 ? "0" : ""}${hours}:` : ""}${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

export function toFilename(str: string, ext: string = null): string {
  return str.replace(/[^A-Za-z0-9]/ig, "_") + (ext ? `.${ext}` : "");
}

export function getUrlParams(): IObject {
  const params: IObject = {};
  window.location.search.substr(1, window.location.search.length - 1).split("&").forEach(p => {
    const [key, val] = p.split("=");
    params[key] = val;
  });

  return params;
}
