import {HOST, NumbersReq} from "../Store";

/**
 * Fetch request to terminate number.
 * @param orgNr
 * @param voiceUriId
 * @param didId
 * @returns fetch response
 */
export default async function unassignNumber(
  orgNr: number,
  voiceUriId: number,
  didId: number
): Promise<any> {
  const resp: any = await NumbersReq<any>(
    `${HOST}opm/organization/${orgNr}/${voiceUriId}/${didId}/unassign`,
    null,
    "post"
  );
  return resp;
}
