import React from "react";

const NotFound: any = (props) => {
  if (!~props.history.location.pathname.indexOf("page_does_not_exist")) {
    props.history.replace("./page_does_not_exist");
    return null;
  } else {
    return (
      <div className="col-md-12 text-center">
        <h1 className="not-found-title">404: Sorry, that page does not exist...</h1>
      </div>
    );
  }
};

export default NotFound;
