const getItem = (key: string): any => localStorage.getItem(key);

const setItem = (key: string, value: any) => localStorage.setItem(key, value);
const removeItem = (key: string) => {
    localStorage.removeItem(key);
};
const clearAllCookies = () => {
    localStorage.clear();
};

export {getItem, setItem, removeItem, clearAllCookies};
