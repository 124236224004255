import React, {Component} from "react";
import {HTMLElementEventHandler} from "../../../Interfaces/Common";

interface IProps {
  onChange: HTMLElementEventHandler;
  value: number;
  name: string;
  index?: number;
  step?: number;
  min?: number;
  max?: number;
  percentage?: boolean;
}

export default class Number extends Component<IProps> {
  public render(): React.ReactNode {
    let {min, max, value, step = 1, percentage} = this.props;
    if (percentage) {
      min = 0;
      max = 100;
    }

    value = parseInt(value + "", 10);

    return <div className="input-number">
      <div className="minus" onClick={e => !isNaN(min) && min >= value ? null : this.onChange(value - step)}>-</div>
      <input type="number" name={this.props.name}
             value={this.props.value}
             data-index={~this.props.index ? this.props.index : -1}
             onChange={e => this.onChange(e.target.value)}
             onBlur={e => this.onBlur(e.target.value)}
             step={this.props.step || 1}/>
      {percentage && <div className="percentage">%</div>}
      <div className="plus" onClick={e => max && max <= value ? null : this.onChange(value + step)}>+</div>
    </div>;
  }

  private onChange(value: any): void {
    this.props.onChange(this.props.name, value, this.props.index);
  }

  private onBlur(value: any): void {
    if (this.props.step > 1 && value % this.props.step > 0) {
      this.onChange(Math.round(value / this.props.step) * this.props.step);
    }
  }

}
